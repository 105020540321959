import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { format } from "../../utils/currency";
import {
  TransactionStatuses,
  currencySymbols,
  PaymentMethods,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  card: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: "solid",
    padding: theme.spacing(0, "10px"),
    paddingBottom: 16,
    paddingTop: 10,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, "16px"),
      paddingRight: 0,
      paddingTop: 16,
      borderTopWidth: 0,
    },
    "&:first-child": {
      borderTopWidth: 0,
    },
  },
  heading: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    alignItems: "center",
    minHeight: 30,
    height: "100%",
    paddingTop: 16,

    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
    },
  },
  itemLabel: {
    fontSize: 11,
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  itemTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  number: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  awaitingPayment: {
    color: theme.palette.error.dark,
    fontSize: 14,
  },
  paymentStatusError: { color: theme.palette.error.dark },
  statusBadge: {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  receipt: {
    "& a": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  noReceipt: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const EmployerPaymentHistory = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { payment, number } = props;

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid container spacing={isMdUp ? 1 : 0} className={classes.card}>
        <Typography className={classes.heading}>
          {t("common.employee")} {number + 1}
        </Typography>
        <Grid item container spacing={isMdUp ? 1 : 0} xs={12} md={5}>
          <Grid item xs={12} md={1} className={classes.number}>
            <div className={classes.item}>
              <Typography className={classes.itemTitle}>
                {number + 1}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("registration.fullNameKH")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {payment.contributor_name_kh}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("registration.fullNameLATIN")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {payment.contributor_name_latin}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("registration.gender")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {t(`registration.${payment.contributor_gender}`)}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <div className={classes.item}>
            <Typography className={classes.itemLabel}>
              {t("registration.nssfId")}
            </Typography>
            <Typography className={classes.itemTitle}>
              {payment.contributor_nssf_id}
            </Typography>
          </div>
        </Grid>

        <Grid item container spacing={isMdUp ? 1 : 0} xs={12} md={5}>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("contribution.salary")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {format(
                  payment.salary,
                  currencySymbols[payment.salary_currency]
                )}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("common.contribution")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {format(payment.contribution)}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("common.paymentMethod")}
              </Typography>
              <Typography className={classes.itemTitle}>
                {t(`payment.${payment.payment_method}`)}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div className={classes.item}>
              <Typography className={classes.itemLabel}>
                {t("payment.status")}
              </Typography>
              <Typography
                className={clsx(
                  classes.itemTitle,
                  {
                    [classes.awaitingPayment]:
                      payment.status ===
                      TransactionStatuses.AWAITING_CONFIRMATION,
                  },
                  {
                    [classes.paymentStatusError]:
                      payment.status === TransactionStatuses.CANCELED ||
                      payment.status === TransactionStatuses.FAILED,
                  }
                )}
              >
                {t(`payment.${payment.status}`)}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={2}>
            <div
              className={clsx(
                classes.item,
                classes.receipt,
                payment.payment_method !== PaymentMethods.CASH_PAYMENT
                  ? classes.noReceipt
                  : ""
              )}
            >
              <Typography className={classes.itemLabel}>
                {t("payment.receipt")}
              </Typography>
              <Typography className={clsx(classes.itemTitle)}>
                {payment.payment_receipt_url !== "" &&
                  payment.payment_method === PaymentMethods.CASH_PAYMENT && (
                    <a
                      rel="noreferrer"
                      href={payment.payment_receipt_url}
                      target="_blank"
                    >
                      <ReceiptIcon />
                    </a>
                  )}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default EmployerPaymentHistory;
