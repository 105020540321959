import React, { useState } from "react";

import {
  Grid,
  Box,
  Container,
  Button,
  Typography,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "../../components/Header";
import UploadRecipt from "./UploadRecipt";

import Aba from "../../assets/aba_bank.png";
import Acleda from "../../assets/acleda_bank.png";
import Canadia from "../../assets/canadia_bank.png";
import JTrust from "../../assets/j_trust.png";
import NSSF from "../../assets/logo_hd.png";

import { createTransaction } from "../../store/actions/transactionActions";

import { UserRoles, PaymentMethods } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  button: {
    height: 154,
    width: 154,
    backgroundColor: "white",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      height: 61,
      width: "100%",
    },
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    paddingBottom: 15,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      paddingBottom: 8,
    },
  },
  text: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 23,
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: 15,
    },
  },
  list: {
    marginTop: 33,
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 6,
      lineHeight: 5,
      "&.MuiGrid-root ": {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  logo: {
    borderRadius: 0,
    textAlign: "center",
    "&.MuiAvatar-root": {
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 0,
      fontSize: 35,
    },
  },
  buttonContents: {
    display: "block",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PaymentMethod = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openUploadRecipt, setOpenUploadRecipt] = useState(false);
  const [data, setData] = useState();

  const { user } = props;

  const transaction = useSelector(
    (state) => state.contributionPayment.transaction
  );

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/contribution-payment",
    },
    {
      title: t("common.paymentMethod"),
      route: "/contribution-payment/payment-method",
    },
  ];

  const paymentGateways = [
    // {
    //   key: PaymentMethods.ABA,
    //   name: t("payment.abaBank"),
    //   logo: Aba,
    // },
    // {
    //   key: PaymentMethods.ACLEDA,
    //   name: t("payment.acledaBank"),
    //   logo: Acleda,
    // },
    {
      key: PaymentMethods.CANADIA,
      name: t("payment.canadiaBank"),
      logo: Canadia,
    },
    // {
    //   key: PaymentMethods.JTRUST_ROYAL,
    //   name: t("payment.jTrustRoyalBank"),
    //   logo: JTrust,
    // },
    {
      key: PaymentMethods.CASH_PAYMENT,
      name: t("payment.cash_payment"),
      logo: NSSF,
    },
  ];

  const onPressButtonBack = () => {
    history.push("/contribution-payment");
  };

  const onPress = (method) => {
    let data = {
      ...transaction,
      payment_method: method.key,
      payments: transaction.payments.filter((payment) => payment.selected),
    };

    if (method.key == PaymentMethods.CASH_PAYMENT) {
      setData(data);
      setOpenUploadRecipt(true);
    } else {
      dispatch(createTransaction(data));
      history.push("/contribution-payment/payment-processing");
    }
  };

  const onCloseUploadRecipt = () => {
    setOpenUploadRecipt(false);
  };

  if (!transaction) {
    history.push("/contribution-payment");
  }

  return (
    <>
      <Header
        title={
          user.role === UserRoles.EMPLOYER
            ? t("payment.employerContributionPayment")
            : t("contribution.TuktukDriverContribution")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Box className={classes.container}>
          <Typography className={classes.heading} variant="h3" gutterBottom>
            {t("common.paymentMethod")}
          </Typography>

          <Grid container className={classes.list}>
            {paymentGateways.map((item, index) => (
              <Grid item xs={12} md={2} key={index}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size={"large"}
                  disableElevation
                  className={classes.button}
                  onClick={() => onPress(item)}
                >
                  <div className={classes.buttonContents}>
                    <Avatar src={item.logo} className={classes.logo}></Avatar>
                    <Typography className={classes.text} variants="h2">
                      {item.name}
                    </Typography>
                  </div>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>

        <UploadRecipt
          open={openUploadRecipt}
          title={t("payment.uploadPaymentReceipt")}
          onClose={onCloseUploadRecipt}
          data={data}
        />
      </Container>
    </>
  );
};

export default PaymentMethod;
