/**
 * Generate url params
 * @param {*} params 
 */
export const generateParamsUrl = (params) => {
  let paramsUrl = ''
  for (var key in params) {
    if(paramsUrl !== '') {
      paramsUrl += "&"
    } else {
      paramsUrl += '?'
    }
    paramsUrl += key + "=" + params[key]
  }
  return paramsUrl
}

/**
 * Get url params
 * @param {*} url 
 */
export const getParamsUrl = (url) => {
  let params = []
  let splitUrl = url.split("?")
  if (splitUrl.length > 1) {
    splitUrl = (splitUrl[1]).split("&")
    splitUrl.map( param => {
      let paramSplit = param.split("=")
      if (paramSplit.length > 1) {
        params[paramSplit[0]] = paramSplit[1]
      }
    })
  }
  return params
}