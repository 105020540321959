import { SET_TRANSACTION, RESET_TRANSACTION } from "../types";

const initialState = {
  transaction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };

    case RESET_TRANSACTION:
      return {
        ...state,
        transaction: null,
      };

    default:
      return state;
  }
}
