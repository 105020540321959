import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    marginTop: 68,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiTypography-h1": {
      fontWeight: 500,
      fontSize: 24,
      padding: theme.spacing("6px", 0),
    },
    "& .MuiTypography-h5": { fontSize: 14 },
  },
}));

const PageNotFound = (props) => {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.root}>
      <div className={classes.paper}>
        <Typography component="h1" variant="h1">
          404
        </Typography>
        <Typography component="p" variant="h5">
          This page could not be found.
        </Typography>
      </div>
    </Container>
  );
};

export default PageNotFound;
