import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { UserRoles } from "../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

const NSSFIDControl = ({ user, registration, register, errors }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (user.role != UserRoles.EMPLOYER) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} md={6}>
        <TextField
          id="beneficiary_nssf_id"
          label={t("registration.beneficiaryNssfId")}
          name="beneficiary_nssf_id"
          inputRef={register()}
          defaultValue={registration.beneficiary_nssf_id}
          variant="outlined"
          error={!!errors.beneficiary_nssf_id?.message}
          helperText={errors.beneficiary_nssf_id?.message}
          className={classes.formControl}
        />
      </Grid>
    </Grid>
  );
};

export default NSSFIDControl;
