import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Employee from "./Employee";
import EmployeeListHeader from "./EmployeeListHeader";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const EmployeeList = (props) => {
  const classes = useStyles();

  const { employees } = props;

  const isEmpty = () => {
    return employees.length == 0;
  };

  return (
    <div className={clsx(classes.root)}>
      {!isEmpty() && <EmployeeListHeader />}

      {employees.map((employee, index) => (
        <Employee employee={employee} number={index + 1} key={index}></Employee>
      ))}
    </div>
  );
};
export default EmployeeList;
