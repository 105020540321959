import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 28,

    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginBottom: 24,
    },
  },
  message: {
    fontSize: 14,
    color: theme.palette.primary.main,
    paddingRight: 30,
    paddingLeft: 30,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 8,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textAlign: "center",
    textDecoration: "underline",
    marginTop: 20,
    marginBottom: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const PageTitle = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title} variant="h3" gutterBottom>
        {t("registration.summaryInformation")}
      </Typography>
      <Typography className={classes.message} variant="body2" gutterBottom>
        Lorem dolor sit amet consectetur adipisicing elit, sed do.
      </Typography>

      <Typography className={classes.subtitle} variant="h4" gutterBottom>
        {t("registration.personalInformations")}
      </Typography>
    </>
  );
};

export default PageTitle;
