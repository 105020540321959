import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, Button, IconButton } from "@material-ui/core";
import kh from "../assets/icons/kh.svg";
import en from "../assets/icons/en.svg";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paperDesktop: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    padding: 6,
  },
  selectedLang: {
    backgroundColor: "#e8e8e8 !important",
    borderRadius: 2,
    height: 31,
  },
  icon: {
    height: 26,
    width: 26,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  img: {
    height: 16,
  },
}));

const languageOptions = [
  { name: "Khmer", code: "kh", icon: kh },
  { name: "English", code: "en", icon: en },
];

const LanguageSwitcher = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    languageOptions.find((lang) => lang.code == i18n.language)
  );

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);

    setCurrentLanguage(
      languageOptions.find((lang) => lang.code == i18n.language)
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.paperDesktop}>
        <Button
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={handleClick}
          component="button"
          className={classes.button}
          key={currentLanguage}
        >
          <div className={classes.icon}>
            <img
              src={currentLanguage.icon}
              className={classes.img}
              alt={currentLanguage.name}
            />
          </div>
          <ExpandMoreIcon style={{ marginLeft: 5 }} color="primary" />
        </Button>

        <Menu
          id="languge-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {languageOptions.map((language) => (
            <MenuItem
              onClick={() => changeLanguage(language.code)}
              selected={language.code == currentLanguage.code}
              key={language.name}
            >
              <div className={classes.icon} onClick={handleClose}>
                <img
                  src={language.icon}
                  className={classes.img}
                  alt={language.name}
                />
              </div>
            </MenuItem>
          ))}
        </Menu>
      </div>

      <div className={classes.paper}>
        {languageOptions.map((lang) => (
          <IconButton
            component="button"
            className={clsx(classes.button, {
              [classes.selectedLang]: lang.code == currentLanguage.code,
            })}
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
          >
            <div className={classes.icon}>
              <img src={lang.icon} className={classes.img} alt={lang.name} />
            </div>
          </IconButton>
        ))}
      </div>
    </div>
  );
};
export default LanguageSwitcher;
