const TOKEN_KEY = 'access_token';

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}

export const cleanUserAuthStorage = () => {
    localStorage.removeItem('phone');
    localStorage.removeItem('facebook_name');
    localStorage.removeItem('facebook_user_id');
    localStorage.removeItem('google_name');
    localStorage.removeItem('google_user_id');
}

export const setPhoneData = (data) => {
  if (data.phone) {
    localStorage.setItem("phone", data.phone);
  }
}

export const setGoogleData = (data) => {
    if (data.google_name) {
      localStorage.setItem("google_name", data.google_name);
    }
    if (data.google_user_id) {
      localStorage.setItem("google_user_id", data.google_user_id);
    }
}

export const setFacebookData = (data) => {
  if (data.facebook_name) {
    localStorage.setItem("facebook_name", data.facebook_name);
  }
  if (data.facebook_user_id) {
    localStorage.setItem("facebook_user_id", data.facebook_user_id);
  }
}