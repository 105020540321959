import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";

import Header from "../../components/Header";

import { useTracking } from "../../hooks/useTracking";
import Tracker from "../../services/tracker";

const useStyles = makeStyles((theme) => ({
  container: {},
  list: {
    width: "100%",
    paddingTop: theme.spacing(3),
  },
  item: {
    borderColor: "#BCE0FD",
    borderBottomWidth: 2,
    borderStyle: "solid",
    color: theme.palette.primary.main,
    paddingRight: 8,
    paddingLeft: 8,

    "&. MuiListItem-root": {
      padding: theme.spacing("16px", 0),
    },

    [theme.breakpoints.up("sm")]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  title: {
    "& span": {
      fontWeight: 600,
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: 10,
  },
}));

const WorkingContract = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);

  useTracking(Tracker.TRACKER_PAGES.WORKING_CONTRACT_SAMPLE);

  const user = useSelector((state) => state.auth.user);

  let breadcrumbs = [
    { title: t("common.workingContractSample"), route: "/working-contract" },
  ];

  if (user) {
    breadcrumbs.unshift({
      title: t(`common.${user.role}`),
      route: "/",
    });
  }

  const onPressButtonBack = () => {
    history.push("/");
  };

  const setWorkingContact = () => {
    let data = [
      {
        title: t("workingContracts.workingContractSampleKhmer"),
        path: "/documents/working-contract-sample_kh.docx",
      },
      {
        title: t("workingContracts.workingContractSampleEnglish"),
        path: "/documents/working-contract-sample_eng.docx",
      },
      {
        title: t("workingContracts.workingContractSampleEnglishPDF"),
        path: "/documents/working-contract-sample_eng.pdf",
      },
    ];

    setDocuments(data);
  };

  useEffect(() => {
    setWorkingContact();
  }, [props]);

  return (
    <>
      <Header
        title={t("common.workingContractSample")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" className={classes.container}>
        <List dense={true} className={classes.list}>
          {documents.map((item, index) => (
            <ListItem
              button
              onClick={(event) => (window.location.href = item.path)}
              key={index}
              className={classes.item}
            >
              <ListItemText className={classes.title} primary={item.title} />
              <div className={classes.iconContainer}>
                <PrintIcon className={classes.icon} />
                {t("common.print")}
              </div>
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  );
};

export default WorkingContract;
