import React, { useEffect } from "react";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import EmployeeList from "../../components/Profile/EmployeeList";

import { fetchEmployees } from "../../store/actions/employeesAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",

    [theme.breakpoints.up("md")]: {
      paddingTop: 32,
      minHeight: 400,
    },
  },
  blankslate: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: theme.spacing("28px", 0),

    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
  buttonContainer: { padding: "0 !important" },
  loading: {
    paddingTop: 10,
    padding: theme.spacing("28px", 0),
  },
  button: {
    marginTop: 16,
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      maxWidth: 240,
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      margin: theme.spacing(0, "auto"),
      marginTop: 32,
      marginBottom: 16,

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
  },
}));

const EmployeesPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { registration, user } = props;

  const employees = useSelector((state) => state.employees.items);
  const loading = useSelector((state) => state.employees.loading);

  const renderBlankslate = () => {
    if (!loading && employees.length == 0) {
      return (
        <Typography variant={"h4"} className={classes.blankslate}>
          {t("registration.noEmployeeRegistered")}
        </Typography>
      );
    }
  };

  const onPressAddEmployee = () => {
    history.push("/profile/add-employee");
  };

  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {renderBlankslate()}
      {loading && <Loading styles={classes.loading} />}

      {!loading && (
        <EmployeeList
          registration={registration}
          user={user}
          employees={employees}
        ></EmployeeList>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.button}
            onClick={onPressAddEmployee}
            variant="outlined"
          >
            {t("registration.addEmployee")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeesPage;
