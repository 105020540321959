import {
  SET_USER,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGIN_REQUEST,
} from "../types";
import { config } from "../../config";
import history from "../../utils/history";
import { axios } from "../../utils/request";
// import { generateParamsUrl, getParamsUrl } from "../../utils/url";
import Otp from "../../services/otp";
import {
  setGoogleData,
  setFacebookData,
  cleanUserAuthStorage,
  setPhoneData,
} from "../../utils/auth";

import { fetchNotifications } from "../../store/actions/notificationActions";

export const getUser = () => async (dispatch) => {
  const response = await axios.get(`${config.API_BASE_URL}/auth/user`);
  dispatch({
    type: SET_USER,
    payload: response.data.data,
  });
  localStorage.setItem("auth", JSON.stringify(response.data.data));
};

/**
 * User Registration
 * @param {*} data
 */
export const register = (data) => async (dispatch) => {
  dispatch({
    type: USER_REGISTER_REQUEST,
  });

  try {
    const response = await axios.post(
      `${config.API_BASE_URL}/auth/register`,
      data
    );
    dispatch({
      type: SET_USER,
      payload: response.data.user,
    });
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: response.data.user,
    });

    cleanUserAuthStorage();
    localStorage.setItem("auth", JSON.stringify(response.data.user));
    localStorage.setItem("access_token", response.data.access_token);

    history.push("/");
  } catch (err) {
    dispatch({ type: USER_REGISTER_FAILURE, payload: err.response.data });

    return { error: err.response.data };
  }
};

export const requestCode = (data, role) => async (dispatch) => {
  try {
    Otp.generateOtpCode(data).then((response) => {
      if (
        response.data &&
        response.data.success === true &&
        "phone" in data &&
        data["phone"] != ""
      ) {
        // Store phone in localstorage
        localStorage.setItem("phone", data["phone"]);
        history.push("/check-sms-code/" + role);
      } else if ("status" in response && response["status"] === 500) {
        dispatch({ type: USER_LOGIN_FAILURE, payload: response.data });
      }
    });
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, payload: err.response.data });
  }
};

/** DEPRECATED
 * Login with phone and password
 * @param {*} phone
 * @param {*} password
 */
export const login = (phone, password) => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_REQUEST,
  });

  try {
    const response = await axios.post(`${config.API_BASE_URL}/auth/login`, {
      phone,
      password,
    });
    localStorage.setItem("access_token", response.data.access_token);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: response.data,
    });
    dispatch(getUser());
    dispatch(fetchNotifications());

    history.push("/");
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, payload: err.response.data });
  }
};

/**
 * Login with Facebook account
 * @param {*} data
 * @param {*} role
 */
export const loginFacebook = (data, role) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.API_BASE_URL}/auth/login-fb`,
      data
    );

    // Login
    if ("status" in response && response["status"] === 200) {
      localStorage.setItem("access_token", response.data.access_token);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.data,
      });
      dispatch(getUser());
      dispatch(fetchNotifications());

      history.push("/");
      // Force registration with facebook account
    } else if ("status" in response && response["status"] === 202) {
      if (role && role !== "") {
        cleanUserAuthStorage();
        setFacebookData(response.data);
        history.push("/register/" + role);
      }
    }
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, payload: err.response.data });
  }
};

/**
 * Login with google account
 * @param {*} data
 * @param {*} role
 */
export const loginGoogle = (data, role) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${config.API_BASE_URL}/auth/login-google`,
      data
    );

    // Login
    if ("status" in response && response["status"] === 200) {
      localStorage.setItem("access_token", response.data.access_token);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.data,
      });
      dispatch(getUser());
      dispatch(fetchNotifications());

      history.push("/");
      // Force registration with google account
    } else if ("status" in response && response["status"] === 202) {
      if (role && role !== "") {
        cleanUserAuthStorage();
        setGoogleData(response.data);
        history.push("/register/" + role);
      } else {
        history.push("/");
      }
    }
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, payload: err.response.data });
  }
};

/**
 * Login with phone and code
 * @param {*} data
 * @param {*} role
 */
export const loginPhone = (response, role) => async (dispatch) => {
  try {
    // Login
    if ("status" in response && response["status"] === 200) {
      localStorage.setItem("access_token", response.data.access_token);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.data,
      });
      dispatch(getUser());
      dispatch(fetchNotifications());
      history.push("/");

      // Force registration with phone number and code validation
    } else if ("status" in response && response["status"] === 202) {
      if (role && role !== "") {
        // history.push("/register/" + role + generateParamsUrl(response.data));

        cleanUserAuthStorage();
        setPhoneData(response.data);
        history.push("/register/" + role);
      }
    }
  } catch (err) {
    dispatch({ type: USER_LOGIN_FAILURE, payload: err.response.data });
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("auth");
    localStorage.removeItem("access_token");
    dispatch({ type: USER_LOGOUT_REQUEST });

    history.push("/");
  } catch (err) {
    console.log(err);
  }
};
