import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  login,
  loginFacebook,
  loginGoogle,
} from "../../store/actions/authActions";
import { Typography, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";

import { generateParamsUrl, getParamsUrl } from "../../utils/url";

import Header from "../../components/Header";

import { UserRoles } from "../../constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginTop: 10,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    textAlign: "center",
    marginBottom: 16,
  },
  errors: {
    margin: 0,
  },
  fb: {
    width: "100%",
  },
}));

const Login = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const error = useSelector((state) => state.auth.error);
  const [userRole, setUserRole] = useState();

  const responseFacebook = (response) => {
    // console.log("Facebook login = ", response);
    let params = getParamsUrl(props.location.search);
    dispatch(loginFacebook(response, params["role"]));
  };

  const responseGoogle = (response) => {
    // console.log("Google login = ", response);
    if (!("error" in response)) {
      let params = getParamsUrl(props.location.search);
      dispatch(loginGoogle(response, params["role"]));
    }
  };

  useEffect(() => {
    let params = getParamsUrl(props.location.search)["role"];
    if (params === UserRoles.TUKTUK_DRIVER) {
      params = t("common.tuktukDriver");
    } else if (params === UserRoles.DOMESTIC_WORKER) {
      params = t("common.domesticWorker");
    } else if (params === UserRoles.EMPLOYER) {
      params = t("common.employer");
    }

    setUserRole(params);
  });

  if (user) {
    return <Redirect to="/" />;
  }

  const breadcrumbs = [{ title: userRole, route: "/login" }];

  const onPressButtonBack = () => {
    history.push("/");
  };

  const redirectLoginByPhone = () => {
    let params = getParamsUrl(props.location.search);
    history.push("/login-phone/" + params["role"]);
  };

  return (
    <>
      <Header
        title={userRole}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography className={classes.title} variant="h2">
            {t("login.loginTitle")}
          </Typography>

          <Typography variant="body2" className={classes.subtitle}>
            {t("login.loginSubtitle")}
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="bt-phone-login"
            fullWidth
            onClick={redirectLoginByPhone}
          >
            {t("login.loginPhone")}
          </Button>

          <div className={classes.fb}>
            <FacebookLogin
              appId={config.FB_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              cssClass="bt-fb-login"
              textButton={t("login.loginFacebook")}
              icon="fa-facebook"
            />
          </div>

          <GoogleLogin
            clientId={config.GOOGLE_ID}
            buttonText={t("login.loginGoogle")}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            isSignedIn={false}
            cookiePolicy={"single_host_origin"}
            className="bt-google-login"
          />
        </div>
      </Container>
    </>
  );
};

export default Login;
