import {
  UPSERT_REGISTRATION_REQUEST,
  UPSERT_REGISTRATION_SUCCESS,
  UPSERT_REGISTRATION_FAILURE,
  GET_REGISTRATION_REQUEST,
  GET_REGISTRATION_SUCCESS,
  GET_REGISTRATION_FAILURE,
  UPLOAD_PHOTO_SUCCESS,
  DELETE_PHOTO_SUCCESS,
  UPDATE_PRE_REGISTRATION_SUCCESS,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

import { getUser } from "./authActions";

export const createOrUpdateRegistration = (data) => async (dispatch) => {
  dispatch({
    type: UPSERT_REGISTRATION_REQUEST,
  });

  try {
    const res = await axios.post(`${config.API_BASE_URL}/registrations`, data);
    dispatch({
      type: UPSERT_REGISTRATION_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: UPSERT_REGISTRATION_FAILURE,
      payload: err.response,
    });
  }
};

export const fetchRegistration = () => async (dispatch) => {
  dispatch({
    type: GET_REGISTRATION_REQUEST,
  });

  try {
    const res = await axios.get(`${config.API_BASE_URL}/registrations`);

    dispatch({
      type: GET_REGISTRATION_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_REGISTRATION_FAILURE,
      payload: err.response,
    });
  }
};

export const submitRegistration = () => async (dispatch) => {
  try {
    const res = await axios.put(`${config.API_BASE_URL}/registrations/submit`);
    dispatch(getUser());
    return res;
  } catch (err) {
    return { error: err.response };
  }
};

export const uploadPhoto = (data, registrationId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${config.API_BASE_URL}/registrations/${registrationId}/photos`,
      data
    );

    dispatch({
      type: UPLOAD_PHOTO_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {}
};

export const deletePhoto = (data) => async (dispatch) => {
  try {
    const res = await axios.delete(`${config.API_BASE_URL}/photos/${data.id}`);

    dispatch({
      type: DELETE_PHOTO_SUCCESS,
      payload: data,
    });
  } catch (e) {}
};

export const updatePreRegistrationStatus = () => async (dispatch) => {
  try {
    const res = await axios.put(
      `${config.API_BASE_URL}/registrations/update_status`
    );

    dispatch({
      type: UPDATE_PRE_REGISTRATION_SUCCESS,
      payload: res,
    });

    dispatch(getUser());
  } catch (e) {}
};
