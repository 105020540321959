import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import EmployeePreview from "./EmployeePreview";
import SearchEmployee from "./SearchEmployee";
import PhoneVerification from "./PhoneVerification";
import UpdatePhone from "./UpdatePhone";
import Profile from "./Profile";

const ProfileContainer = (props) => {
  let { path } = useRouteMatch();

  const user = useSelector((state) => state.auth.user);

  if (!user) {
    return null;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <Profile {...props} user={user} />}
      />
      <Route
        exact
        path={`${path}/add-employee`}
        render={(props) => <SearchEmployee {...props} />}
      />
      <Route
        exact
        path={`${path}/employee-preview`}
        render={(props) => <EmployeePreview {...props} />}
      />
      <Route
        exact
        path={`${path}/update-phone`}
        render={(props) => <UpdatePhone {...props} />}
      />
      <Route
        exact
        path={`${path}/phone-verification`}
        render={(props) => <PhoneVerification {...props} />}
      />
    </Switch>
  );
};

export default ProfileContainer;
