import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import PaymentReceipt from "./PaymentReceipt";

const useStyles = makeStyles((theme) => ({
  button: {
    borderWidth: 0,
    borderTopWidth: 1,
    borderRadius: 0,
    paddingTop: 12,
    paddingBottom: 12,
    borderTopColor: "#2699FB",

    "&:hover": {
      borderWidth: 0,
      borderTopWidth: 1,
    },
  },
  hasBottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: "#2699FB",
    "&:hover": {
      borderBottomWidth: 1,
      borderBottomColor: "#2699FB",
    },
  },
  recipe: {
    display: "none",
  },
  print: {
    padding: theme.spacing("42px", "52px"),
  },
}));

const PrintPayment = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const componentRef = useRef();
  const { contribution, userRole, hasBottomBorder } = props;

  return (
    <div className={classes.root}>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={clsx(classes.button, {
              [classes.hasBottomBorder]: hasBottomBorder,
            })}
            startIcon={<PrintIcon />}
          >
            {t("contribution.print")}
          </Button>
        )}
        content={() => componentRef.current}
        bodyClass={clsx(classes.print)}
      />
      <div className={classes.recipe}>
        <PaymentReceipt
          ref={componentRef}
          contribution={contribution}
          userRole={userRole}
        />
      </div>
    </div>
  );
};

export default PrintPayment;
