import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  row: {
    fontSize: 10,
    marginTop: 40,
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  item: {
    fontSize: 11,
    paddingBottom: 10,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    color: theme.palette.primary.main,
  },
}));

const EmployeeListHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.row} spacing={1}>
      <Grid item md={1}>
        <Typography variant={"h3"} className={classes.item}>
          {t("common.employee")} #
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant={"h3"} className={classes.item}>
          {t("registration.fullNameKH")}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant={"h3"} className={classes.item}>
          {t("registration.fullNameLATIN")}
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography variant={"h3"} className={classes.item}>
          {t("registration.gender")}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant={"h3"} className={classes.item}>
          {t("registration.nssfIdNumber")}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant={"h3"} className={classes.item}>
          {t("contribution.salary")}
        </Typography>
      </Grid>
      <Grid container item md={2} spacing={1}>
        <Grid item md={8}>
          <Typography variant={"h3"} className={classes.item}>
            {t("common.contribution")}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant={"h3"} className={classes.item}>
            {t("actions.select")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EmployeeListHeader;
