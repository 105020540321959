import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Box, Link, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import TransactionStatusChanged from "../../components/Notifications/TransactionStatusChanged";
import MessageNotification from "../../components/Notifications/Message";

const useStyles = makeStyles((theme) => ({
  container: {},
  wrapper: {
    backgroundColor: "#fff",
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#2699FB",
    borderStyle: "solid",
    borderRadius: 6,
    overflow: "hidden",
    padding: 16,

    "& .MuiTypography-h5": {
      fontSize: 20,
      marginBottom: 8,
    },
    [theme.breakpoints.up("md")]: {
      padding: 24,
    },
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();

  const [notification, setNotification] = useState({ data: {} });

  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.notifications.items);

  let breadcrumbs = [
    { title: t("common.notifications"), route: "/notifications" },
    {
      title:
        notification.type === "Message"
          ? notification?.data.title
          : t("notifications.transactionsStatusUpdate"),
    },
  ];

  if (user) {
    breadcrumbs.unshift({
      title: t(`common.${user.role}`),
      route: "/",
    });
  }

  const onPressButtonBack = () => {
    history.push("/notifications");
  };

  useEffect(() => {
    let notification = notifications.find((item) => item.id === params.id);
    if (notification) {
      setNotification(notification);
    }
  }, [notifications, params]);

  return (
    <>
      <Header
        title={
          notification.type === "Message"
            ? notification?.data.title
            : t("notifications.transactionsStatusUpdate")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" className={classes.container}>
        <Box className={classes.wrapper}>
          {notification.type === "TransactionStatusChanged" && (
            <TransactionStatusChanged notification={notification} />
          )}
          {notification.type === "Message" && (
            <MessageNotification notification={notification} />
          )}
        </Box>
      </Container>
    </>
  );
};

export default Notification;
