import React, { useEffect, useRef } from "react";
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectStyles } from "../../theme/sharedStyles";

const CommuneSelect = (props) => {
  const { i18n } = useTranslation();
  const selectRef = useRef(null);

  const { getValues, defaultValue, errors } = props;

  const communes = useSelector((state) => state.communes.items);
  const loading = useSelector((state) => state.communes.loading);

  const communeOptions = communes.map((commune) => {
    return {
      value: commune.com_id,
      label:
        i18n.language == "kh" ? commune.com_name_khmer : commune.com_name_latin,
      name: commune.com_name_latin,
    };
  });

  useEffect(() => {
    const data = getValues();

    if (data.commune == null && selectRef.current) {
      selectRef.current.select.clearValue();
    }
  }, [communes]);

  return (
    <FormControl
      variant="outlined"
      className={props.class}
      error={!!errors.commune?.message}
    >
      <InputLabel>{props.title}</InputLabel>
      <Controller
        name="commune"
        control={props.control}
        render={({ onChange }) => (
          <Select
            ref={selectRef}
            options={communeOptions}
            isLoading={loading}
            onChange={(selected) => {
              onChange(selected);
            }}
            styles={selectStyles(errors.commune?.message)}
            defaultValue={communeOptions.find(
              (option) => option.value == defaultValue
            )}
          />
        )}
        defaultValue={communeOptions.find(
          (option) => option.value == defaultValue
        )}
        rules={{ required: "Required" }}
      />
      {!!errors.commune?.message && (
        <FormHelperText error={!!errors.commune?.message}>
          {errors.commune?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CommuneSelect;
