import {
  FETCH_NATIONALITIES_REQUEST,
  FETCH_NATIONALITIES_SUCCESS,
  FETCH_NATIONALITIES_FAILURE,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchNationalities = () => async (dispatch) => {
  dispatch({
    type: FETCH_NATIONALITIES_REQUEST,
  });

  try {
    const res = await axios.get(`${config.API_BASE_URL}/nationalities`);

    dispatch({
      type: FETCH_NATIONALITIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_NATIONALITIES_FAILURE,
      payload: err,
    });
  }
};
