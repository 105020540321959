import React, { useEffect } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector, useDispatch } from "react-redux";
import TuktukDriverPayment from "./TuktukDriverPayment";
import EmployerPayment from "./EmployerPayment";
import EmployerPaymentFailure from "./EmployerPaymentFailure";
import Header from "../../components/Header";
import { UserRoles, RegistrationStatuses } from "../../constants";

import { createLoadingSelector } from "../../store/selectors";

import { checkPaymentFailure } from "../../store/actions/paymentFailureActions";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 32,
    [theme.breakpoints.up("md")]: {
      minHeight: 360,
    },
  },
  title: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  message: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    marginTop: 8,
  },
}));

const Payment = (props) => {
  const dispatch = useDispatch();
  const { user, loading } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const paymentFailures = useSelector((state) => state.paymentFailure.items);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/Profile/EmployerPaymentList",
    },
  ];

  const onPressButtonBack = () => {
    history.push("/");
  };

  useEffect(() => {
    dispatch(checkPaymentFailure());
  }, []);

  if (user.role === UserRoles.DOMESTIC_WORKER) {
    return <Redirect to="/" />;
  }

  if (user.pre_registration_status !== RegistrationStatuses.VALIDATED) {
    return (
      <>
        <Header
          title={"Employer contribution payment"}
          breadcrumbs={breadcrumbs}
          onPressButtonBack={onPressButtonBack}
        />
        <Container component="main">
          <Box className={classes.root}>
            <Typography className={classes.title}>
              {t("payment.nssfContributors")}
            </Typography>
            <Typography className={classes.message}>
              {t("payment.yourPreRegistrationMustBeValidatedByNssfFirst")}
            </Typography>
          </Box>
        </Container>
      </>
    );
  }

  if (loading) {
    return null;
  }

  if (user.role === UserRoles.TUKTUK_DRIVER) {
    return <TuktukDriverPayment {...props} />;
  }

  return paymentFailures.length ? (
    <EmployerPaymentFailure {...props} />
  ) : (
    <EmployerPayment {...props} />
  );
};

const loadingSelector = createLoadingSelector(["paymentFailure"]);
const mapStateToProps = (state) => ({ loading: loadingSelector(state) });
export default connect(mapStateToProps)(Payment);
