import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LanguageIcon from "@material-ui/icons/Language";

import Button from "@material-ui/core/Button";
import Header from "../../components/Header";

import Tracker from "../../services/tracker";

import {
  NSSF_CONTACT_EMAIL,
  NSSF_CALL_CENTER_PHONE,
} from "../../constants/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  buttonCallUs: {
    position: "relative",
    "& .MuiSvgIcon-root": {
      marginRight: 20,
    },
  },
  buttonCallUsInner: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing("12px", 0),
    position: "relative",
  },
  buttonCallUsTitle: {
    display: "block",
    borderRadius: 20,
    fontSize: 14,
    borderColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    padding: theme.spacing("2px", "18px"),
  },
  callUsLabel: {
    fontSize: 12,
    textTransform: "none",
    position: "absolute",
    top: -12,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, "6px"),
    height: 20,
  },
  buttonEmail: {
    padding: theme.spacing("11px", "21px"),

    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },
  },

  /*Responsive*/
  desktopContact: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      paddingTop: 18,
    },
  },
  desc: {
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "20px",
    padding: theme.spacing("15px", 0),
    alignItems: "center",
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    borderBottomWidth: 2,

    "& h6": {
      fontWeight: 600,
    },
  },
  buttonEmailD: {
    padding: 10,
    height: 48,
    width: 239,
    fontSize: 12,
  },
  buttonBranch: {
    padding: 10,
    height: 48,
    width: 239,
    textTransform: "lowercase",
    fontSize: 12,
  },
  callButton: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 0,
    width: 239,
    fontSize: 20,
    fontWeight: 600,
  },
}));

const Contact = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const phone = NSSF_CALL_CENTER_PHONE.replace(/\s/g, "");

  const user = useSelector((state) => state.auth.user);

  let breadcrumbs = [{ title: t("contacts.contact"), route: "/contact" }];

  if (user) {
    breadcrumbs.unshift({
      title: t(`common.${user.role}`),
      route: "/",
    });
  }

  const onPressFreeCall = () => {
    Tracker.track(Tracker.TRACKER_PAGES.FREE_CALL_CENTER);
  };

  const onPressButtonBack = () => {
    history.push("/");
  };

  return (
    <>
      <Header
        title={t("common.contact")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />

      <Container component="main">
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size={"large"}
              disableElevation
              className={classes.buttonCallUs}
              href={`tel:${phone}`}
              onClick={onPressFreeCall}
            >
              <CallIcon />
              <span className={classes.buttonCallUsInner}>
                <span className={classes.callUsLabel}>
                  {t("contacts.callUs")}
                </span>
                <span border={1} className={classes.buttonCallUsTitle}>
                  {t("contacts.freeCallCenter")}
                </span>
              </span>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size={"large"}
              disableElevation
              className={classes.buttonEmail}
              startIcon={<EmailIcon />}
              href={`mailto:${NSSF_CONTACT_EMAIL}`}
            >
              {t("contacts.contactUsVaiEmail")}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.desktopContact}>
          <Grid item xs={12} className={classes.desc}>
            <Typography variant="h6" gutterBottom>
              {t("contacts.freeCallCenter")}
            </Typography>
            <Button
              color="primary"
              size={"large"}
              disableElevation
              className={classes.callButton}
              startIcon={<CallIcon />}
              onClick={onPressFreeCall}
              href={`tel:${phone}`}
            >
              {NSSF_CALL_CENTER_PHONE}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.desc}>
            <Typography variant="h6" gutterBottom>
              {t("registration.email")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size={"large"}
              disableElevation
              className={classes.buttonEmailD}
              startIcon={<EmailIcon />}
              href={`mailto:${NSSF_CONTACT_EMAIL}`}
            >
              {t("contacts.contactUsVaiEmail")}
            </Button>
          </Grid>

          <Grid item xs={12} className={classes.desc}>
            <Typography variant="h6" gutterBottom>
              {t("contacts.NSSFBranches")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size={"large"}
              disableElevation
              className={classes.buttonBranch}
              startIcon={<LanguageIcon />}
              href="http://www.nssf.gov.kh/"
              target="_blank"
            >
              {"nssf.gov.kh"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
