import moment from "moment";

export const createDateFromString = (dateString) => {
  var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
  var dateArray = reggie.exec(dateString);
  var dateObject = new Date(
    Date.UTC(
      +dateArray[1],
      +dateArray[2] - 1, // Careful, month starts at 0!
      +dateArray[3],
      +dateArray[4],
      +dateArray[5],
      +dateArray[6]
    )
  );

  return dateObject;
};

export const getYearRange = (start, end) => {
  let startDate = moment(start).startOf("year");
  const endDate = moment(end).endOf("year");
  const years = [];

  var current = startDate;

  while (current.isBefore(endDate)) {
    years.push(current);

    current = current.clone().add(1, "year");
  }

  return years;
};

export const getPreviousYear = (current) => {
  return moment(current).clone().subtract(1, "years");
};

export const getNextYear = (current) => {
  return moment(current).clone().add(1, "years");
};

export const getMonthRangeName = (startAt, endAt) => {
  let diff = moment(endAt).diff(moment(startAt), "months", true);

  if (diff > 1) {
    // PREDICATED: Always display year
    // if (moment(startAt).year() != moment(endAt).year()) {
    //   return (
    //     getMonthName(startAt) +
    //     " " +
    //     moment(startAt).year() +
    //     " - " +
    //     getMonthName(endAt) +
    //     " " +
    //     moment(endAt).year()
    //   );
    // }
    // return getMonthName(startAt) + " - " + getMonthName(endAt);

    return (
      getMonthName(startAt) +
      " " +
      moment(startAt).year() +
      " - " +
      getMonthName(endAt) +
      " " +
      moment(endAt).year()
    );
  }
  return getMonthName(startAt);
};

export const getMonthName = (date) => {
  return moment(date).clone().format("MMMM");
};

export const getShortDateFormat = (date) => {
  return moment(date).clone().format("DD/MM/YYYY");
};

export const getDateFormat = (date) => {
  return moment(date).clone().format("DD MMM YYYY");
};
