import {
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILURE,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchDistricts = (provinceId) => async (dispatch) => {
  dispatch({
    type: FETCH_DISTRICTS_REQUEST,
  });

  try {
    const res = await axios.get(
      `${config.API_BASE_URL}/districts?province_id=${provinceId}`
    );

    dispatch({
      type: FETCH_DISTRICTS_SUCCESS,
      payload: { [provinceId]: res.data.data },
    });
  } catch (err) {
    dispatch({
      type: FETCH_DISTRICTS_FAILURE,
      payload: err,
    });
  }
};

export const resetDistricts = () => async (dispatch) => {
  dispatch({
    type: FETCH_DISTRICTS_SUCCESS,
    payload: [],
  });
};
