export const DEFAULT_LOCALE = "kh";

export const defaultMinDate = new Date("2020-01-01");
export const defaultMaxDate = new Date("2099-12-31");

export const UserRoles = Object.freeze({
  TUKTUK_DRIVER: "tuktuk_driver",
  DOMESTIC_WORKER: "domestic_worker",
  EMPLOYER: "employer",
});

export const UserPositionLabels = Object.freeze({
  [UserRoles.TUKTUK_DRIVER]: "Tuk tuk Driver",
  [UserRoles.DOMESTIC_WORKER]: "Domestic worker",
  [UserRoles.EMPLOYER]: "Employer",
});

export const ContributionRate = 2.6;
export const TuktukDriverContributionFee = 20000;

export const PaymentMethods = Object.freeze({
  ABA: "aba",
  ACLEDA: "acleda",
  CANADIA: "canadia",
  JTRUST_ROYAL: "jtrust_royal",
  CASH_PAYMENT: "cash_payment",
});

export const SUPPORTED_PAYMENT_GATEWAYS = [
  PaymentMethods.ABA,
  PaymentMethods.CANADIA,
  PaymentMethods.ACLEDA,
  PaymentMethods.JTRUST_ROYAL,
];

export const RegistrationStatuses = Object.freeze({
  IN_PROGRESS: "in_progress",
  SUBMITTED: "submitted",
  VALIDATED: "validated",
});

export const RegistrationPageTitle = Object.freeze({
  [UserRoles.TUKTUK_DRIVER]: "tuktukDriverPreRegistration",
  [UserRoles.DOMESTIC_WORKER]: "domesticWorkerPreRegistration",
  [UserRoles.EMPLOYER]: "employerPreRegistration",
});

export const TransactionStatuses = Object.freeze({
  AWAITING_CONFIRMATION: "awaiting_confirmation",
  COMPLETED: "completed",
  FAILED: "failed",
  REFUNDED: "refunded",
  CANCELED: "canceled",
});

export const currencySymbols = {
  KHR: "៛",
  USD: "$",
  VND: "₫",
  THB: "฿",
};

export const NSSF_CONTACT_EMAIL = "registration@nssf.gov.kh";
export const NSSF_WEBSITE = "www.nssf.gov.kh";
export const NSSF_WEBSITE_URL = "http://www.nssf.gov.kh";
export const NSSF_FACEBOOK_PAGE = "https://www.facebook.com/nssfpage";
export const NSSF_CALL_CENTER_PHONE = "855 92 123 123";
