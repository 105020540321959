import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {},
}));

const Message = (props) => {
  const classes = useStyles();
  const { notification } = props;

  return (
    <>
      <Typography variant="h5">{notification.data.title}</Typography>
      <Typography variant="body2">{notification.data.content}</Typography>
    </>
  );
};
export default Message;
