import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";

import clsx from "clsx";

import { UserPositionLabels } from "../../constants";

import ListItem from "./ListItem";
import { getShortDateFormat } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: 60,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    paddingTop: 32,
    paddingBottom: 16,
  },
  list: {
    padding: 0,
  },

  listLeft: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 16,
      borderRightWidth: 2,
      borderRightColor: "#BCE0FD",
      borderRightStyle: "solid",
    },
  },
  listRight: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
    },
  },

  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginTop: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  name: {
    fontWeight: "700",
    marginTop: 8,
  },
  subtitle: {
    fontSize: 14,
  },
  label: {
    marginRight: 8,
  },
  buttons: {
    marginTop: 32,
    paddingBottom: 12,
  },
  button: {
    fontSize: 12,
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
    },
  },
  buttonLeft: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  userIcon: {
    "&.MuiAvatar-root": {
      width: 60,
      height: 60,
    },
  },
}));

const Profile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { registration, user } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPressUpdatePhoneNumber = () => {
    history.push("/profile/update-phone");
  };

  const dateOfBirthFormated = () => {
    let dateOfBirth = registration.date_of_birth || user.date_of_birth;

    if (dateOfBirth) {
      return getShortDateFormat(dateOfBirth);
    }

    return "―";
  };

  const getFullNameLatin = () => {
    return user.first_name_latin + " " + user.last_name_latin;
  };

  const getFullNameKH = () => {
    return user.first_name_kh + " " + user.last_name_kh;
  };

  return (
    <div>
      <div className={classes.header}>
        <Avatar className={classes.userIcon} />
        <Typography className={classes.name}>{getFullNameLatin()}</Typography>
        <Typography className={classes.subtitle}>
          <span className={classes.label}>{t("registration.nssfId")}:</span>
          <span>{user.nssf_id || "―"}</span>
        </Typography>
      </div>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <List className={clsx(classes.list, classes.listLeft)}>
            <ListItem
              text={t("registration.fullNameKH")}
              value={getFullNameKH()}
            />
            <ListItem
              text={t("registration.fullNameLATIN")}
              value={getFullNameLatin()}
            />
            <ListItem
              text={t("registration.position")}
              value={t(`common.${user.role}`)}
            />
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List className={clsx(classes.list, classes.listRight)}>
            <ListItem
              text={t("registration.dateOfBirth")}
              value={dateOfBirthFormated()}
            />
            <ListItem
              text={t("registration.nationalID")}
              value={user.national_id || registration.national_id || "―"}
            />

            <ListItem
              text={t("registration.phoneNumber")}
              value={user.phone || "―"}
            />
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.buttons}>
        <Grid item xs={6} className={classes.buttonLeft}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.button}
            onClick={onPressUpdatePhoneNumber}
          >
            {t("registration.updateMyPhoneNumber")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.button}
            onClick={handleClickOpen}
          >
            {t("registration.editInformation")}
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("profiles.editInformation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("profiles.pleaseGoToNSSFToChangeInformation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("common.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Profile;
