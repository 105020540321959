import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import languages from "./locales";

i18next.use(initReactI18next).init(
  {
    lng: "en",
    debug: false,
    resources: languages,
  },
  function (err, t) {}
);

export default i18next;
