import React from "react";

import { Container, Stepper, StepLabel, Step } from "@material-ui/core";
import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import { UserRoles } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: 180,
    paddingBottom: 70,
    paddingTop: 10,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  stepper: {
    margin: theme.spacing(0, "24px"),
    "& .MuiStep-root": { padding: 0 },
    "& .MuiStepLabel-root": {
      flexDirection: "column",
      position: "relative",
    },
    "& .MuiStepLabel-iconContainer": {
      justifyContent: "center",
      paddingRight: 0,
    },
    "& .MuiStepLabel-labelContainer": {
      display: "block",
      position: "absolute",
      minWidth: 180,
      top: 110,
      textAlign: "center",
    },
    "& .MuiStepConnector-line": {
      borderColor: "#BCE0FD",
    },
    "& .MuiStepIcon-root": {
      width: 60,
      height: 60,
    },
    "& .MuiStepIcon-text": {
      fontSize: 10,
    },
    "& .MuiStepLabel-label": {
      fontSize: 20,
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  },
  stepIcon: {
    width: 100,
    height: 100,
    borderRadius: 50,
    fontWeight: 700,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#F2F2F2",
    color: theme.palette.primary.main,
    fontSize: 36,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    textDecoration: "none",
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  employer: {
    width: "50%",
    margin: theme.spacing(0, "auto"),
  },
}));

const Steps = ({ activeStep, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let steps = [
    {
      route: "/pre-registration",
      title: t("registration.personalInformations"),
    },
    {
      route: "/pre-registration/upload",
      title: t("registration.uploadYourDocumentPhotos"),
    },
    {
      route: "/pre-registration/success",
      title: t("registration.preRegistrationConfirmation"),
    },
  ];

  if (user.role == UserRoles.EMPLOYER) {
    steps.splice(1, 1);
  }

  const stepIcons = (props) => {
    if (props.completed) {
      return (
        <RouterLink
          className={clsx(classes.stepIcon, {
            [classes.active]: props.active,
          })}
          to={steps[props.icon - 1].route}
        >
          {props.icon}
        </RouterLink>
      );
    }

    return (
      <div
        className={clsx(classes.stepIcon, {
          [classes.active]: props.active,
        })}
      >
        {props.icon}
      </div>
    );
  };

  const isEmployer = () => {
    return user.role == UserRoles.EMPLOYER;
  };

  return (
    <div className={classes.root}>
      <Container>
        <Stepper
          className={clsx(classes.stepper, {
            [classes.employer]: isEmployer(),
          })}
          activeStep={activeStep}
        >
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={stepIcons}>
                  {step.title}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Container>
    </div>
  );
};

export default Steps;
