import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import SvgIcon from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import BannerMobile from "../../assets/banner@1.png";
import Banner from "../../assets/banner@2.png";
import { generateParamsUrl } from "../../utils/url";

import { ReactComponent as TuktukIcon } from "../../assets/icons/tuktuk.svg";
import { ReactComponent as WorkerIcon } from "../../assets/icons/worker.svg";
import { ReactComponent as EmployerIcon } from "../../assets/icons/employer.svg";
import { ReactComponent as RegisterIcon } from "../../assets/icons/account-edit.svg";
import { ReactComponent as AccountIcon } from "../../assets/icons/account.svg";
import { ReactComponent as InformationIcon } from "../../assets/icons/information.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/money.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";

import PreRegistrationSuccessPopup from "../../components/PreRegistration/SuccessPopup";

import { getUser } from "../../store/actions/authActions";
import { fetchNotifications } from "../../store/actions/notificationActions";
import { updatePreRegistrationStatus } from "../../store/actions/registrationActions";

import Intro from "../../components/Intro";
import {
  UserRoles,
  RegistrationStatuses,
  RegistrationPageTitle,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 56,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    [theme.breakpoints.up("md")]: {
      paddingTop: 64,
    },
  },
  paper: {
    paddingTop: 16,
    display: "inline-flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  banner: {
    height: 150,
    marginLeft: -16,
    "&.MuiCardMedia-root": {
      backgroundPositionY: "23%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  button: {
    fontSize: 14,
    height: "100%",
    fontWeight: "700",
    padding: theme.spacing(1.66, 2),
    "& .MuiButton-label": {
      flexDirection: "column",
      lineHeight: "17px",
    },
    "&:hover": {
      backgroundColor: "rgb(242,242,242)",
    },
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
  },
  button2: {
    fontSize: 14,
    height: "100%",
    fontWeight: "700",
    minHeight: 120,
    padding: theme.spacing(3, "14px"),
    "& .MuiButton-label": {
      flexDirection: "column",
      textAlign: "center",
      lineHeight: "17px",
    },

    "&.Mui-disabled": {
      backgroundColor: "#6AB8FC",
      color: "#f2f2f2",
    },
  },
  icon: {
    fontSize: 28,
    opacity: 0.5,
    marginBottom: 10,
  },

  /*Responsive*/
  bannerContainer: {
    position: "relative",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  paperDesktop: {
    display: "flex",
    justifyContent: "space-around",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
  bannerDesktop: {
    display: "none",
    backgroundColor: "#2699FB",
    backgroundImage: "none !important",
    [theme.breakpoints.up("md")]: {
      display: "block",
      backgroundSize: "cover",
      position: "relative",
      height: 720,
    },
  },
  eachButton: {
    width: 250,
    height: 110,
    fontSize: 14,
    textAlign: "center",
    fontWeight: "700",
    letterSpacing: 1,
    paddingLeft: 45,
    paddingRight: 45,
    padding: theme.spacing(3, "14px"),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "rgb(242,242,242)",
    },
    "& .MuiButton-label": {
      flexDirection: "column",
      lineHeight: "17px",
    },
  },
  information: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      marginTop: 18,
      paddingBottom: 42,
    },
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();

  const menus = [
    {
      title: t("common.tuktukDriver"),
      route: "/login" + generateParamsUrl({ role: UserRoles.TUKTUK_DRIVER }),
      icon: TuktukIcon,
    },
    {
      title: t("common.domesticWorker"),
      route: "/login" + generateParamsUrl({ role: UserRoles.DOMESTIC_WORKER }),
      icon: WorkerIcon,
    },
    {
      title: t("common.employer"),
      route: "/login" + generateParamsUrl({ role: UserRoles.EMPLOYER }),
      icon: EmployerIcon,
    },
    {
      title: t("common.information"),
      route: "/information",
      icon: InformationIcon,
      outline: true,
    },
  ];

  const menusDesktop = [
    {
      title: t("registration.tukTukDriverPreRegisteration"),
      route: "/login" + generateParamsUrl({ role: UserRoles.TUKTUK_DRIVER }),
      icon: TuktukIcon,
    },
    {
      title: t("registration.domesticWorkerPreRegisteration"),
      route: "/login" + generateParamsUrl({ role: UserRoles.DOMESTIC_WORKER }),
      icon: WorkerIcon,
    },
    {
      title: t("registration.employerPreRegisteration"),
      route: "/login" + generateParamsUrl({ role: UserRoles.EMPLOYER }),
      icon: EmployerIcon,
    },
  ];

  const menusAuth = [
    {
      title: t("common.myProfile"),
      route: "profile?tab=personal-infos",
      icon: AccountIcon,
    },
    {
      title: t("common.preRegistration"),
      route: "pre-registration",
      icon: RegisterIcon,
    },
    {
      title: t("common.contributionPayment"),
      route: "contribution-payment",
      icon: MoneyIcon,
    },
    {
      title: t("common.workingContractSample"),
      route: "/working-contract",
      icon: FileIcon,
    },
    {
      title: t("common.information"),
      route: "/information",
      icon: InformationIcon,
    },
    {
      title: t("common.contact"),
      route: "/contact",
      icon: PinIcon,
    },
  ];

  const menusAuthDesktop = [
    {
      title: t("common.myProfile"),
      route: "profile",
      icon: AccountIcon,
    },

    {
      title: t("common.contributionPayment"),
      route: "contribution-payment",
      icon: MoneyIcon,
    },
  ];

  const onClickMenu = (e, menu) => {
    if (
      menu.route == "pre-registration" &&
      user.pre_registration_status === RegistrationStatuses.SUBMITTED
    ) {
      e.preventDefault();

      setOpen(true);
    }
  };

  const isDisabledContributionPayment = (menu) => {
    if (
      menu.route == "contribution-payment" &&
      user.role == UserRoles.DOMESTIC_WORKER
    ) {
      return true;
    }

    return false;
  };

  const isDisabledPreRegistration = (menu) => {
    if (
      menu.route == "pre-registration" &&
      user.pre_registration_status === RegistrationStatuses.VALIDATED
    ) {
      return true;
    }

    return false;
  };

  const isDisabled = (menu) => {
    return (
      isDisabledPreRegistration(menu) || isDisabledContributionPayment(menu)
    );
  };

  const onCloseSuccessDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    // refresh user in store
    if (user) {
      dispatch(getUser());
      dispatch(fetchNotifications());

      if (user.pre_registration_status == RegistrationStatuses.SUBMITTED) {
        dispatch(updatePreRegistrationStatus());
      }
    }
  }, []);

  return (
    <Container maxWidth={false} component="main" className={classes.container}>
      {/* <CardMedia className={classes.banner} image={BannerMobile} /> */}

      <div className={classes.bannerContainer}>
        <CardMedia className={classes.bannerDesktop} image={Banner} />
        <Grid container spacing={2} className={classes.paperDesktop}>
          {!isLoggedIn && (
            <>
              {menusDesktop.map((menu, index) => (
                <Grid item key={index}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size={"large"}
                    disableElevation
                    className={clsx(classes.eachButton, {
                      [classes.button]: menu.outline,
                    })}
                    to={menu.route}
                    component={RouterLink}
                  >
                    <SvgIcon className={classes.icon}>
                      <menu.icon />
                    </SvgIcon>
                    {menu.title}
                  </Button>
                </Grid>
              ))}
            </>
          )}

          {isLoggedIn && (
            <>
              {menusAuthDesktop.map((menu, index) => (
                <Grid item key={index}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size={"large"}
                    disableElevation
                    className={classes.eachButton}
                    disabled={isDisabledContributionPayment(menu)}
                    to={menu.route}
                    component={RouterLink}
                    onClick={(e) => onClickMenu(e, menu)}
                  >
                    <SvgIcon className={classes.icon}>
                      <menu.icon />
                    </SvgIcon>
                    {menu.title}
                  </Button>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </div>

      <Container>
        <Grid container spacing={2} className={classes.paper}>
          {!isLoggedIn && (
            <>
              {menus.map((menu, index) => (
                <Grid item xs={6} key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size={"large"}
                    disableElevation
                    className={clsx(classes.button2, {
                      [classes.button]: menu.outline,
                    })}
                    to={menu.route}
                    component={RouterLink}
                  >
                    <SvgIcon className={classes.icon}>
                      <menu.icon />
                    </SvgIcon>
                    {menu.title}
                  </Button>
                </Grid>
              ))}
            </>
          )}

          {isLoggedIn && (
            <>
              {menusAuth.map((menu, index) => (
                <Grid item xs={6} key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size={"large"}
                    disableElevation
                    disabled={isDisabled(menu)}
                    className={classes.button2}
                    to={menu.route}
                    component={RouterLink}
                    onClick={(e) => onClickMenu(e, menu)}
                  >
                    <SvgIcon className={classes.icon}>
                      <menu.icon />
                    </SvgIcon>
                    {menu.title}
                  </Button>
                </Grid>
              ))}

              <PreRegistrationSuccessPopup
                open={open}
                title={t(`registration.${RegistrationPageTitle[user.role]}`)}
                onClose={onCloseSuccessDialog}
              />
            </>
          )}
        </Grid>

        <Grid container spacing={2} className={classes.information}>
          <Intro />
        </Grid>
      </Container>
    </Container>
  );
};

export default Home;
