import {
  FETCH_CONTRIBUTION_HISTORY_REQUEST,
  FETCH_CONTRIBUTION_HISTORY_SUCCESS,
} from "../types";

const initialState = {
  items: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTRIBUTION_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CONTRIBUTION_HISTORY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}
