import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Logo from "../components/Logo";
import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import PublicIcon from "@material-ui/icons/Public";
import FacebookIcon from "@material-ui/icons/Facebook";
import PreRegistrationSuccessPopup from "../components/PreRegistration/SuccessPopup";
import {
  RegistrationPageTitle,
  NSSF_FACEBOOK_PAGE,
  NSSF_WEBSITE_URL,
} from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5),
    display: "none",
    marginTop: 32,

    [theme.breakpoints.up("sm")]: {
      display: "block",

      position: "absolute",
      bottom: 0,
      width: "100%",
    },
  },
  side: {
    padding: theme.spacing(2),
    display: "flex",
  },
  listStyle: {
    listStyle: "none",
    fontSize: 12,
    margin: 0,
    paddingLeft: 20,
    borderLeftWidth: 1.3,
    borderLeftStyle: "solid",
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.light,
    },
    "& li": {
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  list: {
    marginBottom: 5,
  },
  icon: {
    fontSize: 35,
  },
  logo: {
    margin: "auto",
  },
  copyRight: {
    fontSize: 9,
    textAlign: "center",
  },
  link: {
    padding: theme.spacing(2),
    display: "flex",
    margin: "auto",
  },
  refer: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    marginRight: 55,
    "& :hover": {
      color: theme.palette.primary.light,
    },
  },
  public: {
    display: "flex",
    alignItems: "center",
  },
}));

const Footer = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const onClickMenu = (e) => {
    if (user.pre_registration_status == "submitted") {
      e.preventDefault();

      setOpen(true);
    }
  };

  const onCloseSuccessDialog = () => {
    setOpen(false);
  };

  return (
    <footer className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={2} className={classes.side}>
          <Logo className={classes.logo}></Logo>
        </Grid>
        <Grid item xs={3} className={classes.side}>
          <ul className={classes.listStyle}>
            {user && (
              <RouterLink
                to="/pre-registration"
                onClick={(e) => onClickMenu(e)}
              >
                <li className={classes.list}>{t("footer.preRegistration")}</li>
              </RouterLink>
            )}

            <RouterLink to="/working-contract">
              <li>{t("common.workingContractSample")}</li>
            </RouterLink>
          </ul>
        </Grid>
        <Grid item xs={3} className={classes.side}>
          <ul className={classes.listStyle}>
            <RouterLink to="/information">
              <li className={classes.list}>{t("footer.information")}</li>
            </RouterLink>
            <RouterLink to="/contact">
              <li className={classes.list}>{t("footer.contact")}</li>
            </RouterLink>
          </ul>
        </Grid>
        <Grid item xs={4} className={classes.link}>
          <a href={NSSF_WEBSITE_URL} target="_blank" className={classes.refer}>
            <div className={classes.public}>
              <PublicIcon className={classes.icon}></PublicIcon>
              <p>www.nssf.gov.kh</p>
            </div>
          </a>
          <a
            href={NSSF_FACEBOOK_PAGE}
            target="_blank"
            className={classes.refer}
          >
            <div className={classes.public}>
              <FacebookIcon className={classes.icon}></FacebookIcon>
            </div>
          </a>
        </Grid>
      </Grid>
      <div className={classes.copyRight}>{t("footer.copyRight")}</div>
      {isLoggedIn && (
        <PreRegistrationSuccessPopup
          open={open}
          title={t(`registration.${RegistrationPageTitle[user.role]}`)}
          onClose={onCloseSuccessDialog}
        />
      )}
    </footer>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Footer);
