import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 28,
    paddingTop: 0,
  },
  formControl: {
    minWidth: "100%",
  },
  title: {
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginBottom: 8,
    marginLeft: 8,
  },
  separator: {
    height: 2,
    backgroundColor: "#BCE0FD",
    marginBottom: 12,
  },
}));

const Contacts = ({ user, registration, register, control, errors }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          id="phone"
          label={t("registration.phoneNumber")}
          name="phone"
          inputRef={register({ required: "Required" })}
          defaultValue={user.phone}
          variant="outlined"
          disabled={true}
          error={!!errors.phone?.message}
          helperText={errors.phone?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="fax"
          label={t("registration.fax")}
          name="fax"
          inputRef={register()}
          defaultValue={registration.fax}
          variant="outlined"
          error={!!errors.fax?.message}
          helperText={errors.fax?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="email"
          label={t("registration.email")}
          name="email"
          inputRef={register({
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          defaultValue={registration.email}
          variant="outlined"
          error={!!errors.email?.message}
          helperText={errors.email?.message}
          className={classes.formControl}
        />
      </Grid>
    </>
  );
};

export default Contacts;
