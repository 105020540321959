import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { UserRoles } from "../../constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  title: { textAlign: "center" },
  error: {
    fontSize: 16,
    textAlign: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  buttonQuit: {
    fontSize: 12,
    fontWeight: "700",

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 306,
    },
  },
}));

const PaymentSuccess = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const status = query.get("status");

  const user = useSelector((state) => state.auth.user);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/contribution-payment",
    },
    {
      title: t("common.paymentProcessing"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/contribution-payment");
  };

  const onPressQuit = () => {
    history.push("/");
  };

  if (!status) {
    history.push("/");
  }

  return (
    <>
      <Header
        title={
          user.role === UserRoles.EMPLOYER
            ? t("payment.employerContributionPayment")
            : t("contribution.TuktukDriverContribution")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Box className={classes.container}>
          {status === "success" && (
            <Typography className={classes.title}>
              {t("payment.paymentSuccessful")}
            </Typography>
          )}

          {status === "failed" && (
            <Typography className={classes.title}>
              {t("payment.paymentFailed")}
            </Typography>
          )}
        </Box>

        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.buttonQuit}
            onClick={onPressQuit}
          >
            {t("registration.quit")}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default PaymentSuccess;
