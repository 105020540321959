import React, {useState} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Typography, useMediaQuery } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@material-ui/icons/Info";

import Header from "../components/Header";

import { logout } from "../store/actions/authActions";


const useStyles = makeStyles((theme) => ({
  root: {
    top: "104px !important",

    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },

    "& .MuiDialog-paper": {
      boxShadow: "none",
    },

    [theme.breakpoints.up("md")]: {
      top: "0 !important",

      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },

      "& .MuiDialogContent-root": {
        paddingBottom: 24,
      },
    },
  },

  header: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  dialogTitle: {
    padding: 0,
    paddingTop: 8,
    height: 48,
    color: "white",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    alignItems: "center",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 32,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    textAlign: "center",
    lineHeight: "32px",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  errorIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#f44336",
    fontSize: 48,
  },
  buttonBack: {
    fontSize: 12,
    fontWeight: "700",

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 306,
    },
  },
  footer: {
    paddingTop: 16,
    paddingBottom: 12,
    display: "flex",
    justifyContent: "center",
  },
}));

const PaymentFailureModal = ({ open }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(open);

  const popup = useMediaQuery(theme.breakpoints.up("md"));

  const onPressBack = () => {
    setIsOpen(false);
    dispatch(logout());
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="responsive-dialog-title"
      fullScreen={!popup}
      className={classes.root}
    >
      <DialogTitle className={classes.header}>
        <Header title={t("payment.paymentFailure")} />
      </DialogTitle>

      <DialogTitle className={classes.dialogTitle}>
        {t("payment.paymentFailure")}
      </DialogTitle>

      <DialogContent>
        <div className={classes.titleContainer}>
          <span className={classes.errorIcon}>
            <InfoIcon fontSize="large"></InfoIcon>
          </span>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("payment.paymentFailureMessage")}
          </Typography>
        </div>

        <div className={classes.footer}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.buttonBack}
            onClick={onPressBack}
          >
            {t("common.backToHomepage")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentFailureModal;
