import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { getTotalContribution } from "../../utils/payment";
import PrintPayment from "./PrintPayment";
import {
  TransactionStatuses,
  PaymentMethods,
  UserRoles,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {},
  contribution: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
  },
  status: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    paddingTop: 0,
  },
  title: { fontSize: 15, fontWeight: 700, color: theme.palette.primary.main },
  paymentStatus: {
    color: theme.palette.error.dark,
  },
  receiptItem: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

const TuktukDriverPaymentHistory = ({ payment, isLast }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const total = getTotalContribution(UserRoles.TUKTUK_DRIVER, [payment]);

  return (
    <div className={classes.root}>
      <div className={clsx(classes.contribution)}>
        <Typography className={classes.title}>
          {t("common.contribution")}
        </Typography>
        <Typography className={classes.title}>{total}</Typography>
      </div>

      <div className={clsx(classes.status)}>
        <Typography className={classes.title}>
          {t("common.paymentMethod")}
        </Typography>
        <Typography className={classes.title}>
          {t(`payment.${payment.payment_method}`)}
        </Typography>
      </div>

      <div className={clsx(classes.status)}>
        <Typography className={classes.title}>{t("payment.status")}</Typography>
        <Typography
          className={clsx(classes.title, {
            [classes.paymentStatus]:
              payment.status === TransactionStatuses.AWAITING_CONFIRMATION ||
              payment.status === TransactionStatuses.CANCELED ||
              payment.status === TransactionStatuses.FAILED,
          })}
        >
          {t(`payment.${payment.status}`)}
        </Typography>
      </div>

      {payment.payment_method == PaymentMethods.CASH_PAYMENT && (
        <div className={clsx(classes.status)}>
          <Typography className={classes.title}>
            {t("payment.receipt")}
          </Typography>
          <Typography className={clsx(classes.title, classes.receiptItem)}>
            {payment.payment_receipt_url != "" && (
              <a href={payment.payment_receipt_url} target="_blank">
                <ReceiptIcon />
              </a>
            )}
          </Typography>
        </div>
      )}

      <PrintPayment
        contribution={{ payments: [payment] }}
        userRole={UserRoles.TUKTUK_DRIVER}
        hasBottomBorder={!isLast}
      />
    </div>
  );
};
export default TuktukDriverPaymentHistory;
