import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Roboto", "Hanuman", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    background: {
      default: "#F2F2F2",
    },
    primary: { main: "#2699FB", contrastText: "#fff" },
  },
  overrides: {
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },

    MuiSelect: {
      root: {
        backgroundColor: "#fff",
      },
    },

    MuiInputLabel: {
      outlined: {
        color: "#2699FB",
        top: -42,
        transform: "translate(0, 24px) scale(0.7)",
        "&$shrink": {
          transform: "translate(0, 24px) scale(0.7)",
        },
      },

      root: {
        "&.Mui-disabled": {
          color: "#2699FB",
        },
      },
    },

    MuiFormControl: {
      root: {
        marginTop: 28,
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "#2699FB",

        "&.Mui-disabled": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2699FB",
          },
        },
      },

      notchedOutline: {
        borderColor: "#2699FB",
        top: 0,
        "& legend": {
          display: "none",
        },
      },
    },
  },
});
export default theme;
