import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingTop: 36,
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
}));

const Loading = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.styles)}>
      <CircularProgress size={32} />
    </div>
  );
};

export default Loading;
