import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItemSecondaryAction,
  ListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginTop: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  value: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

const ListItem = ({ text, value }) => {
  const classes = useStyles();

  return (
    <MuiListItem className={classes.item}>
      <ListItemText primary={text} />
      <ListItemSecondaryAction className={classes.value}>
        {value}
      </ListItemSecondaryAction>
    </MuiListItem>
  );
};

export default ListItem;
