import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Typography,
  Button,
  Container,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import Header from "../../components/Header";
import EmployeeNotFound from "../../components/Profile/EmployeeNotFound";

import { searchEmployee } from "../../store/actions/employeesAction";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginTop: 48,
  },
  input: {
    minWidth: "100%",
    marginTop: 0,

    "& input": {
      textAlign: "center",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: 20,
    },
  },
  button: {
    marginTop: 48,
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const SearchEmployeeContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, control, errors } = useForm();
  const [openDialog, setOpenDialog] = React.useState(false);

  const error = useSelector((state) => state.employees.error);

  const breadcrumbs = [
    {
      title: t("common.myProfile"),
      route: "/profile?tab=employees",
    },
    {
      title: t("registration.addEmployee"),
    },
  ];

  const handleDisplayDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onPressButtonBack = () => {
    history.push("/profile?tab=employees");
  };

  const onPressSubmit = (data) => {
    dispatch(searchEmployee(data));
  };

  useEffect(() => {
    const data = getValues();

    if (error && data.nssf_id) {
      handleDisplayDialog();
    }
  }, [error]);

  return (
    <>
      <Header
        title={t("registration.addEmployee")}
        onPressButtonBack={onPressButtonBack}
        breadcrumbs={breadcrumbs}
      />

      <Container component="main" maxWidth="xs">
        <Typography className={classes.title} variant="body2" gutterBottom>
          {t("profiles.pleaseEnterYourEmployeeNssfIdNumber")}
        </Typography>

        <form
          onSubmit={handleSubmit(onPressSubmit)}
          noValidate
          autoComplete="off"
        >
          <FormControl className={classes.input}>
            <TextField
              id="nssf_id"
              name="nssf_id"
              inputRef={register({
                required: "Required",
              })}
              variant="outlined"
              error={!!errors.nssf_id?.message}
              helperText={errors.nssf_id?.message}
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            size={"large"}
            className={classes.button}
            disableElevation
          >
            {t("common.ok")}
          </Button>
        </form>

        <EmployeeNotFound open={openDialog} onClose={handleCloseDialog} />
      </Container>
    </>
  );
};

export default SearchEmployeeContainer;
