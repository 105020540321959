import React, { useRef, useEffect } from "react";
import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectStyles } from "../../theme/sharedStyles";

const DistrictSelect = (props) => {
  const { t, i18n } = useTranslation();
  const selectRef = useRef(null);

  const { name, getValues, defaultValue, errors, onChange, provinceId } = props;

  const districts = useSelector((state) => state.districts.items);
  const loading = useSelector((state) => state.districts.loading);

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const districtOptions = districts[provinceId]
    ? districts[provinceId].map((district) => {
        return {
          value: district.dis_id,
          label:
            i18n.language == "kh"
              ? district.dis_name_khmer
              : district.dis_name_latin,
          name: district.dis_name_latin,
        };
      })
    : [];

  useEffect(() => {
    const data = getValues();

    if (data[name] == null && selectRef.current) {
      selectRef.current.select.clearValue();
    }
  }, [districts]);

  return (
    <FormControl
      variant="outlined"
      className={props.class}
      error={!!errors[name]?.message}
    >
      <InputLabel>{props.title}</InputLabel>
      <Controller
        name={name}
        control={props.control}
        render={({ onChange }) => (
          <Select
            options={districtOptions}
            isLoading={loading}
            ref={selectRef}
            onChange={(selected) => {
              onChange(selected);
              handleChange(selected);
            }}
            styles={selectStyles(errors[name]?.message)}
            defaultValue={districtOptions.find(
              (option) => option.value == defaultValue
            )}
          />
        )}
        defaultValue={districtOptions.find(
          (option) => option.value == defaultValue
        )}
        rules={{ required: "Required" }}
      />
      {!!errors[name]?.message && (
        <FormHelperText error={!!errors[name]?.message}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DistrictSelect;
