import {
  FETCH_ASSOCIATIONS_REQUEST,
  FETCH_ASSOCIATIONS_SUCCESS,
  FETCH_ASSOCIATIONS_FAILURE,
  USER_UPDATE_PHONE_SUCCESS,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchAssociations = () => async (dispatch) => {
  dispatch({
    type: FETCH_ASSOCIATIONS_REQUEST,
  });

  try {
    const res = await axios.get(
      `${config.API_BASE_URL}/users?role=association`
    );

    dispatch({
      type: FETCH_ASSOCIATIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ASSOCIATIONS_FAILURE,
      payload: err.response.data,
    });
  }
};

export const updateUserPhone = (id, data, callback) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${config.API_BASE_URL}/users/${id}/update_phone_number`,
      data
    );

    dispatch({
      type: USER_UPDATE_PHONE_SUCCESS,
      payload: res.data.data,
    });

    localStorage.setItem("auth", JSON.stringify(res.data.data));

    callback(res.data.data);
  } catch (err) {
    callback(err.response.data);
  }
};
