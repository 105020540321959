import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import PWAPrompt from "react-ios-pwa-prompt";

// PWA
import * as serviceWorkerRegistration from "./pwa/serviceWorkerRegistration";
import reportWebVitals from "./pwa/reportWebVitals";

// STORE
import store from "./store/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <PWAPrompt />
  </React.StrictMode>,
  document.getElementById("root")
);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
