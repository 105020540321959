import _ from "lodash";
import {
  CHECK_PAYMENT_FAILURE_REQUEST,
  CHECK_PAYMENT_FAILURE_SUCCESS,
} from "../types";
import { axios } from "../../utils/request";
import { config } from "../../config";

import { getUser } from "./authActions";

export const checkPaymentFailure = () => async (dispatch) => {
  try {
    dispatch({
      type: CHECK_PAYMENT_FAILURE_REQUEST,
    });

    const response = await axios.get(
      `${config.API_BASE_URL}/payments/check-payment-failure`
    );

    // refresh user to get payment failure state
    dispatch(getUser());

    dispatch({
      type: CHECK_PAYMENT_FAILURE_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {}
};
