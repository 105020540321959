import React, { useEffect, useState } from "react";
import moment from "moment";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Grid,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import NSSFIDControl from "./NSSFIDControl";
import NationalitySelect from "./NationalitySelect";

import { selectStyles } from "../../theme/sharedStyles";

import { UserRoles } from "../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  datePicker: {
    minWidth: "100%",

    "& input": {
      padding: "17.5px 14px",
    },

    "& label": {
      top: -42,
      color: "#2699FB",
      zIndex: 1,
      transform: "translate(0, 24px) scale(0.7)",
      pointerEvents: "none",
    },

    "& .MuiInput-formControl": {
      marginTop: 0,
      color: "#2699FB",
      borderColor: "#2699FB",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
      paddingRight: 14,
      backgroundColor: "white",
    },
  },
}));

const General = ({
  user,
  registration,
  associations,
  register,
  control,
  errors,
  setValue,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [dateOfBirth, setDateOfBirth] = useState(
    moment(
      registration.date_of_birth || moment(user.date_of_birth) || new Date()
    )
  );

  let associationOptions = associations
    .filter((association) => {
      return association.association_type == user.role;
    })
    .map((association) => {
      let associationName =
        i18n.language === "kh"
          ? association.full_name_kh
          : association.full_name_latin;
      return {
        value: association.id,
        label: t("registration.memberOf") + " " + associationName,
      };
    });
  associationOptions.push({
    value: "",
    label: t("registration.noAssociationMember"),
  });

  const genderOptions = [
    { value: "male", label: t("register.genderMale") },
    { value: "female", label: t("register.genderFemale") },
  ];

  const isDomesticWorker = () => {
    return user.role === UserRoles.DOMESTIC_WORKER;
  };

  const renderAssociationControl = () => {
    if (user.role == UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <Grid container item xs={12}>
        <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{t("registration.areYouAssociationMember")}</InputLabel>
            <Controller
              name="association"
              control={control}
              options={associationOptions}
              as={Select}
              styles={selectStyles()}
              defaultValue={associationOptions.find(
                (option) =>
                  option.value ==
                  (registration.association_id
                    ? registration.association_id
                    : "")
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderDateOfBirthControl = () => {
    if (user.role == UserRoles.EMPLOYER) {
      return;
    }

    return (
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            autoOk
            label={t("registration.dateOfBirth")}
            value={dateOfBirth}
            onChange={(date) => {
              setDateOfBirth(date);
              setValue("date_of_birth", date, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: <EventIcon />,
            }}
            format="DD/MM/yyyy"
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    );
  };

  const renderPhoneControl = () => {
    if (user.role == UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="phone"
          label={t("registration.phoneNumber")}
          name="phone"
          disabled={true}
          inputRef={register({ required: "Required" })}
          defaultValue={user.phone}
          variant="outlined"
          error={!!errors.phone?.message}
          helperText={errors.phone?.message}
          className={classes.formControl}
        />
      </Grid>
    );
  };

  const renderNationalityControl = () => {
    return (
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <NationalitySelect
          class={classes.formControl}
          title={t("registration.nationality")}
          control={control}
          errors={errors}
          defaultValue={registration.nationality_id}
        />
      </Grid>
    );
  };

  useEffect(() => {
    register("date_of_birth");
  }, [register]);

  useEffect(() => {
    setValue("date_of_birth", dateOfBirth, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, []);

  return (
    <>
      <NSSFIDControl
        user={user}
        registration={registration}
        register={register}
        errors={errors}
      />

      {renderAssociationControl()}

      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="first_name_kh"
          label={t("registration.firstNameKH")}
          name="first_name_kh"
          inputRef={register({ required: "Required" })}
          defaultValue={registration.first_name_kh || user.first_name_kh}
          variant="outlined"
          error={!!errors.first_name_kh?.message}
          helperText={errors.first_name_kh?.message}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="last_name_kh"
          label={t("registration.lastNameKH")}
          name="last_name_kh"
          inputRef={register({ required: "Required" })}
          defaultValue={registration.last_name_kh || user.last_name_kh}
          variant="outlined"
          error={!!errors.last_name_kh?.message}
          helperText={errors.last_name_kh?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="first_name_latin"
          label={t("registration.firstNameLATIN")}
          name="first_name_latin"
          inputRef={register({ required: "Required" })}
          defaultValue={registration.first_name_latin || user.first_name_latin}
          variant="outlined"
          error={!!errors.first_name_latin?.message}
          helperText={errors.first_name_latin?.message}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="last_name_latin"
          label={t("registration.lastNameLATIN")}
          name="last_name_latin"
          inputRef={register({ required: "Required" })}
          defaultValue={registration.last_name_latin || user.last_name_latin}
          variant="outlined"
          error={!!errors.last_name_latin?.message}
          helperText={errors.last_name_latin?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={!!errors.gender?.message}
        >
          <InputLabel>{t("registration.gender")}</InputLabel>
          <Controller
            name="gender"
            control={control}
            options={genderOptions}
            as={Select}
            styles={selectStyles(errors.gender?.message)}
            defaultValue={genderOptions.find(
              (option) => option.value == (registration.gender || user.gender)
            )}
            rules={{ required: "Required" }}
          />
          {!!errors.gender?.message && (
            <FormHelperText error={!!errors.gender?.message}>
              {errors.gender?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      {renderNationalityControl()}
      {renderDateOfBirthControl()}

      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="national_id"
          name="national_id"
          label={t("registration.nationalID")}
          inputRef={register({ required: "Required" })}
          defaultValue={registration.national_id}
          variant="outlined"
          error={!!errors.national_id?.message}
          helperText={errors.national_id?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="passport_no"
          name="passport_no"
          label={t("registration.passport") + " #"}
          inputRef={register()}
          defaultValue={registration.passport_no}
          variant="outlined"
          className={classes.formControl}
        />
      </Grid>

      {renderPhoneControl()}
    </>
  );
};

export default General;
