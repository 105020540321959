import axios from "axios";
import { config } from "../config/";

const updateTransactionStatus = async (data) => {
  return await axios
    .post(`${config.API_BASE_URL}/transactions/status`, data)
    .then((response) => {
      return response.data.data;
    });
};

export default { updateTransactionStatus };
