const selectStyles = (hasError) => ({
  control: (styles) => ({
    ...styles,
    height: 56,
    borderColor: hasError ? "#f44336" : "#2699FB",
    color: "#2699FB",

    "&:focus": {
      outline: "none",
    },

    "&:hover": {
      borderColor: hasError ? "#f44336" : "#2699FB",
    },
  }),
  singleValue: (styles) => ({ ...styles, color: "#2699FB" }),
  menu: (styles) => ({ ...styles, marginTop: 2, zIndex: 10 }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: hasError ? "#f44336" : "#2699FB",
  }),
});

export { selectStyles };
