import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    paddingBottom: 4,
  },
  footer: {
    paddingBottom: 12,
    paddingRight: 16,
  },
  button: {
    backgroundColor: "#f54242",
    fontSize: 12,

    "&:hover": {
      backgroundColor: "#e60000",
    },
  },
  buttonCancel: {
    fontSize: 12,
  },
}));

const ConfirmDialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { title, children, open, setOpen, onConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle className={classes.title} id="confirm-dialog">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={() => setOpen(false)} className={classes.buttonCancel}>
          {t("actions.no")}
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="secondary"
          variant="contained"
          className={classes.button}
        >
          {t("actions.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
