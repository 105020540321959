import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TextField, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

import { createOrUpdateRegistration } from "../../store/actions/registrationActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 24,
    paddingBottom: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,

      borderLeftColor: "#BCE0FD",
      borderLeftStyle: "solid",
      borderLeftWidth: 2,

      paddingLeft: 28,
    },
  },
  title: {
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginBottom: 10,
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: 10,
    },
  },
  formControl: {
    minWidth: "100%",
  },
  item: { position: "relative" },
  buttonRemove: {
    position: "absolute",
    bottom: 18,
    right: 6,
  },
  button: {
    marginTop: 8,
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },
    "& .MuiSvgIcon-root": {
      display: "none",
    },

    [theme.breakpoints.up("md")]: {
      marginTop: 30,
      fontSize: 16,

      "&.MuiButton-root": {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: "solid",
        backgroundColor: "white",
        height: 48,
        width: 48,
      },

      "& .MuiTypography-root": {
        display: "none",
      },

      "& .MuiSvgIcon-root": {
        display: "block",
      },
    },
  },
}));

const Employers = ({ registration, register, control }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [employers, setEmployers] = useState([]);

  const onPressAddEmployer = () => {
    setEmployers([...employers, ""]);
  };

  const onPressDelete = (index) => {
    let newEmployes = [...employers];
    newEmployes = newEmployes.filter((item, i) => i !== index);

    setEmployers(newEmployes);

    let params = {
      employer_nssf_ids: newEmployes,
      user_id: registration.user_id,
    };

    dispatch(createOrUpdateRegistration(params));
  };

  const onChange = (index, e) => {
    let newEmployers = [...employers];
    newEmployers[index] = e.target.value.replace(/ +?/g, "");
    setEmployers(newEmployers);

    let params = {
      employer_nssf_ids: newEmployers,
      user_id: registration.user_id,
    };

    dispatch(createOrUpdateRegistration(params));
  };

  useEffect(() => {
    if (registration.employer_nssf_ids) {
      setEmployers(registration.employer_nssf_ids);
    }
  }, []);

  return (
    <div className={classes.paper}>
      <Typography className={classes.title}>{t("common.employers")}</Typography>
      {employers.map((nssfId, index) => (
        <div className={classes.item} key={index}>
          <TextField
            name={"nssf_id_" + index}
            label={"Employer #" + (index + 1) + " NSSF ID number"}
            inputRef={register({ required: true })}
            defaultValue={nssfId}
            onChange={(e) => onChange(index, e)}
            variant="outlined"
            className={classes.formControl}
          />
          <IconButton
            color="primary"
            aria-label="remove employer nssf id"
            className={classes.buttonRemove}
            size="small"
            onClick={() => onPressDelete(index)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="primary"
        fullWidth
        size={"large"}
        disableElevation
        className={classes.button}
        onClick={onPressAddEmployer}
        variant="outlined"
      >
        <Typography>{t("registration.addEmployer")}</Typography>
        <AddIcon />
      </Button>
    </div>
  );
};

export default Employers;
