import axios from "axios";
import { config } from "../config/";

const generateOtpCode = async (data) => {
  return await axios
    .post(`${config.API_BASE_URL}/otps`, data)
    .then((response) => {
      return response;
    });
};

const verifyOtpCode = async (data) => {
  return await axios
    .post(`${config.API_BASE_URL}/otps/verify`, data)
    .then((response) => {
      return response;
    });
};

const verifyOtpCodeAndLogin = async (data) => {
  return await axios
    .post(`${config.API_BASE_URL}/auth/login-phone`, data)
    .then((response) => {
      return response;
    });
};

export default { generateOtpCode, verifyOtpCode, verifyOtpCodeAndLogin };
