import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button as MuiButton, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "relative",
    left: -8,
  },
}));

const Button = ({
  title,
  color,
  size,
  type,
  disabled,
  loading,
  className,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      variant="contained"
      color={color || "default"}
      fullWidth
      size={size || "large"}
      onClick={onClick}
      disableElevation
      className={className}
      disabled={disabled}
      type={type || "button"}
    >
      {loading && <CircularProgress className={classes.loading} size={14} />}
      {title}
    </MuiButton>
  );
};

export default Button;
