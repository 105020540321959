import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { useHistory, NavLink as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: "space-between",
    position: "fixed",
    top: 56,
    left: 0,
    right: 0,
    [theme.breakpoints.up("md")]: {
      top: 64,
    },
    zIndex: 9,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "600",
    flex: 1,
    textAlign: "center",
  },
  icon: {
    color: theme.palette.common.white,
  },

  centerComponent: {
    flex: 8,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  leftComponent: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  rightComponent: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonBack: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  breadcrumb: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  breadcrumbItem: {
    color: theme.palette.common.white,
    fontSize: 16,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();

  const breadcrumbs = props.breadcrumbs || [];

  const onPressButtonBack = () => {
    if (props.onPressButtonBack) {
      props.onPressButtonBack();
    } else {
      history.push("/");
    }
  };

  const isLast = (index) => {
    return breadcrumbs.length - 1 == index;
  };

  return (
    <Toolbar className={classes.toolbar} variant="dense">
      <div className={clsx(classes.leftComponent, classes.buttonBack)}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={onPressButtonBack}
        >
          <ArrowBackIcon className={classes.icon} />
        </IconButton>
      </div>
      <div className={classes.centerComponent}>
        <Typography className={classes.title}>{props.title}</Typography>
      </div>

      <div className={classes.rightComponent}></div>

      <Container className={classes.breadcrumb}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <NavigateNextIcon fontSize="small" style={{ color: "#fff" }} />
          }
        >
          <Link
            color="inherit"
            to={"/"}
            component={RouterLink}
            className={classes.breadcrumbItem}
          >
            {t("common.nssf")}
          </Link>

          {breadcrumbs.map((crumb, ci) => {
            if (isLast(ci)) {
              return (
                <Typography key={ci} className={classes.breadcrumbItem}>
                  {crumb.title}
                </Typography>
              );
            }
            return (
              <Link
                color="inherit"
                to={crumb.route}
                component={RouterLink}
                className={classes.breadcrumbItem}
                key={ci}
              >
                {crumb.title}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Container>
    </Toolbar>
  );
};
export default Header;
