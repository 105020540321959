import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Box, Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import General from "../../components/PreRegistration/General";
import Address from "../../components/PreRegistration/Address";
import PlaceOfBirth from "../../components/PreRegistration/PlaceOfBirth";

import MaritalStatus from "../../components/PreRegistration/MaritalStatus";
import DrivingLicense from "../../components/PreRegistration/DrivingLicense";
import Employers from "../../components/PreRegistration/Employers";
import Contacts from "../../components/PreRegistration/Contacts";
import Steps from "../../components/PreRegistration/Steps";
import Button from "../../components/Button";

import { UserRoles } from "../../constants";

import { createOrUpdateRegistration } from "../../store/actions/registrationActions";

import { fetchDistricts } from "../../store/actions/districtActions";
import { fetchCommunes } from "../../store/actions/communeActions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  root: {},
  paper: {
    marginTop: theme.spacing(4),
    paddingBottom: 42,

    "& .MuiFormControl-root": {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 5,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 8,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginTop: 0,
      marginBottom: 16,
      fontSize: 20,
    },
  },
  message: {
    fontSize: 14,
    color: theme.palette.primary.main,
    paddingRight: 30,
    paddingLeft: 30,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 40,
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  personalInformation: {
    [theme.breakpoints.up("md")]: {
      "& .MuiGrid-item": {
        "& .MuiFormControl-root": {
          paddingRight: 28,
        },
      },
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  footer: {
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  button: {
    margin: 0,
    marginTop: 16,

    [theme.breakpoints.up("md")]: {
      maxWidth: 240,
      fontSize: 12,
      fontWeight: "700",
      margin: theme.spacing(0, "auto"),
      marginTop: 32,
    },
  },

  address: {
    [theme.breakpoints.up("md")]: {
      borderLeftColor: "#BCE0FD",
      borderLeftStyle: "solid",
      borderLeftWidth: 2,
    },
  },
  guide: {
    color: theme.palette.primary.main,
    padding: theme.spacing("26px", "30px", 0),
  },
  blank: { [theme.breakpoints.up("md")]: { width: "100%", minHeight: 178 } },
  blankLarge: {
    [theme.breakpoints.up("md")]: { width: "100%", minHeight: 267 },
  },
}));

const PersonalInformations = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    control,
    errors,
    watch,
    setValue,
  } = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { user, loading, registration } = props;
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.preRegistration"),
      route: "/pre-registration",
    },
  ];

  const onSubmit = (data) => {
    setIsLoading(true);
    const params = getParams(data);

    saveRegistration(params);
  };

  const onError = (errors, e) => {
    const params = getParams(getValues());

    // Save user informations even all fields are not valid
    dispatch(createOrUpdateRegistration(params));
  };
  const isTukTukDriver = () => {
    return user.role === UserRoles.TUKTUK_DRIVER;
  };
  const isDomesticWorker = () => {
    return user.role === UserRoles.DOMESTIC_WORKER;
  };
  const isEmployer = () => {
    return user.role === UserRoles.EMPLOYER;
  };

  const saveRegistration = (data) => {
    dispatch(createOrUpdateRegistration(data)).then(() => {
      setIsLoading(false);

      if (user.role == UserRoles.EMPLOYER) {
        history.push("/pre-registration/confirmation");
      } else {
        history.push("/pre-registration/upload");
      }
    });
  };

  const getParams = (data) => {
    const params = {
      ...data,
      nationality_id: data.nationality?.value,
      nationality: data.nationality?.label,
      marital_status: data.marital_status?.value,
      province_id: data.province?.value,
      province: data.province?.label,
      district_id: data.district?.value,
      district: data.district?.label,
      commune_id: data.commune?.value,
      commune: data.commune?.label,
      association_id: data.association?.value,
      gender: data.gender?.value,
      date_of_birth: data.date_of_birth.format(),
      phone: user.phone,
      user_id: user.id,
      children_no: data.children_no || 0,
      pob_province_id: data.pob_province?.value,
      pob_district_id: data.pob_district?.value,
      pob_province_name: data.pob_province?.label,
      pob_district_name: data.pob_district?.label,
    };
    delete params.association;

    return params;
  };

  const renderDrivingLicenseInput = () => {
    if (user.role != UserRoles.TUKTUK_DRIVER) {
      return null;
    }

    return (
      <DrivingLicense
        registration={props.registration}
        register={register}
        control={control}
        errors={errors}
      />
    );
  };

  const renderMaritalStatus = () => {
    return (
      <MaritalStatus
        registration={props.registration}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
      ></MaritalStatus>
    );
  };

  const renderEmployments = () => {
    if (user.role != UserRoles.DOMESTIC_WORKER) {
      return null;
    }
    return (
      <Grid container item xs={12} md={4}>
        <Employers
          registration={props.registration}
          register={register}
          errors={errors}
        />
      </Grid>
    );
  };

  const renderContact = () => {
    if (user.role != UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <Contacts
        user={user}
        registration={props.registration}
        register={register}
        errors={errors}
      />
    );
  };

  useEffect(() => {
    if (registration.province_id) {
      dispatch(fetchDistricts(registration.province_id));
    }
    if (registration.pob_province_id) {
      dispatch(fetchDistricts(registration.pob_province_id));
    }
    if (registration.district_id) {
      dispatch(fetchCommunes(registration.district_id));
    }
  }, []);

  return (
    <>
      <Header
        title={t(`registration.${props.pageTitle}`)}
        breadcrumbs={breadcrumbs}
      />
      <Container
        component="main"
        maxWidth={false}
        className={classes.container}
      >
        <Steps activeStep={0} user={user}></Steps>
        <Container className={classes.paper}>
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Grid container>
              <Grid
                container
                item
                xs={12}
                md={isDomesticWorker() ? 4 : 8}
                className={classes.personalInformation}
              >
                <Typography className={classes.title} variant="h3" gutterBottom>
                  {t("registration.personalInformations")}
                </Typography>
                <Typography
                  className={classes.message}
                  variant="body2"
                  gutterBottom
                >
                  Lorem dolor sit amet consectetur adipisicing elit, sed do.
                </Typography>
                <General
                  registration={registration}
                  associations={props.associations}
                  register={register}
                  control={control}
                  watch={watch}
                  user={user}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
                {renderMaritalStatus()}
                {renderDrivingLicenseInput()}
                {renderContact()}

                <div
                  className={
                    isTukTukDriver()
                      ? classes.blank
                      : isEmployer()
                      ? classes.blankLarge
                      : ""
                  }
                ></div>
              </Grid>

              <Grid container item xs={12} md={4}>
                <Box className={classes.address}>
                  <PlaceOfBirth
                    registration={registration}
                    register={register}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                  />

                  <Address
                    registration={registration}
                    register={register}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                  ></Address>
                </Box>
              </Grid>

              {renderEmployments()}
            </Grid>

            <Box className={classes.guide}>
              {t("registration.successGuideNote")}
            </Box>

            <div className={classes.footer}>
              <Button
                title={t("actions.next")}
                color="primary"
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                className={classes.button}
              ></Button>
            </div>
          </form>
        </Container>
      </Container>
    </>
  );
};

export default PersonalInformations;
