import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";

import Header from "../../components/Header";

import { markAsReadNotification } from "../../store/actions/notificationActions";

import { getDateFormat } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  container: {},
  wrapper: {
    backgroundColor: "#fff",
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#2699FB",
    borderStyle: "solid",
    borderRadius: 6,
    overflow: "hidden",

    "& .MuiList-root": {
      backgroundColor: "#F5F7F7",
      padding: 0,
    },
  },
  item: {
    backgroundColor: "#F5F7F7",
    boxShadow: "inset 0 -1px 0 0 rgb(100 121 143 / 12%)",
    paddingTop: 8,
    paddingBottom: 8,

    "& .MuiListItemText-primary": {
      fontWeight: 400,
      color: "#000",
    },
  },
  unread: {
    backgroundColor: "#fff",
    "& .MuiListItemText-primary": {
      fontWeight: 700,
    },
  },
  date: {
    marginTop: 5,
  },
  blankslate: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: theme.spacing("28px", 0),

    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
}));

const Notifications = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.notifications.items);
  const loading = useSelector((state) => state.notifications.loading);

  let breadcrumbs = [
    { title: t("common.notifications"), route: "/notifications" },
  ];

  if (user) {
    breadcrumbs.unshift({
      title: t(`common.${user.role}`),
      route: "/",
    });
  }

  const onPressButtonBack = () => {
    history.push("/");
  };

  const onPressNotification = (item) => {
    if (item.read_at == null) {
      dispatch(markAsReadNotification(item.id));
    }

    history.push("/notifications/" + item.id);
  };

  const renderDate = (item) => {
    let date = new Date(item.created_at);
    return getDateFormat(date);
  };

  const renderBlankslate = () => {
    if (!loading && notifications.length == 0) {
      return (
        <Typography className={classes.blankslate}>
          {t("common.noNotification")}
        </Typography>
      );
    }
  };

  const getNotificationTitle = (notification) => {
    if (notification.type === "Message") {
      return notification.data.title;
    }

    return t("notifications.transactionsStatusUpdate");
  };

  const getNotificationContent = (notification) => {
    if (notification.type === "Message") {
      let content = notification.data.content;

      if (content.length > 100) {
        return content.substring(0, 100) + "...";
      }

      return content;
    }

    return t("notifications.theStatusOfYouBelowTransactionsHasBeenUpdated");
  };

  return (
    <>
      <Header
        title={t("common.notifications")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" className={classes.container}>
        <Box className={classes.wrapper}>
          <List dense={true} className={classes.list}>
            {notifications.map((item, index) => (
              <ListItem
                button
                onClick={() => onPressNotification(item)}
                key={index}
                className={clsx(
                  classes.item,
                  item.read_at == null ? classes.unread : ""
                )}
              >
                <ListItemText
                  className={classes.title}
                  primary={getNotificationTitle(item)}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {getNotificationContent(item)}
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.date}
                      >
                        {renderDate(item)}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>

          {renderBlankslate()}
        </Box>
      </Container>
    </>
  );
};

export default Notifications;
