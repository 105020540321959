import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useHistory } from "react-router-dom";

import { register as registerUser } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import {
  TextField,
  FormControlLabel,
  Typography,
  Button,
  Container,
  Checkbox,
  Link,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import Header from "../../components/Header";

import { getParamsUrl } from "../../utils/url";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 16,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginTop: 10,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    textAlign: "center",
    marginBottom: 0,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),

    "& .MuiIconButton-label .MuiSvgIcon-root": {
      color: "#2699FB",
    },
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  errors: {
    margin: 0,
  },
  policy: {
    color: theme.palette.primary.main,
    marginTop: -5,
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  gender: {
    marginTop: 10,
  },
  genderTitle: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    paddingLeft: "0px",
  },
  genderGroup: {
    display: "initial !important",
    marginTop: -5,
    marginBottom: -10,
    color: theme.palette.primary.main,
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  datePicker: {
    minWidth: "100%",
    marginTop: 12,

    "& label": {
      top: -25,
      color: "#2699FB",
      zIndex: 1,
      transform: "translate(0, 24px) scale(0.7)",
      pointerEvents: "none",
    },

    "& input": {
      padding: "18.5px 14px",
    },

    "& .MuiInput-formControl": {
      color: "#2699FB",
      borderColor: "#2699FB",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
      paddingRight: 14,
      backgroundColor: "white",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  footer: {
    marginTop: 18,
  },
}));

const isFieldMandatory = (registeredBy, fieldName) => {
  let requiredFields = {
    facebook: ["name", "birthday", "gender", "phone"],
    google: ["name", "birthday", "gender", "phone"],
    phone: ["name", "phone", "birthday", "gender"],
  };

  if (
    registeredBy in requiredFields &&
    requiredFields[registeredBy].includes(fieldName)
  ) {
    return true;
  }

  return false;
};

const Register = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const now = moment();

  let paramsUrl = getParamsUrl(props.location.search);

  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const [phone, setPhone] = useState(
    localStorage.getItem("phone") ? localStorage.getItem("phone") : ""
  );

  const [nameLatin, setNameLatin] = useState(
    localStorage.getItem("facebook_name")
      ? localStorage.getItem("facebook_name")
      : localStorage.getItem("google_name")
      ? localStorage.getItem("google_name")
      : ""
  );

  const { register, handleSubmit, control, errors, setError } = useForm();

  const facebookId = localStorage.getItem("facebook_user_id");
  const googleId = localStorage.getItem("google_user_id");
  const registered_by = facebookId ? "facebook" : googleId ? "google" : "phone";

  const [dateOfBirth, setDateOfBirth] = useState(now);

  const [isAcceptPolicyAndTerm, setIsAcceptPolicyAndTerm] = useState(false);
  const error = useSelector((state) => state.auth.error);

  const onSubmit = (data) => {
    const user = {
      first_name_kh: data.first_name_kh,
      last_name_kh: data.last_name_kh,
      first_name_latin: data.first_name_latin,
      last_name_latin: data.last_name_latin,
      date_of_birth: dateOfBirth.format(),
      gender: data.gender,
      role: params.role.trim(),
      registered_by: registered_by,
    };
    if (facebookId && facebookId !== "") {
      user["facebook_user_id"] = facebookId;
    }
    if (googleId && googleId !== "") {
      user["google_user_id"] = googleId;
    }

    if (phone && phone !== "") {
      user["phone"] = phone;
    } else if (data.phone) {
      user["phone"] = data.phone;
    }

    dispatch(registerUser(user)).then((res) => {
      handleSetError(res);
    });
  };

  const breadcrumbs = [
    {
      title: t("register.register"),
      route: "/register/:role",
    },
  ];

  const onPressButtonBack = () => {
    history.push("/");
  };

  const handleSetError = (res) => {
    if (res && res.error && res.error.errors) {
      for (var err of res.error.errors) {
        if (err == "The phone has already been taken.") {
          setError("phone", {
            type: "server",
            message: t("registration.phoneAlreadyBeenTaken"),
          });
        }
      }
    }
  };

  return (
    <>
      <Header
        title={t("register.register")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography className={classes.title} variant="h2">
            {t("register.title")}
          </Typography>

          <Typography variant="body2" className={classes.subtitle}>
            {t("register.subtitle")}
          </Typography>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            noValidate
          >
            <TextField
              name="first_name_kh"
              inputRef={register({ required: "Required" })}
              id="first_name_kh"
              label={
                isFieldMandatory(registered_by, "name")
                  ? t("registration.firstNameKH") + " *"
                  : t("registration.firstNameKH")
              }
              variant="outlined"
              error={!!errors.first_name_kh?.message}
              helperText={errors.first_name_kh?.message}
              className={classes.formControl}
            />

            <TextField
              name="last_name_kh"
              inputRef={register({ required: "Required" })}
              id="last_name_kh"
              label={
                isFieldMandatory(registered_by, "name")
                  ? t("registration.lastNameKH") + " *"
                  : t("registration.lastNameKH")
              }
              variant="outlined"
              error={!!errors.last_name_kh?.message}
              helperText={errors.last_name_kh?.message}
              className={classes.formControl}
            />

            <TextField
              name="first_name_latin"
              defaultValue={nameLatin}
              inputRef={register({ required: "Required" })}
              id="first_name_latin"
              label={
                isFieldMandatory(registered_by, "name")
                  ? t("registration.firstNameLATIN") + " *"
                  : t("register.firstNameLATIN")
              }
              variant="outlined"
              error={!!errors.first_name_latin?.message}
              helperText={errors.first_name_latin?.message}
              className={classes.formControl}
            />

            <TextField
              name="last_name_latin"
              defaultValue={nameLatin}
              inputRef={register({ required: "Required" })}
              id="last_name_latin"
              label={
                isFieldMandatory(registered_by, "name")
                  ? t("registration.lastNameLATIN") + " *"
                  : t("register.lastNameLATIN")
              }
              variant="outlined"
              error={!!errors.last_name_latin?.message}
              helperText={errors.last_name_latin?.message}
              className={classes.formControl}
            />

            {registered_by == "phone" ? (
              <TextField
                name="phone"
                inputRef={register({ required: "Required" })}
                id="phone"
                defaultValue={phone}
                label={
                  isFieldMandatory(registered_by, "phone")
                    ? t("register.phone") + " *"
                    : t("register.phone")
                }
                variant="outlined"
                className={classes.formControl}
                disabled
              />
            ) : (
              <TextField
                name="phone"
                inputRef={register({ required: "Required" })}
                id="phone"
                label={
                  isFieldMandatory(registered_by, "phone")
                    ? t("register.phone") + " *"
                    : t("register.phone")
                }
                variant="outlined"
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
                className={classes.formControl}
              />
            )}

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                label={t("register.birthday") + " *"}
                format="MM/DD/yyyy"
                value={dateOfBirth}
                onChange={setDateOfBirth}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <EventIcon />,
                }}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>

            <FormControl
              component="fieldset"
              className={classes.gender}
              error={!!errors.gender?.message}
            >
              <FormLabel className={classes.genderTitle} component="legend">
                {isFieldMandatory(registered_by, "gender")
                  ? t("register.gender") + " *"
                  : t("register.gender")}
              </FormLabel>

              <Controller
                name="gender"
                control={control}
                defaultValue={false}
                rules={{ required: "Required" }}
                render={(props) => (
                  <RadioGroup
                    {...props}
                    className={classes.genderGroup}
                    aria-label="gender"
                    name={"gender"}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio color="primary" />}
                      label={t("register.genderFemale")}
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio color="primary" />}
                      label={t("register.genderMale")}
                    />
                  </RadioGroup>
                )}
              />
              {!!errors.gender?.message && (
                <FormHelperText error={!!errors.gender?.message}>
                  {errors.gender?.message}
                </FormHelperText>
              )}
            </FormControl>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.footer}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAcceptPolicyAndTerm}
                    onChange={(e) => setIsAcceptPolicyAndTerm(e.target.checked)}
                    name="is_accept_policy_and_term"
                    color="primary"
                  />
                }
                className={classes.policy}
                label={t("register.policy")}
              />

              <Link
                to={"/login?type=" + useParams().role.trim()}
                href={"/login?type=" + useParams().role.trim()}
                component={NavLink}
              >
                Login
              </Link>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size={"large"}
              className={classes.submit}
              fullWidth
              disableElevation
              disabled={!isAcceptPolicyAndTerm}
            >
              Continue
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Register;
