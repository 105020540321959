import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Tabs, Tab, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Header from "../../components/Header";
import Profile from "../../components/Profile/Profile";
import ContributionHistory from "./ContributionHistory";
import Employees from "./Employees";

import {
  fetchRegistration,
  updatePreRegistrationStatus,
} from "../../store/actions/registrationActions";
import { UserRoles, RegistrationStatuses } from "../../constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: { paddingBottom: 28 },
  paper: {
    padding: 0,
    marginTop: 20,
  },
  tabPanel: {
    padding: theme.spacing(1, "20px"),
    paddingBottom: 20,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopWidth: 0,
    backgroundColor: "white",
  },
  tabs: {
    marginBottom: -1,
    "& .MuiTab-root": {
      minWidth: 10,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
  },
  tab: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: "capitalize",
    padding: theme.spacing("6px", 0),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: "#f2f2f2",

    "&.Mui-selected": {
      borderBottomWidth: 0,
      backgroundColor: "white",
    },

    "&.MuiTab-textColorInherit": {
      opacity: 1,
    },
  },
  tabRight: {},
  tabCenter: { marginRight: -1 },
  tabLeft: { marginRight: -1 },
}));

const ProfileContainer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let query = useQuery();

  const { user } = props;

  const currentTab = {
    "personal-infos": 0,
    employees: 1,
    "contribution-history": user.role == UserRoles.EMPLOYER ? 2 : 1,
  };

  const defaultTab = currentTab[query.get("tab")] || 0;

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const registration = useSelector((state) => state.registrations.item);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.myProfile"),
    },
  ];

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    dispatch(fetchRegistration());

    if (user.pre_registration_status == RegistrationStatuses.SUBMITTED) {
      dispatch(updatePreRegistrationStatus());
    }
  }, []);

  const isDomesticWorker = () => {
    return user.role == UserRoles.DOMESTIC_WORKER;
  };

  const renderEmployeesTab = () => {
    if (user.role != UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <Tab
        label={t("common.employees")}
        component={Link}
        to={"/profile?tab=employees"}
        classes={{ root: clsx(classes.tab, classes.tabCenter) }}
      />
    );
  };

  const renderContributionHistoryTab = () => {
    if (user.role == UserRoles.DOMESTIC_WORKER) {
      return null;
    }

    return (
      <Tab
        label={t("profiles.contributionHistory")}
        component={Link}
        to={"/profile?tab=contribution-history"}
        classes={{ root: clsx(classes.tab, classes.tabRight) }}
      />
    );
  };

  const renderEmployeesTabPanel = () => {
    if (user.role != UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <TabPanel value={selectedTab} index={1} className={classes.tabPanel}>
        <Employees registration={registration} user={user}></Employees>
      </TabPanel>
    );
  };

  const renderContributionHistoryTabPanel = () => {
    if (user.role == UserRoles.DOMESTIC_WORKER) {
      return null;
    }

    return (
      <TabPanel
        value={selectedTab}
        index={user.role == UserRoles.EMPLOYER ? 2 : 1}
        className={classes.tabPanel}
      >
        <ContributionHistory />
      </TabPanel>
    );
  };

  return (
    <>
      <Header title={t("common.myProfile")} breadcrumbs={breadcrumbs} />
      <Container component="main" className={classes.container}>
        <Box className={classes.paper}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            indicatorColor="secondary"
            className={classes.tabs}
          >
            <Tab
              label={t("profiles.personalInformation")}
              component={Link}
              to={"/profile?tab=personal-infos"}
              classes={{
                root: clsx(classes.tab, {
                  [classes.tabLeft]: !isDomesticWorker(),
                }),
              }}
            />
            {renderEmployeesTab()}
            {renderContributionHistoryTab()}
          </Tabs>
          <TabPanel value={selectedTab} index={0} className={classes.tabPanel}>
            <Profile registration={registration} user={user}></Profile>
          </TabPanel>

          {renderEmployeesTabPanel()}
          {renderContributionHistoryTabPanel()}
        </Box>
      </Container>
    </>
  );
};

export default ProfileContainer;
