import _ from "lodash";
import { ContributionRate, TuktukDriverContributionFee } from "../constants";
import { format } from "./currency";
import { TransactionStatuses, UserRoles } from "../constants";

export const getTotalContribution = (userRole, payments) => {
  let total = 0;

  if (userRole === UserRoles.EMPLOYER) {
    total = _.sumBy(payments, function (payment) {
      // Sum only contribution of completed transactions
      if (payment.status === TransactionStatuses.COMPLETED) {
        return payment.contribution;
      } else {
        return 0;
      }
    });

    return format(total);
  }

  total = _.sumBy(payments, function (payment) {
    return payment.contribution * parseInt(payment.frequency);
  });
  return format(total);
};

export const getContribution = (payment, exchangeRates) => {
  let contribution = 0;

  let salary = salaryToRiel(payment, exchangeRates);

  if (salary) {
    contribution = (salary * ContributionRate) / 100;
  }

  return contribution;
};

export const getContributionWithCurrency = (payment, exchangeRates) => {
  const frequency = payment.frequency || 1;
  let contribution = getContribution(payment, exchangeRates);

  return format(contribution * frequency);
};

export const getTotalEmployeesContribution = (payments, exchangeRates) => {
  const total = _.sumBy(payments, function (payment) {
    let frequency = payment.frequency || 1;
    return getContribution(payment, exchangeRates) * frequency;
  });

  return total;
};

export const getTotalEmployeesContributionWithCurrency = (
  payments,
  exchangeRates
) => {
  const total = getTotalEmployeesContribution(payments, exchangeRates);

  return format(total);
};

export const getTotalTuktukDriverContribution = (frequency) => {
  return TuktukDriverContributionFee * frequency;
};

export const getTotalTuktukDriverContributionWithCurrency = (frequency) => {
  const total = getTotalTuktukDriverContribution(frequency);

  return format(total);
};

const salaryToRiel = (payment, exchangeRates) => {
  if (payment.salary_currency === "KHR") {
    return payment.salary;
  }

  let exchangeRate = _.chain(exchangeRates)
    .keyBy("code")
    .mapValues("rate")
    .value();

  return payment.salary * exchangeRate[payment.salary_currency];
};
