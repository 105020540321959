import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, Box, Button, useMediaQuery } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/Header";

import { createTransaction } from "../../store/actions/transactionActions";

const useStyles = makeStyles((theme) => ({
  root: {
    top: "104px !important",

    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },

    "& .MuiDialog-paper": {
      boxShadow: "none",
      width: 600,
    },

    "& .MuiDialogContent-root": {
      padding: 18,
      boxSizing: "border-box",
    },

    [theme.breakpoints.up("md")]: {
      top: "0 !important",

      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },

      "& .MuiDialogContent-root": {
        paddingBottom: 18,
      },
    },
  },

  header: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  dialogTitle: {
    padding: 0,
    paddingTop: 8,
    height: 48,
    color: "white",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    alignItems: "center",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 32,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  message: {
    fontSize: 18,
    color: theme.palette.primary.main,
    paddingRight: 30,
    paddingLeft: 30,
    textAlign: "center",
    marginBottom: 8,
  },
  button: {
    marginTop: 20,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 240,
    },
  },
  buttonSubmit: {
    fontSize: 12,
    fontWeight: "700",
    height: 48,

    [theme.breakpoints.up("md")]: {
      height: 42,
      width: 160,
    },
  },
  buttonCancel: {
    fontSize: 12,
    fontWeight: "700",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
      height: 42,
      width: 100,
      marginRight: 16,
    },
  },
  buttonUpload: {
    fontSize: 14,
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: 180,
      height: 40,
      fontSize: 14,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapper: {
    minHeight: 200,
  },
  preview: {
    minHeight: 200,
    marginBottom: 24,
    marginTop: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#f2f2f2",
  },
  photo: {
    width: "100%",
  },
  photoContainer: {
    padding: 16,
    position: "relative",
    width: "90%",
  },
  buttonRemove: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#ffffff89",
  },
  buttonLoading: {
    position: "relative",
    left: -8,
  },
}));

const UploadRecipt = ({ open, title, onClose, data }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const [file, setFile] = useState("");
  const [photo, setPhoto] = useState("");
  const [loading, setLoading] = useState(false);

  const onPressCancel = () => {
    onClose();
  };

  const onFileUpload = (event) => {
    const files = event.target.files;

    setFile(files[0]);
    setPhoto(URL.createObjectURL(files[0]));
  };

  const onPressRemove = () => {
    setPhoto("");
    setFile("");
  };

  const onSubmit = () => {
    if (!file) {
      return;
    }
    setLoading(true);

    var formData = new FormData();
    formData.append("receipt_photo", file);

    buildFormData(formData, data);

    dispatch(createTransaction(formData));
  };

  const buildFormData = (formData, data, parentKey) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  };

  const popup = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={!popup}
      className={classes.root}
    >
      <DialogTitle className={classes.header}>
        <Header title={title} onPressButtonBack={onPressCancel} />
      </DialogTitle>

      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>

      <DialogContent>
        <Typography className={classes.message} variant="body2" gutterBottom>
          {t("payment.uploadReceiptMessage")}
        </Typography>
        <Box className={classes.wrapper}>
          <div className={classes.preview}>
            {photo && (
              <Box className={classes.photoContainer}>
                <img src={photo} className={classes.photo}></img>
                <IconButton
                  color="primary"
                  aria-label="remove photo"
                  className={classes.buttonRemove}
                  size="small"
                  onClick={onPressRemove}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            {photo == "" && (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                size={"large"}
                disableElevation
                className={classes.buttonUpload}
                component="label"
              >
                {t("payment.selectPhoto")}
                <input
                  type="file"
                  hidden
                  onChange={onFileUpload}
                  accept="image/*"
                  multiple={false}
                />
              </Button>
            )}
          </div>
        </Box>

        <div className={classes.footer}>
          <Button
            size={"medium"}
            disableElevation
            className={classes.buttonCancel}
            onClick={onPressCancel}
          >
            {t("form.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"medium"}
            disableElevation
            className={classes.buttonSubmit}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading && (
              <CircularProgress className={classes.buttonLoading} size={14} />
            )}
            {t("form.submit")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadRecipt;
