import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Typography,
  Button,
  Container,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Header from "../../components/Header";
import Otp from "../../services/otp";
import { setVerificationPhone } from "../../store/actions/otpActions";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginTop: 48,
  },
  input: {
    minWidth: "100%",
    marginTop: 28,

    [theme.breakpoints.up("sm")]: {
      marginTop: 18,
    },

    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#ffffff",
    },
  },
  button: {
    marginTop: 48,
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      maxWidth: 240,
      margin: theme.spacing(0, "auto"),
      marginTop: 40,
      fontWeight: 700,
    },
  },

  buttonLoading: {
    position: "relative",
    left: -8,
  },
}));

const UpdatePhoneNumber = () => {
  const classes = useStyles();
  const histroy = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { register, handleSubmit, control, watch, errors } = useForm();

  const breadcrumbs = [
    {
      title: t("common.myProfile"),
      route: "/profile",
    },
    {
      title: t("registration.updateMyPhoneNumber"),
    },
  ];

  const onPressButtonBack = () => {
    histroy.push("/profile");
  };

  const onPressSubmit = (data) => {
    setLoading(true);
    Otp.generateOtpCode(data).then((response) => {
      setLoading(false);

      dispatch(setVerificationPhone(data.phone));
    });
  };

  return (
    <>
      <Header
        title={t("registration.updateMyPhoneNumber")}
        onPressButtonBack={onPressButtonBack}
        breadcrumbs={breadcrumbs}
      />

      <Container component="main" maxWidth="xs">
        <Typography className={classes.title} variant="body2" gutterBottom>
          {t("profiles.enterYourNewPhoneNumber")}
        </Typography>

        <form
          onSubmit={handleSubmit(onPressSubmit)}
          noValidate
          autoComplete="off"
        >
          <FormControl className={classes.input}>
            <TextField
              id="phone"
              name="phone"
              inputRef={register({
                required: "Required",
                pattern: {
                  value: /^[0-9]{9,10}$/i,
                  message: t("profiles.invalidPhoneNumber"),
                },
              })}
              variant="outlined"
              error={!!errors.phone?.message}
              helperText={errors.phone?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PhoneAndroidIcon></PhoneAndroidIcon>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            size={"large"}
            className={classes.button}
            disabled={loading}
            disableElevation
          >
            {loading && (
              <CircularProgress className={classes.buttonLoading} size={14} />
            )}
            {t("actions.next")}
          </Button>
        </form>
      </Container>
    </>
  );
};

export default UpdatePhoneNumber;
