import { useEffect } from "react";
import { useSelector } from "react-redux";

import Tracker from "../services/tracker";

export const useTracking = (page) => {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      Tracker.track(page);
    }
  }, []);
};
