import {
  SEARCH_EMPLOYEE_REQUEST,
  SEARCH_EMPLOYEE_SUCCESS,
  SEARCH_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";
import history from "../../utils/history";

export const fetchEmployees = () => async (dispatch) => {
  dispatch({
    type: FETCH_EMPLOYEES_REQUEST,
  });

  try {
    const res = await axios.get(`${config.API_BASE_URL}/employments`);

    dispatch({
      type: FETCH_EMPLOYEES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_EMPLOYEES_FAILURE,
      payload: err.response,
    });
  }
};

export const searchEmployee = (data) => async (dispatch) => {
  dispatch({
    type: SEARCH_EMPLOYEE_REQUEST,
  });

  try {
    const res = await axios.post(
      `${config.API_BASE_URL}/employees/search`,
      data
    );

    dispatch({
      type: SEARCH_EMPLOYEE_SUCCESS,
      payload: res.data.data,
    });

    history.push("/profile/employee-preview");
  } catch (err) {
    dispatch({
      type: SEARCH_EMPLOYEE_FAILURE,
      payload: err.response,
    });
  }
};

export const createEmployee = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_EMPLOYEE_REQUEST,
  });

  try {
    const res = await axios.post(`${config.API_BASE_URL}/employments`, data);

    dispatch({
      type: CREATE_EMPLOYEE_SUCCESS,
      payload: res.data.data,
    });

    history.push("/profile?tab=employees");
  } catch (err) {
    dispatch({
      type: CREATE_EMPLOYEE_FAILURE,
      payload: err.response,
    });
  }
};

export const deleteEmployee = (data) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${config.API_BASE_URL}/employments/${data.id}`,
      data
    );

    dispatch({
      type: DELETE_EMPLOYEE_SUCCESS,
      payload: data,
    });
  } catch (e) {}
};
