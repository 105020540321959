import {
  FETCH_ASSOCIATIONS_REQUEST,
  FETCH_ASSOCIATIONS_SUCCESS,
  FETCH_ASSOCIATIONS_FAILURE,
} from "../types";

const initialState = {
  associations: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSOCIATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_ASSOCIATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
