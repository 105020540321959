import {
  FETCH_COMMUNES_REQUEST,
  FETCH_COMMUNES_SUCCESS,
  FETCH_COMMUNES_FAILURE,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchCommunes = (districtId) => async (dispatch) => {
  dispatch({
    type: FETCH_COMMUNES_REQUEST,
  });

  try {
    const res = await axios.get(
      `${config.API_BASE_URL}/communes?district_id=${districtId}`
    );

    dispatch({
      type: FETCH_COMMUNES_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_COMMUNES_FAILURE,
      payload: err,
    });
  }
};

export const resetCommunes = () => async (dispatch) => {
  dispatch({
    type: FETCH_COMMUNES_SUCCESS,
    payload: [],
  });
};
