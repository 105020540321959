import React, { useState, useEffect } from "react";
import { Typography, TextField, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import Otp from "../services/otp";
import Loading from "./Loading";

const MINUTES = 5;

const useStyles = makeStyles((theme) => ({
  otps: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
  },
  formControl: {
    margin: 4,
    "& input": {
      width: 22,
      textAlign: "center",
      fontSize: 20,
    },
  },

  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginTop: 48,
  },

  message: {
    fontSize: 14,
    textAlign: "center",
    color: theme.palette.primary.main,
  },

  wrap: {
    display: "inline-block",
    position: "relative",
    display: "flex",
    color: theme.palette.primary.main,
  },
  display: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    width: 38,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 22,
    position: "relative",
    margin: 6,
    backgroundColor: "#fff",
  },
  input: {
    backgroundColor: "transparent",
    outline: "none",
    zIndex: 1,
    width: 38,
    top: 6,
    bottom: 6,
    left: 6,
    position: "absolute",
    marginTop: 0,

    "& .MuiInput-root": {
      height: "100%",
      color: theme.palette.primary.main,
    },
    "& input": {
      width: 38,
      height: "100%",
      top: 0,
      bottom: 0,
      left: 0,
      position: "absolute",
      textAlign: "center",
      padding: 0,
      fontSize: 20,
      backgroundColor: "transparent !important",
    },
    "& .MuiInput-underline": {
      "&::before": {
        borderBottomWidth: "0 !important",
      },
      "&::after": {
        borderBottomWidth: "0 !important",
      },
    },
  },
  focused: { borderWidth: 2 },
  resend: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  timing: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ramainingTimeBar: {
    width: 200,
    marginBottom: 6,
  },
  error: {
    color: "#f44336",
    textAlign: "center",
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 36,
  },
  noTimeRemaining: {
    color: "red",
  },
  loadingContainer: { paddingTop: 36, minHeight: 68 },
  loading: { paddingTop: 0 },
}));

const OTPVerification = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const CODE_LENGTH = new Array(4).fill(0);

  const [counter, setCounter] = useState(props.counter ? props.counter : 0);

  // const [phone, setPhone] = useState(props.phone ? props.phone : "");
  const phone = props.phone;
  const verifyOnly = props.verifyOnly;
  const input = React.createRef();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [focused, setFocused] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const values = code.split("");
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    let data = {
      phone: phone,
      code: code,
    };

    setLoading(true);

    if (verifyOnly == true) {
      Otp.verifyOtpCode(data).then((response) => {
        if (response.data.success) {
          props.onSuccess(data);
        } else {
          setError("sorryIncorrectCode");
        }

        setLoading(false);
      });
    } else {
      Otp.verifyOtpCodeAndLogin(data).then((response) => {
        if (response.data.success) {
          props.onSuccess(response);
        } else {
          setError("sorryIncorrectCode");
        }

        setLoading(false);
      });
    }
  };

  const handleClick = () => {
    input.current.focus();
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  const onClickResend = () => {
    let data = {
      phone: phone,
      code: code,
    };

    Otp.generateOtpCode(data)
      .then((response) => {
        // Reset  remaining time
        setCounter(0);
        setCode("");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (code.length >= CODE_LENGTH.length) return null;

    setCode((code + value).slice(0, CODE_LENGTH.length));
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      setCode(code.slice(0, code.length - 1));
    }
  };

  const normalise = (value) => 100 - (value * 100) / (MINUTES * 60);

  const renderPhoneNumberWithCountryCode = () => {
    return `+855 ${phone}`;
  };

  const reaminingTime = (counter) => {
    var duration = MINUTES * 60 - counter;
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    var ret = "";

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  useEffect(() => {
    let selectedIndex =
      values.length < CODE_LENGTH.length
        ? values.length
        : CODE_LENGTH.length - 1;

    setCurrent(selectedIndex);

    setError("");

    if (code.length == CODE_LENGTH.length) {
      setHideInput(true);
      handleSubmit();
    } else {
      setHideInput(false);
    }
  }, [code]);

  useEffect(() => {
    const timer =
      counter < MINUTES * 60 &&
      setInterval(() => setCounter(counter + 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <Typography className={classes.title} variant="body2" gutterBottom>
        {t("otp.enterSmsCodeVerification")}
      </Typography>
      <Typography className={classes.message} variant="body2" gutterBottom>
        {t("otp.weHaveSentYouAnSmsWithTheCode")} <br /> {t("otp.to")}{" "}
        {renderPhoneNumberWithCountryCode()}
      </Typography>
      <form noValidate autoComplete="off">
        <div className={classes.otps}>
          <div className={classes.wrap} onClick={handleClick}>
            <TextField
              value=""
              autoFocus
              inputRef={input}
              onKeyUp={handleKeyUp}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={classes.input}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{
                style: {
                  left: `${current * (38 + 12)}px`,
                  opacity: hideInput ? 0 : 1,
                },
              }}
            />

            {CODE_LENGTH.map((v, index) => (
              <div
                className={clsx(classes.display, {
                  [classes.focused]: current == index,
                })}
                key={index}
              >
                {values[index]}
              </div>
            ))}
          </div>
        </div>
      </form>
      <div className={classes.error}>{error ? t(`otp.${error}`) : ""}</div>
      <Typography className={classes.message} variant="body2" gutterBottom>
        {t("otp.notReceivedCode")}
        <a className={classes.resend} onClick={onClickResend}>
          {t("otp.resend")}
        </a>
      </Typography>

      <div className={classes.loadingContainer}>
        {loading && <Loading styles={classes.loading} />}
      </div>

      <div className={classes.timing}>
        <LinearProgress
          className={classes.ramainingTimeBar}
          variant="determinate"
          value={normalise(counter)}
        />
        <Typography
          className={
            reaminingTime(counter) != "0:00"
              ? classes.message
              : classes.noTimeRemaining
          }
          variant="body2"
          gutterBottom
        >
          {t("otp.remainingTime")}: {reaminingTime(counter)}
        </Typography>
      </div>
    </>
  );
};

export default OTPVerification;
