import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

import EmployerPaymentHistory from "./EmployerPaymentHistory";
import TuktukDriverPaymentHistory from "./TuktukDriverPaymentHistory";
import PrintPayment from "./PrintPayment";
import PaymentHistoryListHeader from "./PaymentHistoryListHeader";

import { getMonthRangeName } from "../../utils/date";
import { getTotalContribution } from "../../utils/payment";
import { UserRoles } from "../../constants";

const Accordion = withStyles({
  root: {
    border: "1px solid #2699FB",
    boxShadow: "none",
    marginBottom: 8,
    "&:not(:last-child)": {},
    "&:last-child": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      marginBottom: 8,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "white",
    borderBottom: "1px solid #2699FB",
    marginBottom: -1,
    minHeight: 56,
    boxShadow: "none",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  moreIcon: { color: theme.palette.primary.main },
  contribution: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: "solid",
  },
  title: { fontSize: 15, fontWeight: 700, color: theme.palette.primary.main },
  list: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing("16px", 0),
    },
  },
}));

const EmployerContributionAmount = (props) => {
  const { total } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={clsx(classes.contribution, classes.borderTop)}>
        <Typography className={classes.title}>
          {t("contribution.totalContributionCompleted")}
        </Typography>
        <Typography className={classes.title}>{total}</Typography>
      </div>
    </>
  );
};

const ContributionHistoryList = (props) => {
  const classes = useStyles();

  const { contributionGrouped, userRole } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getHeaderName = (startAt, endAt) => {
    return getMonthRangeName(startAt, endAt);
  };

  return (
    <>
      {contributionGrouped.map((item, index) => (
        <Accordion
          expanded={expanded === item.endAt}
          onChange={handleChange(item.endAt)}
          key={index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.moreIcon} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              {getHeaderName(item.startAt, item.endAt)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {userRole === UserRoles.EMPLOYER && (
              <>
                <div className={classes.list}>
                  <PaymentHistoryListHeader />
                  {item.payments.map((payment, index) => (
                    <EmployerPaymentHistory
                      payment={payment}
                      number={index}
                      key={index}
                    />
                  ))}
                </div>
                <EmployerContributionAmount
                  total={getTotalContribution(userRole, item.payments)}
                />
                <PrintPayment contribution={item} userRole={userRole} />
              </>
            )}

            {userRole === UserRoles.TUKTUK_DRIVER && (
              <>
                {item.payments.map((payment, index) => (
                  <TuktukDriverPaymentHistory
                    payment={payment}
                    isLast={index === item.payments.length - 1}
                    key={index}
                  />
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ContributionHistoryList;
