import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

import { requestCode } from "../../store/actions/authActions";
import { Typography, Button, Container, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import PhoneIcon from "@material-ui/icons/Phone";

import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginTop: 10,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    textAlign: "center",
    marginBottom: 25,
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: 0,
      marginBottom: 10,
      fontSize: 14,
      fontWeight: "normal",
    },
  },
  errors: {
    margin: 0,
  },
  buttonLoading: {
    position: "relative",
    left: -8,
  },
}));

const LoginByPhone = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const error = useSelector((state) => state.auth.error);
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();

  if (user) {
    return <Redirect to="/" />;
  }

  const onPressButtonBack = () => {
    history.goBack();
  };

  const isValidPhoneNumber = (phone) => {
    var regexp = /^\d{9,10}$/;
    if (phone.match(regexp)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (e) => {
    setSubmitted(true);
    setLoading(true);
    e.preventDefault();
    dispatch(requestCode({ phone: phone }, params.role.trim()));
  };

  const breadcrumbs = [
    { title: t("register.signIn"), route: "/register/:role" },
  ];

  return (
    <>
      <Header
        title={t("login_phone.title")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography variant="body2" className={classes.subtitle}>
            {t("login_phone.subtitle")}
          </Typography>

          <form onSubmit={onSubmit} className={classes.form} noValidate>
            {error && error.errors && error.errors.length > 0 && submitted && (
              <Alert severity="error">
                <ul className={classes.errors}>
                  {error.errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            <TextField
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              placeholder="Ex: 091231231"
              variant="outlined"
              fullWidth
              margin="normal"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className={classes.textLabel}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size={"large"}
              className={classes.submit}
              fullWidth
              disableElevation
              disabled={
                !phone || phone === "" || !isValidPhoneNumber(phone) || loading
              }
            >
              {loading && (
                <CircularProgress className={classes.buttonLoading} size={14} />
              )}
              {t("login_phone.btn")}
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default LoginByPhone;
