import React from "react";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    paddingBottom: 4,
  },
  footer: {
    paddingBottom: 12,
    paddingRight: 16,
  },
}));

const Dialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { title, children, open, setOpen } = props;

  return (
    <MuiDialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={classes.title} id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={() => setOpen(false)} color="primary" autoFocus>
          {t("common.ok")}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
export default Dialog;
