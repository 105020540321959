import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_AS_READ_NOTIFICATION,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchNotifications = () => async (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATIONS_REQUEST,
  });

  try {
    const res = await axios.get(`${config.API_BASE_URL}/notifications`);

    dispatch({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_NOTIFICATIONS_FAILURE,
      payload: err,
    });
  }
};

export const markAsReadNotification = (id) => async (dispatch) => {
  try {
    await axios.post(`${config.API_BASE_URL}/notifications/mark_as_read`, {
      id: id,
    });

    dispatch({
      type: MARK_AS_READ_NOTIFICATION,
      payload: { id: id },
    });
  } catch (err) {}
};
