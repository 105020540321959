import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UserRoles } from "../../constants";

import { selectStyles } from "../../theme/sharedStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderTopWidth: 2,
    borderTopColor: "#BCE0FD",
    borderTopStyle: "solid",
    marginTop: 20,
    paddingTop: 10,
  },
  formControl: {
    minWidth: "100%",
  },
}));

const MaritalStatus = ({
  registration,
  register,
  control,
  watch,
  errors,
  setValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const maritalStatus = watch("marital_status");

  const user = useSelector((state) => state.auth.user);

  const maritalStatusOptions = [
    { value: "single", label: t("registration.single") },
    { value: "married", label: t("registration.married") },
  ];

  const isDomesticWorker = () => {
    return user.role === UserRoles.DOMESTIC_WORKER;
  };

  const renderNumberOfChildren = () => {
    if (user.role === UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <TextField
          id="children_no"
          label={t("registration.numberOfChildren")}
          type="number"
          inputProps={{ min: "0", step: "1" }}
          name="children_no"
          inputRef={register}
          defaultValue={registration.children_no}
          variant="outlined"
          className={classes.formControl}
        />
      </Grid>
    );
  };

  useEffect(() => {
    if (maritalStatus?.value == "single") {
      setValue("children_no", 0);
    }
  });

  return (
    <>
      <Grid item xs={12} md={isDomesticWorker() ? 12 : 6}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={!!errors.marital_status?.message}
        >
          <InputLabel id="marital_status">
            {t("registration.maritalStatus")}
          </InputLabel>
          <Controller
            name="marital_status"
            control={control}
            options={maritalStatusOptions}
            as={Select}
            styles={selectStyles(errors.marital_status?.message)}
            defaultValue={maritalStatusOptions.find(
              (option) => option.value == registration.marital_status
            )}
            rules={{ required: "Required" }}
          />
          {!!errors.gender?.message && (
            <FormHelperText error={!!errors.gender?.message}>
              {errors.gender?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      {renderNumberOfChildren()}
    </>
  );
};

export default MaritalStatus;
