import React from "react";
import { useTranslation } from "react-i18next";

import ListItem from "./ListItem";
import { UserRoles } from "../../../constants";

import { getShortDateFormat } from "../../../utils/date";

const BasicInformations = ({ user, registration }) => {
  const { t, i18n } = useTranslation();

  const getAssociationName = () => {
    return i18n.language == "kh"
      ? registration.association.full_name_kh
      : registration.association.full_name_latin;
  };

  const renderAssociation = () => {
    if (user.role === UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <ListItem
        text={t("registration.associationMembership")}
        value={
          registration.association_id
            ? getAssociationName()
            : t("registration.no")
        }
      />
    );
  };

  const renderBeneficiaryNSSFID = () => {
    if (user.role !== UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <ListItem
        text={t("registration.nssfIdNumber")}
        value={registration.beneficiary_nssf_id}
      />
    );
  };

  const renderDateOfBirth = () => {
    if (user.role === UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <ListItem
        text={t("registration.dateOfBirth")}
        value={getShortDateFormat(registration.date_of_birth)}
      ></ListItem>
    );
  };

  const renderPhoneNumber = () => {
    if (user.role === UserRoles.EMPLOYER) {
      return null;
    }

    return (
      <ListItem
        text={t("registration.phoneNumber")}
        value={registration.phone}
      />
    );
  };

  const renderMaritalStatus = () => {
    if (user.role === UserRoles.EMPLOYER) {
      return (
        <ListItem
          text={t("registration.maritalStatus")}
          value={t(`registration.${registration.marital_status}`)}
        />
      );
    }

    return (
      <>
        <ListItem
          text={t("registration.maritalStatus")}
          value={t(`registration.${registration.marital_status}`)}
        />

        <ListItem
          text={t("registration.numberOfChildren")}
          value={registration.children_no}
        />
      </>
    );
  };

  const renderDrivingLicence = () => {
    if (user.role !== UserRoles.TUKTUK_DRIVER) {
      return null;
    }

    return (
      <ListItem
        text={t("registration.drivingLicenseNumber")}
        value={registration.driving_license}
      />
    );
  };

  const getFullNameKH = () => {
    return registration.first_name_kh + " " + registration.last_name_kh;
  };

  const getFullNameLatin = () => {
    return registration.first_name_latin + " " + registration.last_name_latin;
  };

  return (
    <>
      {renderAssociation()}
      {renderBeneficiaryNSSFID()}

      <ListItem text={t("registration.fullNameKH")} value={getFullNameKH()} />
      <ListItem
        text={t("registration.fullNameLATIN")}
        value={getFullNameLatin()}
      />
      <ListItem
        text={t("registration.gender")}
        value={t(`registration.${registration.gender}`)}
      />

      <ListItem
        text={t("registration.nationality")}
        value={registration.nationality}
      />

      {renderDateOfBirth()}

      <ListItem
        text={t("registration.nationalID")}
        value={registration.national_id}
      ></ListItem>

      <ListItem
        text={t("registration.passport")}
        value={registration.passport_no || "―"}
      ></ListItem>

      {renderPhoneNumber()}
      {renderMaritalStatus()}
      {renderDrivingLicence()}
    </>
  );
};

export default BasicInformations;
