import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

const DrivingLicense = ({ registration, register, errors }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={6}>
      <TextField
        id="driving_license"
        label={t("registration.drivingLicense") + " #"}
        name="driving_license"
        inputRef={register({ required: "Required" })}
        defaultValue={registration.driving_license}
        variant="outlined"
        error={!!errors.driving_license?.message}
        helperText={errors.driving_license?.message}
        className={classes.formControl}
      />
    </Grid>
  );
};

export default DrivingLicense;
