import React from "react";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { selectStyles } from "../../theme/sharedStyles";

const NationalitySelect = (props) => {
  const { i18n } = useTranslation();
  const { defaultValue, errors } = props;

  const nationalities = useSelector((state) => state.nationalities.items);

  const nationalityOptions = nationalities
    .filter((nationality) => {
      if (nationality.nat_id != -1) {
        return nationality;
      }
    })
    .map((nationality) => {
      return {
        value: nationality.nat_id,
        label:
          i18n.language == "kh"
            ? nationality.nat_name_kh
            : nationality.nat_name_eng,
        name: nationality.nat_name_eng,
      };
    });

  return (
    <FormControl
      variant="outlined"
      className={props.class}
      error={!!errors.nationality?.message}
    >
      <InputLabel>{props.title}</InputLabel>
      <Controller
        name="nationality"
        control={props.control}
        options={nationalityOptions}
        as={Select}
        styles={selectStyles(errors.nationality?.message)}
        defaultValue={nationalityOptions.find(
          (option) => option.value == defaultValue
        )}
        rules={{ required: "Required" }}
      />
      {!!errors.nationality?.message && (
        <FormHelperText error={!!errors.nationality?.message}>
          {errors.nationality?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default NationalitySelect;
