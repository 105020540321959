import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { UserRoles } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginBottom: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
    "& .MuiListItemText-secondary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  itemTitle: { fontSize: 18, color: theme.palette.primary.main },
  employers: {
    alignItems: "flex-start",
    "& ul": {
      listStyle: "none",
      textAlign: "right",
      marginBottom: 8,
    },
  },
  list: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  summary: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textDecoration: "underline",
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const Employers = ({ user, registration }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (user.role != UserRoles.DOMESTIC_WORKER) {
    return null;
  }

  return (
    <>
      <Typography className={classes.heading} variant="h4">
        {t("common.employers")}
      </Typography>
      <List className={classes.list}>
        {registration.employer_nssf_ids?.map((nssfId, index) => (
          <ListItem className={classes.item} key={index}>
            <ListItemText
              primary={t("common.employer") + " #" + index + 1}
              secondary={t("registration.nssfIdNumber")}
            />
            <ListItemSecondaryAction className={classes.itemTitle}>
              {nssfId}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <ListItem
        className={clsx(classes.item, classes.employers, classes.summary)}
      >
        <ListItemText primary={t("common.employers")} />
        <div>
          {registration.employer_nssf_ids?.map((nssfId, index) => (
            <ul key={index}>
              <li className={classes.itemTitle}>
                {t("common.employer")} #{index + 1}
              </li>
              <li className={classes.itemTitle}>
                {t("registration.nssfId")} #: {nssfId}
              </li>
            </ul>
          ))}
        </div>
      </ListItem>
    </>
  );
};

export default Employers;
