import {
  FETCH_NATIONALITIES_REQUEST,
  FETCH_NATIONALITIES_SUCCESS,
  FETCH_NATIONALITIES_FAILURE,
} from "../types";

const initialState = {
  items: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NATIONALITIES_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      };

    case FETCH_NATIONALITIES_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_NATIONALITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
