import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 32,
  },
  heading: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottomColor: "#BCE0FD",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    paddingBottom: 4,
    marginTop: 24,
  },
  text: {
    fontSize: 12,
    color: "#666666",
    marginBottom: 8,
  },
  buttonWebsite: {
    padding: theme.spacing("11px", "21px"),
    marginTop: 16,
    backgroundColor: "#ffffff",
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },

    [theme.breakpoints.up("sm")]: {
      maxWidth: 308,
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const Info = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <>
      <Typography className={classes.title} variant="h3" gutterBottom>
        {t("informations.QA1Title")}
      </Typography>

      <Typography className={classes.heading} variant="h4" gutterBottom>
        {t("informations.QA2Title")}
      </Typography>

      {language == "en" && (
        <Typography className={classes.text} variant="body2" gutterBottom>
          If you are not yet registered and have never been registered as a NSSF
          member previously in another company/job.
        </Typography>
      )}

      {language == "kh" && (
        <Typography className={classes.text} variant="body2" gutterBottom>
          ប្រសិនបើអ្នកមិនទាន់បានចុះឈ្មោះហើយមិនដែលបានចុះឈ្មោះជាសមាជិកបេឡាជាតិរបបសន្តិសុខសង្គមពីមុននៅក្នុងក្រុមហ៊ុន
          / ការងារផ្សេងទៀត។
        </Typography>
      )}

      <Typography className={classes.heading} variant="h4" gutterBottom>
        {t("informations.QA3Title")}
      </Typography>

      {language == "en" && (
        <>
          <Typography className={classes.text} variant="body2" gutterBottom>
            After completely submit the request through the webapp, the
            requestee is required to visit an NSSF branch (See NSSF branches
            link) to complete the registration.
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            Required documents:
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            1. National ID Card or Passport (for Foreigners)
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            2. Working contract
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            3. Photo and thumbprint
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            Note: If you do not have your national ID card yet, NSSF can help to
            request one through the partnership with MoI (supporting documents:
            request form, family/resident book, photo, thumb print)
          </Typography>
        </>
      )}

      {language == "kh" && (
        <>
          <Typography className={classes.text} variant="body2" gutterBottom>
            បន្ទាប់ពីបញ្ជូនសំណើទាំងស្រុងតាមរយៈ webapp អ្នកស្នើសុំត្រូវទៅសាខា បសស
            (សូមមើលផែនទីភ្ជាប់) ដើម្បីបំពេញការចុះបញ្ចិកា។
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            ឯកសារដែលត្រូវការរួមមាន៖
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            1. អត្តសញ្ញាណប័ណ្ណឬលិខិតឆ្លងដែន (សំរាប់ជនបរទេស)
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            2. កិច្ចសន្យាការងារ
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            3. រូបថតនិងស្នាមមេដៃ
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            សម្គាល់ៈ ប្រសិនបើអ្នកមិនទាន់មានអត្តសញ្ញាណប័ណ្ណនៅឡើយទេ
            បេឡាជាតិសន្តិសុខសង្គមអាចជួយស្នើសុំតាមរយៈភាពជាដៃគូជាមួយក្រសួងមហាផ្ទៃ
            (ឯកសារភ្ជាប់៖ ទម្រង់ស្នើសុំ, សៀវភៅគ្រួសារ / ទីលំនៅ, រូបថត,
            ស្នាមមេដៃ)
          </Typography>
        </>
      )}

      <Typography className={classes.heading} variant="h4" gutterBottom>
        {t("informations.QA4Title")}
      </Typography>

      {language == "en" && (
        <>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - Who: The tuk-tuk driver and the employer of the domestic workers
            are responsible to pay the contributions
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - Frequency: every 3 months, every 6 months or annually (To be
            confirmed!!!)
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - How: Through payment gateways available through the webapp or at
            the branch and then send the receipt through the app
          </Typography>
        </>
      )}

      {language == "kh" && (
        <>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - អ្នករត់រ៉ឺម៉កនិងនិយោជកអ្នកធ្វើការតាមផ្ទះទទួលបន្ទុកបង់
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - រៀងរាល់ ៣ ខែរៀងរាល់ ៦ ខែឬប្រចាំឆ្នាំ (ដើម្បីបញ្ជាក់ !!!)
          </Typography>
          <Typography className={classes.text} variant="body2" gutterBottom>
            - របៀប៖ តាមរយៈ webapp ឬនៅសាខា  បន្ទាប់មកផ្ញើវិក័យប័ត្រតាមរយៈកម្មវិធី
          </Typography>
        </>
      )}

      <Button
        variant="outlined"
        color="primary"
        fullWidth
        size={"large"}
        disableElevation
        className={classes.buttonWebsite}
        startIcon={<LanguageIcon />}
        target="_blank"
        href="http://www.nssf.gov.kh"
      >
        {t("informations.visitNssfWebsite")}
      </Button>
    </>
  );
};

export default Info;
