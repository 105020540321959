import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    paddingBottom: 4,
    marginTop: 24,
    textAlign: "center",
  },
  buttonWebsite: {
    padding: theme.spacing("11px", "21px"),
    marginTop: 16,
    backgroundColor: "#ffffff",
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },

    [theme.breakpoints.up("sm")]: {
      maxWidth: 308,
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const Intro = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <>
      {language == "en" && (
        <Typography className={classes.heading} variant="body2" gutterBottom>
          Welcome to "MyNSSF" the SHI application dedicated to the Tuktuk
          drivers, domestic workers and employers of domestic workers in Phnom
          Penh.
        </Typography>
      )}

      {language == "kh" && (
        <Typography className={classes.heading} variant="body2" gutterBottom>
          សូមស្វាគមន៍មកកាន់ទំព័រចុះបញ្ចិកា "MyNSSF"
          សម្រាប់ការចុះបញ្ចិកាអ្នករត់ម៉ូតូកង់បី និងអ្នកធ្វើការក្នុងផ្ទះ
          ក្នុងរាជធានីភ្នំពេញ
        </Typography>
      )}

      <Button
        variant="outlined"
        color="primary"
        fullWidth
        size={"large"}
        disableElevation
        className={classes.buttonWebsite}
        startIcon={<LanguageIcon />}
        target="_blank"
        href="http://www.nssf.gov.kh"
      >
        {t("informations.visitNssfWebsite")}
      </Button>
    </>
  );
};

export default Intro;
