import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import { ReactComponent as TuktukIcon } from "../assets/icons/tuktuk.svg";
import { ReactComponent as WorkerIcon } from "../assets/icons/worker.svg";
import { ReactComponent as EmployerIcon } from "../assets/icons/employer.svg";
import { ReactComponent as RegisterIcon } from "../assets/icons/account-edit.svg";
import { ReactComponent as AccountIcon } from "../assets/icons/account.svg";
import { ReactComponent as InformationIcon } from "../assets/icons/information.svg";
import { ReactComponent as MoneyIcon } from "../assets/icons/money.svg";
import { ReactComponent as PinIcon } from "../assets/icons/pin.svg";
import { ReactComponent as LeaveIcon } from "../assets/icons/leave.svg";
import { ReactComponent as FileIcon } from "../assets/icons/file.svg";
import NotificationsIcon from "@material-ui/icons/Notifications";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";

import PreRegistrationSuccessPopup from "./PreRegistration/SuccessPopup";
import { RegistrationPageTitle } from "../constants";
import { generateParamsUrl } from "../utils/url";

const useStyles = makeStyles((theme) => ({
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: "#666666",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    marginRight: 12,
  },
  navigation: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: 20,
    },
  },
  page: {
    display: "flex",
    alignItems: "center",
  },
  expandIcon: {
    color: "#666666",
    cursor: "pointer",
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: 8,
      right: 11,
      backgroundColor: "#FF0021",
    },
  },
}));

const NavigationMenu = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [menus, setMenus] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const anchorRef = useRef(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const notifications = useSelector((state) => state.notifications.items);

  const unreadNotifications = notifications.filter(
    (item) => item.read_at == null
  );

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseToggle = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const prevOpen = useRef(openMenu);
  useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  const onClickMenu = (e, menu) => {
    if (
      menu.route === "/pre-registration" &&
      user.pre_registration_status === "submitted"
    ) {
      e.preventDefault();

      setOpenSuccess(true);
    }
  };

  const onCloseSuccessDialog = () => {
    setOpenSuccess(false);
  };

  const isValidated = () => {
    if (user && user.pre_registration_status === "validated") {
      return true;
    }

    return false;
  };

  const renderMenuItem = (menu, index) => {
    if (menu.route === "/pre-registration" && isValidated()) {
      return null;
    }

    if (menu.route === "/notifications") {
      return (
        <Badge
          color="secondary"
          badgeContent={unreadNotifications.length}
          className={classes.badge}
          key={index}
        >
          <Link
            noWrap
            key={menu.title}
            variant="body2"
            to={menu.route}
            component={RouterLink}
            className={classes.toolbarLink}
            onClick={(e) => onClickMenu(e, menu)}
          >
            {menu.title}
          </Link>
        </Badge>
      );
    }

    return (
      <div className={classes.page} key={index}>
        {menu.submenus ? (
          <div className={classes.expandIcon}>
            <Link
              noWrap
              key={menu.title}
              variant="body2"
              className={classes.toolbarLink}
              onClick={handleToggle}
              ref={anchorRef}
            >
              {menu.title}
              <ExpandMoreIcon
                aria-controls={openMenu ? "menu-list-grow" : undefined}
                aria-haspopup="true"
              />
            </Link>

            <div className={classes.dropDown}>
              <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "left top" : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseToggle}>
                        <MenuList
                          autoFocusItem={openMenu}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {menu.submenus.map((submenu, index) => (
                            <Link
                              noWrap
                              key={submenu.title}
                              variant="body2"
                              to={submenu.route}
                              component={RouterLink}
                              className={classes.toolbarLink}
                              style={{ alignContent: "center" }}
                              onClick={handleCloseToggle}
                              key={index}
                            >
                              {submenu.title}
                            </Link>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        ) : (
          <Link
            noWrap
            key={menu.title}
            variant="body2"
            to={menu.route}
            component={RouterLink}
            className={classes.toolbarLink}
            onClick={(e) => onClickMenu(e, menu)}
          >
            {menu.title}
          </Link>
        )}
      </div>
    );
  };

  useEffect(() => {
    let routes = [
      {
        title: t("common.preRegistration"),
        route: "/pre-registration",
        icon: RegisterIcon,
        submenus: [
          {
            title: t("common.tuktukDriver"),
            route: "/login" + generateParamsUrl({ role: "tuktuk_driver" }),
            icon: TuktukIcon,
          },
          {
            title: t("common.domesticWorker"),
            route: "/login" + generateParamsUrl({ role: "domestic_worker" }),
            icon: WorkerIcon,
          },
          {
            title: t("common.employer"),
            route: "/login" + generateParamsUrl({ role: "employer" }),
            icon: EmployerIcon,
          },
        ],
      },
      {
        title: t("common.information"),
        route: "/information",
        icon: InformationIcon,
      },
      {
        title: t("common.workingContractSample"),
        route: "/working-contract",
        icon: FileIcon,
      },
      { title: t("common.contact"), route: "/contact", icon: PinIcon },
    ];

    if (user) {
      routes = [
        {
          title: t("common.myProfile"),
          route: "/profile",
          icon: AccountIcon,
        },
        {
          title: t("common.preRegistration"),
          route: "/pre-registration",
          icon: RegisterIcon,
        },
      ];

      if (user.role !== "domestic_worker") {
        routes.push({
          title: t("common.contributionPayment"),
          route: "/contribution-payment",
          icon: MoneyIcon,
        });
      }

      routes = [
        ...routes,
        {
          title: t("common.information"),
          route: "/information",
          icon: InformationIcon,
        },
        {
          title: t("common.workingContractSample"),
          route: "/working-contract",
          icon: FileIcon,
        },
        { title: t("common.contact"), route: "/contact", icon: PinIcon },
        {
          title: t("common.notifications"),
          route: "/notifications",
          icon: NotificationsIcon,
        },
        { title: t("common.logout"), route: "/logout", icon: LeaveIcon },
      ];
    }

    setMenus(routes);
  }, [user, i18n.language]);

  return (
    <div className={classes.navigation}>
      {menus.map((menu, index) => renderMenuItem(menu, index))}
      {isLoggedIn && (
        <PreRegistrationSuccessPopup
          open={openSuccess}
          title={t(`registration.${RegistrationPageTitle[user.role]}`)}
          onClose={onCloseSuccessDialog}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(NavigationMenu);
