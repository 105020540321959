import {
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILURE,
} from "../types";

const initialState = {
  items: {},
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DISTRICTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_DISTRICTS_SUCCESS:
      return {
        ...state,
        items: { ...state.items, ...action.payload },
        loading: false,
        error: null,
      };

    case FETCH_DISTRICTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
