import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ProvinceSelect from "./ProvinceSelect";
import DistrictSelect from "./DistrictSelect";
import CommuneSelect from "./CommuneSelect";

import { resetCommunes } from "../../store/actions/communeActions";
import { fetchDistricts } from "../../store/actions/districtActions";
import { fetchCommunes } from "../../store/actions/communeActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 24,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      paddingTop: 0,

      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 49,
    },
  },
  formControl: {
    minWidth: "100%",
  },
  title: {
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginBottom: 8,
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: 10,
    },
  },
  spacing: {
    paddingRight: 10,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
      "& .MuiGrid-item": {
        "& .MuiFormControl-root": {
          paddingRight: 0,
        },
      },
    },
  },
}));

const Address = ({
  registration,
  register,
  errors,
  setValue,
  getValues,
  control,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [provinceId, setProvinceId] = useState(registration.province_id);

  const handleProvinceChange = (selectedOption) => {
    dispatch(resetCommunes());
    // reset district
    setValue("district", null, {
      shouldValidate: false,
      shouldDirty: true,
    });
    setValue("commune", null, {
      shouldValidate: false,
      shouldDirty: true,
    });
    if (selectedOption) {
      dispatch(fetchDistricts(selectedOption.value));
      setProvinceId(selectedOption.value);
    }
  };

  const handeDistrictChange = (selectedOption) => {
    // reset commune
    setValue("commune", null, {
      shouldValidate: false,
      shouldDirty: true,
    });

    if (selectedOption) {
      dispatch(fetchCommunes(selectedOption.value));
    }
  };

  return (
    <div className={classes.paper}>
      <Typography className={classes.title}>
        {t("registration.address")}
      </Typography>
      <Grid item xs={12}>
        <ProvinceSelect
          name="province"
          class={classes.formControl}
          title={t("locations.province")}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          defaultValue={registration.province_id}
          onChange={handleProvinceChange}
        />
      </Grid>

      <Grid item xs={12}>
        <DistrictSelect
          name="district"
          title={t("locations.district")}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          class={classes.formControl}
          defaultValue={registration.district_id}
          provinceId={provinceId}
          onChange={handeDistrictChange}
        />
      </Grid>

      <Grid item xs={12}>
        <CommuneSelect
          title={t("locations.commune")}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          class={classes.formControl}
          defaultValue={registration.commune_id}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="village"
          label={t("locations.village")}
          name="village"
          inputRef={register({ required: "Required" })}
          defaultValue={registration.village}
          variant="outlined"
          error={!!errors.village?.message}
          helperText={errors.village?.message}
          className={classes.formControl}
        />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={6} md={12} className={classes.spacing}>
          <TextField
            id="house_no"
            label={t("locations.houseNoOptional")}
            name="house_no"
            inputRef={register}
            defaultValue={registration.house_no}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={6} md={12}>
          <TextField
            id="street"
            label={t("locations.streetOptional")}
            name="street"
            inputRef={register}
            defaultValue={registration.street}
            variant="outlined"
            className={classes.formControl}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Address;
