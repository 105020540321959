import axios from "axios";

axios.interceptors.request.use(function (config) {
  delete config.headers.Authorization;

  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

export { axios }
