import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

import { deleteEmployee } from "../../store/actions/employeesAction";

import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    position: "relative",

    padding: theme.spacing(0, "10px"),
    paddingBottom: 10,
    paddingTop: 12,
    marginTop: 12,

    [theme.breakpoints.up("md")]: {
      borderWidth: 0,
      padding: theme.spacing(0, "16px"),
      paddingTop: 10,
      marginTop: 0,
    },
  },
  heading: {
    fontSize: 16,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  gender: {
    textTransform: "capitalize",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",

    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    paddingTop: 8,
    paddingBottom: 4,

    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
      paddingBottom: 8,
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  buttonRemove: {
    position: "absolute",
    top: 4,
    right: 4,

    [theme.breakpoints.up("md")]: {
      position: "relative",
      backgroundColor: "#e6e6e6",
      width: 32,
      height: 32,
    },
  },
  iconRemove: {
    fontSize: "1.5rem",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  itemLabel: {
    fontSize: 11,
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  itemTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    paddingBottom: 4,
  },
  buttonCol: {
    justifyContent: "center",
    display: "flex",

    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingBottom: 10,
      paddingTop: 4,
      borderBottomWidth: 2,
      borderBottomColor: "#BCE0FD",
      borderBottomStyle: "solid",
    },
  },
  number: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

const Employee = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { employee, number } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDeleteEmployee = () => {
    dispatch(deleteEmployee(employee));
  };

  const handlePressDelete = () => {
    setConfirmOpen(true);
  };

  return (
    <>
      <Grid container className={classes.root} spacing={1}>
        <Typography className={classes.heading}>
          {t("common.employee")} {number}
        </Typography>
        <Grid item xs={12} md={2} lg={1} className={classes.number}>
          <div className={classes.item}>
            <Typography className={classes.itemTitle}>{number}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.item}>
            <Typography className={classes.itemLabel}>
              {t("registration.fullNameKH")}
            </Typography>
            <Typography className={classes.itemTitle}>
              {employee.employee_name_kh}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.item}>
            <Typography className={classes.itemLabel}>
              {t("registration.fullNameLATIN")}
            </Typography>
            <Typography className={classes.itemTitle}>
              {employee.employee_name_latin}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={1}>
          <div className={classes.item}>
            <Typography className={classes.itemLabel}>
              {t("registration.gender")}
            </Typography>
            <Typography className={classes.itemTitle}>
              {t(`registration.${employee.employee_gender}`)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={2} lg={3}>
          <div className={classes.item}>
            <Typography className={classes.itemLabel}>
              {t("registration.nssfId")}
            </Typography>
            <Typography className={classes.itemTitle}>
              {employee.employee_nssf_id}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={1}>
          <div className={classes.buttonCol}>
            <IconButton
              color="primary"
              aria-label="remove employer"
              className={classes.buttonRemove}
              size="small"
              onClick={handlePressDelete}
            >
              <CloseIcon className={classes.iconRemove} />
            </IconButton>
          </div>
        </Grid>
      </Grid>

      <ConfirmDialog
        title="Remove Employee?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteEmployee}
      >
        Are you sure you want to remove this employee?
      </ConfirmDialog>
    </>
  );
};
export default Employee;
