import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "none",
    color: theme.palette.primary.main,
    padding: theme.spacing(0, "16px"),
    paddingRight: 0,

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  title: {
    fontSize: 13,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    paddingBottom: 6,
    width: "100%",
  },
}));

const PaymentHistoryListHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.row} spacing={1}>
      <Grid item container spacing={1} xs={12} md={5}>
        <Grid item xs={1}>
          <Typography variant={"h4"} className={classes.title}>
            {t("common.no")}#
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h4"} className={classes.title}>
            {t("registration.fullNameKH")}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={"h4"} className={classes.title}>
            {t("registration.fullNameLATIN")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"h4"} className={classes.title}>
            {t("registration.gender")}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Typography variant={"h4"} className={classes.title}>
          {t("registration.nssfIdNumber")}
        </Typography>
      </Grid>
      <Grid item container spacing={1} xs={5}>
        <Grid item xs={12} md={3}>
          <Typography variant={"h4"} className={classes.title}>
            {t("contribution.salary")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant={"h4"} className={classes.title}>
            {t("common.contribution")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant={"h4"} className={classes.title}>
            {t("common.paymentMethod")}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant={"h4"} className={classes.title}>
            {t("payment.status")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant={"h4"} className={classes.title}>
            {t("payment.receipt")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PaymentHistoryListHeader;
