import React, { useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ProvinceSelect from "./ProvinceSelect";
import DistrictSelect from "./DistrictSelect";

import { fetchDistricts } from "../../store/actions/districtActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 24,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  formControl: {
    minWidth: "100%",
  },
  title: {
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginBottom: 8,
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    paddingBottom: 3,

    [theme.breakpoints.up("md")]: {
      borderBottom: "none",
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: 16,
      paddingBottom: 0,
    },
  },
  spacing: {
    paddingRight: 10,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
      "& .MuiGrid-item": {
        "& .MuiFormControl-root": {
          paddingRight: 0,
        },
      },
    },
  },
}));

const PlaceOfBirth = ({
  registration,
  register,
  errors,
  setValue,
  getValues,
  control,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [provinceId, setProvinceId] = useState(registration.pob_province_id);

  const handleProvinceChange = (selectedOption) => {
    // reset district
    setValue("pob_district", null, {
      shouldValidate: false,
      shouldDirty: true,
    });
    if (selectedOption) {
      dispatch(fetchDistricts(selectedOption.value));
      setProvinceId(selectedOption.value);
    }
  };

  const handleDistrictChange = (selectedOption) => {};

  return (
    <div className={classes.paper}>
      <Typography className={classes.title} variant="h3">
        {t("registration.placeOfBirth")}
      </Typography>

      <Grid item xs={12}>
        <ProvinceSelect
          name="pob_province"
          class={classes.formControl}
          title={t("locations.province")}
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          defaultValue={registration.pob_province_id}
          onChange={handleProvinceChange}
        />
      </Grid>

      <Grid item xs={12}>
        <DistrictSelect
          name="pob_district"
          control={control}
          title={t("locations.district")}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          class={classes.formControl}
          defaultValue={registration.pob_district_id}
          onChange={handleDistrictChange}
          provinceId={provinceId}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="pob_village"
          name="pob_village"
          label={t("locations.village")}
          inputRef={register({ required: "Required" })}
          defaultValue={registration.pob_village}
          variant="outlined"
          error={!!errors.pod_village?.message}
          helperText={errors.pod_village?.message}
          className={classes.formControl}
        />
      </Grid>
    </div>
  );
};

export default PlaceOfBirth;
