import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Translation } from "react-i18next";
import { getTotalContribution } from "../../utils/payment";
import { format } from "../../utils/currency";
import { UserRoles, TransactionStatuses } from "../../constants";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  container: {
    display: "flex",
    flex: 1,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    flexDirection: "column",
  },
  footer: { display: "flex", flexDirection: "column" },
  logo: {
    fontSize: 28,
    fontWeight: "bold",
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
  },

  contact: { fontSize: 18 },
  columnHeader: {
    fontSize: 14,
    color: "#444",
    borderBottomWidth: 2,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
    width: "100%",
    paddingBottom: 6,
    paddingTop: 10,
  },
  row: {
    marginBottom: 4,
  },
  column: {
    fontSize: 16,
    color: "#444",
    borderBottomWidth: 2,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
    width: "100%",
    paddingTop: 16,
    height: "100%",
  },
  rowFooter: { paddingTop: 12 },
  footerRowLabel: {
    textAlign: "right",
  },
  footerLabel: {
    marginRight: 10,
  },
});

export class PaymentReceipt extends React.PureComponent {
  renderPayment(payment, index) {
    const { userRole, classes } = this.props;

    if (
      userRole === UserRoles.EMPLOYER &&
      payment.status !== TransactionStatuses.COMPLETED
    ) {
      return null;
    }

    return (
      <Grid container className={classes.row} spacing={1} key={index}>
        <Grid item xs={1}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {index + 1}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {payment.contributor_name_kh}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {payment.contributor_name_latin}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Translation>
            {(t, { i18n }) => (
              <Typography className={classes.column} variant={"subtitle1"}>
                {t(`registration.${payment.contributor_gender}`)}
              </Typography>
            )}
          </Translation>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {payment.contributor_nssf_id || "―"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {userRole == "employer"
              ? format(payment.salary)
              : payment.frequency + " months"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.column} variant={"subtitle1"}>
            {format(payment.contribution)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { contribution, userRole, classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.logo}>NSSF</div>
          <div className={classes.title}>CONTRIBUTION DETAILS</div>
        </div>

        <div className={classes.container}>
          <Grid container className={classes.row} spacing={1}>
            <Grid item xs={1}>
              <Typography
                className={classes.columnHeader}
                variant={"subtitle1"}
              >
                No #
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {t("registration.fullNameKH")}
                  </Typography>
                )}
              </Translation>
            </Grid>
            <Grid item xs={2}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {t("registration.fullNameLATIN")}
                  </Typography>
                )}
              </Translation>
            </Grid>
            <Grid item xs={1}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {t("registration.gender")}
                  </Typography>
                )}
              </Translation>
            </Grid>
            <Grid item xs={2}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {t("registration.nssfIdNumber")}
                  </Typography>
                )}
              </Translation>
            </Grid>

            <Grid item xs={2}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {userRole === UserRoles.EMPLOYER
                      ? t("contribution.salary")
                      : t("contribution.frequency")}
                  </Typography>
                )}
              </Translation>
            </Grid>
            <Grid item xs={2}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    className={classes.columnHeader}
                    variant={"subtitle1"}
                  >
                    {t("common.contribution")}
                  </Typography>
                )}
              </Translation>
            </Grid>
          </Grid>
          {contribution.payments.map((payment, index) =>
            this.renderPayment(payment, index)
          )}
          <Grid container className={classes.rowFooter} spacing={1}>
            <Grid item xs={10} className={classes.footerRowLabel}>
              <Translation>
                {(t, { i18n }) => (
                  <Typography
                    variant={"subtitle1"}
                    className={classes.footerLabel}
                  >
                    {t("contribution.totalContributionAmount")}
                  </Typography>
                )}
              </Translation>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={"subtitle1"}>
                {getTotalContribution(userRole, contribution.payments)}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.footer}>
          <div className={classes.contact}>
            NSSF | +85592123123 | nssf.gov.kh
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PaymentReceipt);
