import React, { useEffect } from "react";
import {
  Typography,
  Button,
  Container,
  Grid,
  makeStyles,
  List,
  Box,
  Avatar,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import Header from "../../components/Header";
import ListItem from "../../components/Profile/ListItem";
import DuplicateEmployee from "../../components/Profile/DuplicateEmployee";

import { createEmployee } from "../../store/actions/employeesAction";
import { getShortDateFormat } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  box: {
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,

    [theme.breakpoints.up("sm")]: {
      borderWidth: 0,
    },
  },
  header: {
    minHeight: 125,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      paddingTop: 24,
    },
  },
  headerInfo: {
    padding: 16,
    paddingTop: 8,
    textAlign: "center",
    color: "#fff",
    display: "none",

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  content: {
    padding: 16,
    paddingTop: 8,
    textAlign: "center",
    color: theme.palette.primary.main,

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    textAlign: "center",
    marginTop: 28,
    marginBottom: 24,
    textDecoration: "underline",

    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
      fontWeight: 700,
      textDecoration: "none",
    },
  },
  input: {
    minWidth: "100%",
    marginTop: 28,
  },
  avatar: { width: 76, height: 76 },
  name: {
    fontSize: 21,
    fontWeight: "700",
    marginTop: 8,
  },
  gender: {
    fontSize: 14,
    textTransform: "capitalize",
    marginTop: 4,
  },
  subtitle: {
    fontSize: 14,
    marginTop: 4,
  },
  buttons: { marginTop: 32 },
  button: { fontSize: 12 },

  employeeInfoList: {
    display: "none",
    padding: theme.spacing("16px", "16px"),
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  list: {
    padding: 0,
  },
  listLeft: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 16,
      borderRightWidth: 2,
      borderRightColor: "#BCE0FD",
      borderRightStyle: "solid",
    },
  },
  listRight: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
    },
  },
}));

const EmployeePreview = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  const error = useSelector((state) => state.employees.error);
  const employee = useSelector((state) => state.employees.item);

  const breadcrumbs = [
    {
      title: t("common.myProfile"),
      route: "/profile?tab=employees",
    },
    {
      title: t("registration.addEmployee"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/profile?tab=employees");
  };

  const onPressOK = () => {
    const data = {
      employee_id: employee.id,
    };
    dispatch(createEmployee(data));
  };

  if (!employee) {
    history.push("/profile/add-employee");
  }

  const handleDisplayDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (error) {
      handleDisplayDialog();
    }
  }, [error]);

  return (
    <>
      <Header
        title={t("registration.addEmployee")}
        onPressButtonBack={onPressButtonBack}
        breadcrumbs={breadcrumbs}
      />

      <Container component="main">
        <Typography className={classes.title} variant="body2" gutterBottom>
          {t("profiles.employeeInformation")}
        </Typography>

        <div className={classes.box}>
          <div className={classes.header}>
            <Avatar className={classes.avatar}></Avatar>

            <Box className={classes.headerInfo}>
              <Typography className={classes.name}>
                {employee?.full_name_latin}
              </Typography>
              <Typography className={classes.subtitle}>
                <span className={classes.label}>
                  {t("registration.nssfId")}:{" "}
                </span>
                <span>{employee?.nssf_id || "―"}</span>
              </Typography>
            </Box>
          </div>
          <div className={classes.content}>
            <Typography className={classes.name}>
              {employee?.full_name_latin}
            </Typography>
            <Typography className={classes.gender}>
              {t(`registration.${employee?.gender}`)}
            </Typography>
            <Typography className={classes.subtitle}>
              <span className={classes.label}>
                {t("registration.nssfId")}:{" "}
              </span>
              <span>{employee?.nssf_id || "―"}</span>
            </Typography>
          </div>

          <Grid container className={classes.employeeInfoList}>
            <Grid item xs={12} sm={6}>
              <List className={clsx(classes.list, classes.listLeft)}>
                <ListItem
                  text={t("registration.fullNameKH")}
                  value={employee?.full_name_kh}
                />
                <ListItem
                  text={t("registration.fullNameLATIN")}
                  value={employee?.full_name_latin}
                />
              </List>
            </Grid>

            <Grid item xs={12} sm={6}>
              <List className={clsx(classes.list, classes.listRight)}>
                <ListItem
                  text={t("registration.gender")}
                  value={t(`registration.${employee?.gender}`)}
                />
                <ListItem
                  text={t("registration.dateOfBirth")}
                  value={getShortDateFormat(employee?.date_of_birth)}
                />
              </List>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} className={classes.buttons}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size={"large"}
              disableElevation
              onClick={onPressButtonBack}
              className={classes.button}
            >
              {t("form.cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              size={"large"}
              onClick={onPressOK}
              disableElevation
              className={classes.button}
            >
              {t("common.ok")}
            </Button>
          </Grid>
        </Grid>
      </Container>

      <DuplicateEmployee open={openDialog} onClose={handleCloseDialog} />
    </>
  );
};

export default EmployeePreview;
