import React from "react";
import { useTranslation } from "react-i18next";

import { UserRoles } from "../../../constants";
import ListItem from "./ListItem";

const Contacts = ({ user, registration }) => {
  const { t } = useTranslation();

  if (user.role != UserRoles.EMPLOYER) {
    return null;
  }

  return (
    <>
      <ListItem
        text={t("registration.phoneNumber")}
        value={registration.phone}
      />
      <ListItem text={t("registration.fax")} value={registration.fax} />
      <ListItem text={t("registration.email")} value={registration.email} />
    </>
  );
};

export default Contacts;
