import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Container, Backdrop, CircularProgress } from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import Header from "../../components/Header";

import TransactionService from "../../services/transaction";
import { resetTransaction } from "../../store/actions/contributionPaymentActions";

import {
  UserRoles,
  PaymentMethods,
  TransactionStatuses,
  SUPPORTED_PAYMENT_GATEWAYS,
} from "../../constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  title: { textAlign: "center" },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  error: {
    fontSize: 16,
    textAlign: "center",
  },
}));

const PaymentCallback = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const query = useQuery();
  const [checking, setChecking] = useState(true);

  const user = useSelector((state) => state.auth.user);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/contribution-payment",
    },
    {
      title: t("common.paymentProcessing"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/contribution-payment");
  };

  useEffect(() => {
    dispatch(resetTransaction());

    const paymentMethod = params.payment_method;

    if (!SUPPORTED_PAYMENT_GATEWAYS.includes(paymentMethod)) {
      history.push("/");
    }

    let transactionRefId;
    let bankTansactionId;

    if (paymentMethod === PaymentMethods.CANADIA) {
      // params from canadia bank
      // http://localhost:3000/canadia/callback?ref=20201216083054&paymentReference=CNB0112233
      transactionRefId = query.get("ref");
      bankTansactionId = query.get("paymentReference");
    }

    if (!transactionRefId || !bankTansactionId) {
      history.push("/");
    }

    const data = {
      transaction_ref_id: transactionRefId,
      payment_gateway_transaction_id: bankTansactionId,
    };

    TransactionService.updateTransactionStatus(data)
      .then((response) => {
        setChecking(false);

        if (response.status === TransactionStatuses.COMPLETED) {
          history.push("/contribution-payment/success?status=success");
        } else {
          history.push("/contribution-payment/success?status=failed");
        }
      })
      .catch((error) => {
        setChecking(false);

        history.push("/contribution-payment/success?status=failed");
      });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <Header
        title={
          user.role === UserRoles.EMPLOYER
            ? t("payment.employerContributionPayment")
            : t("contribution.TuktukDriverContribution")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Backdrop className={classes.backdrop} open={checking}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
};

export default PaymentCallback;
