import currency from "currency.js";

export const format = (value, symbol) => {
  let precision = 0;

  if (value != parseInt(value)) {
    precision = 2;
  }

  return currency(value, {
    symbol: symbol ? symbol : "៛",
    precision: precision,
    pattern: "# !",
  }).format();
};
