import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { getPreviousYear, getNextYear } from "../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",

    marginBottom: 16,

    [theme.breakpoints.up("md")]: {
      marginTop: 12,
      borderTopWidth: 2,
      borderTopColor: "#BCE0FD",
      borderTopStyle: "solid",

      justifyContent: "center",
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, "90px"),
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const YearSwitcher = (props) => {
  const classes = useStyles();

  const { currentYear, onChange } = props;

  const selectNextYear = () => {
    let current = getNextYear(currentYear);
    onChange(current);
  };
  const selectPreviousYear = () => {
    let current = getPreviousYear(currentYear);
    onChange(current);
  };

  return (
    <div className={clsx(classes.root)}>
      <IconButton onClick={selectPreviousYear}>
        <NavigateBeforeIcon className={classes.icon} />
      </IconButton>
      <Typography variant={"h4"} className={classes.heading}>
        {currentYear.year()}
      </Typography>
      <IconButton onClick={selectNextYear}>
        <NavigateNextIcon className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default YearSwitcher;
