import { SET_VERIFICATION_PHONE } from "../types";

const initialState = {
  phone: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VERIFICATION_PHONE:
      return {
        ...state,
        phone: action.payload,
      };

    default:
      return state;
  }
}
