import { combineReducers } from "redux";
import userReducers from "./userReducers";
import authReducers from "./authReducers";
import registrationReducers from "./registrationReducers";
import employeeReducers from "./employeeReducers";
import otpReducers from "./otpReducers";
import contributionHistoryReducers from "./contributionHistoryReducers";
import contributionPaymentReducers from "./contributionPaymentReducers";
import transactionReducers from "./transactionReducers";
import provinceReducers from "./provinceReducers";
import districtReducers from "./districtReducers";
import communeReducers from "./communeReducers";
import nationalityReducers from "./nationalityReducers";
import exchangeRateReducers from "./exchangeRateReducers";
import notificationReducers from "./notificationReducers";
import paymentFailureReducers from "./paymentFailureReducers";

import { USER_LOGOUT_REQUEST } from "../types";

const reducers = combineReducers({
  users: userReducers,
  auth: authReducers,
  registrations: registrationReducers,
  employees: employeeReducers,
  otps: otpReducers,
  contributionHistory: contributionHistoryReducers,
  contributionPayment: contributionPaymentReducers,
  transaction: transactionReducers,
  provinces: provinceReducers,
  districts: districtReducers,
  communes: communeReducers,
  nationalities: nationalityReducers,
  exchangeRates: exchangeRateReducers,
  notifications: notificationReducers,
  paymentFailure: paymentFailureReducers,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT_REQUEST) state = undefined;

  return reducers(state, action);
};

export default rootReducer;
