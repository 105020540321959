import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));

const Hamburger = ({ toggled, toggle }) => {
  const classes = useStyles();

  const handler = () => {
    if (typeof toggle === "function") {
      toggle(!toggled);
    }
  };

  return (
    <IconButton
      arialabel="open drawer"
      edge="start"
      onClick={handler}
      className={classes.icon}
    >
      {!toggled && <MenuIcon></MenuIcon>}
      {toggled && <CloseIcon></CloseIcon>}
    </IconButton>
  );
};
export default Hamburger;
