export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const SET_USER = "SET_USER";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_UPDATE_PHONE_SUCCESS = "USER_UPDATE_PHONE_SUCCESS";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const GET_REGISTRATION_REQUEST = "GET_REGISTRATION_REQUEST";
export const GET_REGISTRATION_SUCCESS = "GET_REGISTRATION_SUCCESS";
export const GET_REGISTRATION_FAILURE = "GET_REGISTRATION_FAILURE";

export const UPSERT_REGISTRATION_REQUEST = "UPSERT_REGISTRATION_REQUEST";
export const UPSERT_REGISTRATION_SUCCESS = "UPSERT_REGISTRATION_SUCCESS";
export const UPSERT_REGISTRATION_FAILURE = "UPSERT_REGISTRATION_FAILURE";

export const UPDATE_PRE_REGISTRATION_SUCCESS =
  "UPDATE_PRE_REGISTRATION_SUCCESS";

export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const DELETE_PHOTO_SUCCESS = "DELETE_PHOTO_SUCCESS";

export const SEARCH_EMPLOYEE_REQUEST = "SEARCH_EMPLOYEE_REQUEST";
export const SEARCH_EMPLOYEE_SUCCESS = "SEARCH_EMPLOYEE_SUCCESS";
export const SEARCH_EMPLOYEE_FAILURE = "SEARCH_EMPLOYEE_FAILURE";

export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";

export const FETCH_ASSOCIATIONS_REQUEST = "FETCH_ASSOCIATIONS_REQUEST";
export const FETCH_ASSOCIATIONS_SUCCESS = "FETCH_ASSOCIATIONS_SUCCESS";
export const FETCH_ASSOCIATIONS_FAILURE = "FETCH_ASSOCIATIONS_FAILURE";

export const FETCH_CONTRIBUTION_HISTORY_REQUEST =
  "FETCH_CONTRIBUTION_HISTORY_REQUEST";
export const FETCH_CONTRIBUTION_HISTORY_SUCCESS =
  "FETCH_CONTRIBUTION_HISTORY_SUCCESS";

export const SET_VERIFICATION_PHONE = "SET_VERIFICATION_PHONE";

export const SET_TRANSACTION = "SET_TRANSACTION";
export const RESET_TRANSACTION = "RESET_TRANSACTION";

export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILURE = "CREATE_TRANSACTION_FAILURE";

export const FETCH_PROVINCES_REQUEST = "FETCH_PROVINCES_REQUEST";
export const FETCH_PROVINCES_SUCCESS = "FETCH_PROVINCES_SUCCESS";
export const FETCH_PROVINCES_FAILURE = "FETCH_PROVINCES_FAILURE";

export const FETCH_DISTRICTS_REQUEST = "FETCH_DISTRICTS_REQUEST";
export const FETCH_DISTRICTS_SUCCESS = "FETCH_DISTRICTS_SUCCESS";
export const FETCH_DISTRICTS_FAILURE = "FETCH_DISTRICTS_FAILURE";

export const FETCH_COMMUNES_REQUEST = "FETCH_COMMUNES_REQUEST";
export const FETCH_COMMUNES_SUCCESS = "FETCH_COMMUNES_SUCCESS";
export const FETCH_COMMUNES_FAILURE = "FETCH_COMMUNES_FAILURE";

export const FETCH_NATIONALITIES_REQUEST = "FETCH_NATIONALITIES_REQUEST";
export const FETCH_NATIONALITIES_SUCCESS = "FETCH_NATIONALITIES_SUCCESS";
export const FETCH_NATIONALITIES_FAILURE = "FETCH_NATIONALITIES_FAILURE";

export const FETCH_EXCHANGE_RATE_REQUEST = "FETCH_EXCHANGE_RATE_REQUEST";
export const FETCH_EXCHANGE_RATE_SUCCESS = "FETCH_EXCHANGE_RATE_SUCCESS";
export const FETCH_EXCHANGE_RATE_FAILURE = "FETCH_EXCHANGE_RATE_FAILURE";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const MARK_AS_READ_NOTIFICATION = "MARK_AS_READ_NOTIFICATION";

export const CHECK_PAYMENT_FAILURE_REQUEST = "CHECK_PAYMENT_FAILURE_REQUEST";
export const CHECK_PAYMENT_FAILURE_SUCCESS = "CHECK_PAYMENT_FAILURE_SUCCESS";
