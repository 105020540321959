import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { Typography, Button, useMediaQuery } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      top: "56px !important",

      "& .MuiBackdrop-root": {
        backgroundColor: "transparent",
      },

      "& .MuiDialog-paper": {
        boxShadow: "none",
      },
    },

    [theme.breakpoints.up("sm")]: {
      "& .MuiDialogContent-root": {
        paddingBottom: 24,
      },
    },
  },

  header: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      "& .MuiBreadcrumbs-root": {
        display: "none",
      },

      display: "none",
    },
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginTop: 10,
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    marginTop: 48,
    [theme.breakpoints.up("sm")]: {
      marginTop: 32,
    },
  },
  successIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },
  button: {
    fontSize: 12,
    fontWeight: "700",

    [theme.breakpoints.up("sm")]: {
      height: 48,
      width: 306,
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing("20px", 0),
  },

  iconInfo: {
    fontSize: 36,
    color: theme.palette.primary.main,
  },
}));

const PhoneVerifiedSuccess = ({ open, title, onClose, error }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();

  const onPressQuit = () => {
    onClose();
    history.push("/profile");
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      className={classes.root}
    >
      <DialogTitle className={classes.header}>
        <Header title={title} onPressButtonBack={onPressQuit} />
      </DialogTitle>

      <DialogContent>
        <div className={classes.icon}>
          {!error && (
            <span className={classes.successIcon}>
              <DoneIcon></DoneIcon>
            </span>
          )}

          {error && <InfoIcon className={classes.iconInfo}></InfoIcon>}
        </div>

        <Typography className={classes.title} variant="body2" gutterBottom>
          {error
            ? "Phone is already in used."
            : t("profiles.phoneNumberModified")}
        </Typography>

        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.button}
            onClick={onPressQuit}
          >
            {t("profiles.backToMyProfile")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneVerifiedSuccess;
