import React, { useState, useEffect, Suspense } from "react";
import "../i18n";
import "./App.scss";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../theme";

import PersistentHeader from "../layout/HeaderPersistent";

import Home from "../pages/Home/Home";
import Login from "../pages/Auth/Login";
import LoginByPhone from "../pages/Auth/LoginByPhone";
import CheckSmsCode from "../pages/Auth/CheckSmsCode";
import Register from "../pages/Auth/Register";
import Logout from "../pages/Auth/Logout";
import Profile from "../pages/Profile/Index";

import Contact from "../pages/Contact/Contact";
import Information from "../pages/Information/Information";
import WorkingContract from "../pages/WorkingContract";

import Registration from "../pages/PreRegistration/Index";

import Payments from "../pages/Payments/Index";
import PaymentCallback from "../pages/Payments/PaymentCallback";

import Notifications from "../pages/Notifications/Notifications";
import Notification from "../pages/Notifications/Notification";

import PrivateRoute from "../components/PrivateRoute";
import PageNotFound from "../components/PageNotFound";
import PaymentFailureModal from "../components/PaymentFailureModal";

import Footer from "../layout/Footer";

import history from "../utils/history";

import { DEFAULT_LOCALE } from "../constants";

import { getUser } from "../store/actions/authActions";
import { fetchNotifications } from "../store/actions/notificationActions";

export const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state.auth.user);

  const getLanguage = () => {
    return localStorage.getItem("language");
  };

  useEffect(() => {
    const lng = getLanguage() || DEFAULT_LOCALE;
    i18n.changeLanguage(lng);

    if (user) {
      // refresh current logged in user data
      dispatch(getUser());

      dispatch(fetchNotifications());
    }

    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <Router history={history}>
          <PersistentHeader />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/login-phone/:role" component={LoginByPhone} />
            <Route path="/check-sms-code/:role" component={CheckSmsCode} />
            <Route path="/register/:role" component={Register} />
            <Route path="/logout" component={Logout} />
            <Route path="/contact" component={Contact} />
            <Route path="/information" component={Information} />
            <Route path="/working-contract" component={WorkingContract} />
            <PrivateRoute path="/pre-registration" component={Registration} />
            <PrivateRoute path="/contribution-payment" component={Payments} />
            <PrivateRoute
              exact
              path="/:payment_method/callback"
              component={PaymentCallback}
            />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/notifications/:id" component={Notification} />
            <PrivateRoute path="/notifications" component={Notifications} />

            <Route component={PageNotFound} />

            <Route path="/missing" component={PageNotFound} />
            <Redirect to="/missing" />
          </Switch>
          <Footer />
          <PaymentFailureModal open={user?.is_payment_failure} />
        </Router>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
