import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import YearSwitcher from "../../components/YearSwitcher";
import ContributionHistoryList from "../../components/ContributionHistory/ContributionHistoryList";
import { fetchContributionHistory } from "../../store/actions/contributionHistoryActions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  loading: {
    padding: theme.spacing("24px", 0),
  },
  blankslate: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: theme.spacing("28px", 0),
    marginBottom: 10,

    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
  },
}));

const ContributionHistoryPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const now = moment();
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState(now);

  const contributionGrouped = useSelector(
    (state) => state.contributionHistory.items
  );
  const loading = useSelector((state) => state.contributionHistory.loading);

  const user = useSelector((state) => state.auth.user);

  const onChange = (current) => {
    setCurrentYear(current);
  };

  const renderBlankslate = () => {
    if (!loading && contributionGrouped.length == 0) {
      return (
        <Typography className={classes.blankslate}>
          {t("contribution.noContribution")}
        </Typography>
      );
    }
  };

  useEffect(() => {
    dispatch(fetchContributionHistory(currentYear.year()));
  }, [currentYear]);

  return (
    <div className={clsx(classes.root)}>
      <YearSwitcher currentYear={currentYear} onChange={onChange} />

      {!loading && (
        <ContributionHistoryList
          contributionGrouped={contributionGrouped}
          userRole={user.role}
        />
      )}

      {renderBlankslate()}

      {loading && <Loading styles={classes.loading} />}
    </div>
  );
};

export default ContributionHistoryPage;
