import React from "react";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";
import Info from "../../components/Info";

import { useTracking } from "../../hooks/useTracking";
import Tracker from "../../services/tracker";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingBottom: 42,
  },
}));

const Information = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  useTracking(Tracker.TRACKER_PAGES.INFORMATION);

  let breadcrumbs = [{ title: t("common.information"), route: "/information" }];

  if (user) {
    breadcrumbs.unshift({
      title: t(`common.${user.role}`),
      route: "/",
    });
  }

  const onPressButtonBack = () => {
    history.push("/");
  };

  return (
    <>
      <Header
        title={t("common.information")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" className={classes.paper}>
        <Info />
      </Container>
    </>
  );
};

export default Information;
