import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { List, Grid, Container, Typography } from "@material-ui/core";

import Header from "../../components/Header";
import PreRegistrationSuccessPopup from "../../components/PreRegistration/SuccessPopup";
import ErrorModal from "../../components/PreRegistration/ErrorModal";
import Steps from "../../components/PreRegistration/Steps";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

import {
  BasicInformations,
  Addresses,
  PlaceOfBirth,
  Documents,
  Employers,
  Contacts,
  PageTitle,
} from "../../components/PreRegistration/Confirmation";

import { submitRegistration } from "../../store/actions/registrationActions";
import { UserRoles, RegistrationStatuses } from "../../constants";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  paper: {
    paddingBottom: 48,
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textDecoration: "underline",
    marginTop: 0,
    marginBottom: 0,
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  addressTitle: {
    marginTop: 36,
  },
  list: {
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginTop: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  itemTitle: { fontSize: 18, color: theme.palette.primary.main },
  button: {
    [theme.breakpoints.up("md")]: {
      width: 240,
      fontSize: 12,
      fontWeight: "700",
      margin: theme.spacing(0, "auto"),
      marginRight: 16,
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
      borderWidth: 1,
    },
  },
  footer: {
    marginTop: 36,
    display: "flex",
    "& div": {
      [theme.breakpoints.down("md")]: {
        flex: 1,
        display: "flex",
        padding: theme.spacing(0, "4px"),
      },
    },
  },
  buttonLight: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  box: {
    [theme.breakpoints.up("md")]: {
      borderRightColor: "#BCE0FD",
      borderRightStyle: "solid",
      borderRightWidth: 2,
      padding: theme.spacing(0, "28px"),
    },
    "&:first-child": {
      paddingLeft: 0,
    },

    "&:last-child": {
      paddingRight: 0,
      borderRightWidth: 0,
    },
  },
  noBorder: {
    [theme.breakpoints.up("md")]: {
      borderRightWidth: 0,
    },
  },
  buttonLoading: {
    position: "relative",
    left: -8,
  },
}));

const Confirmation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user, registration, loading } = props;
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.preRegistration"),
      route: "/pre-registration",
    },
  ];

  const onPressButtonBack = () => {
    history.goBack();
  };

  const onPressCancel = () => {
    history.push("/");
  };

  const onPressEdit = () => {
    history.push("/pre-registration");
  };

  const onPressSubmit = () => {
    setSubmitting(true);

    dispatch(submitRegistration()).then((res) => {
      if (res.error) {
        setIsError(true);
      } else {
        setOpen(true);
      }

      setSubmitting(false);
    });
  };

  const onCloseSuccessDialog = () => {
    setOpen(false);
  };

  const onCloseErrorDialog = () => {
    setIsError(false);
  };

  const isEmployer = () => {
    return user.role == UserRoles.EMPLOYER;
  };

  const isSubmittable = () => {
    return (
      user.pre_registration_status !== RegistrationStatuses.VALIDATED ||
      user.pre_registration_status !== RegistrationStatuses.SUBMITTED
    );
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <Header
        title={t(`registration.${props.pageTitle}`)}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />

      <Container component="main" className={classes.main} maxWidth={false}>
        <Steps activeStep={isEmployer() ? 1 : 2} user={user} />

        <Container className={classes.paper}>
          <PageTitle />
          <Grid container>
            <Grid item xs={12} md={4} className={classes.box}>
              <Typography className={classes.heading} variant="h4">
                {t("registration.personalInformations")}
              </Typography>
              <List className={classes.list}>
                <BasicInformations user={user} registration={registration} />
                <Contacts user={user} registration={registration} />
              </List>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={clsx(classes.box, {
                [classes.noBorder]: isEmployer(),
              })}
            >
              <Typography className={classes.heading} variant="h4">
                {t("registration.placeOfBirth")}
              </Typography>
              <PlaceOfBirth registration={registration}></PlaceOfBirth>

              <Typography
                className={clsx(classes.heading, classes.addressTitle)}
                variant="h4"
              >
                {t("registration.address")}
              </Typography>
              <Addresses registration={registration}></Addresses>
            </Grid>

            <Grid item xs={12} md={4} className={classes.box}>
              <List className={classes.list}>
                <Employers user={user} registration={registration}></Employers>
                <Documents user={user} />
              </List>
            </Grid>
          </Grid>

          <div className={classes.footer}>
            <div>
              <Button
                onClick={onPressCancel}
                className={clsx(classes.button, classes.buttonLight)}
                title={t("form.cancel")}
              ></Button>
            </div>

            <div>
              <Button
                onClick={onPressEdit}
                className={clsx(classes.button, classes.buttonLight)}
                title={t("form.edit")}
              ></Button>
            </div>
            <div>
              <Button
                color={"primary"}
                onClick={onPressSubmit}
                className={classes.button}
                disabled={submitting || !isSubmittable()}
                loading={submitting}
                title={t("form.submit")}
              ></Button>
            </div>
          </div>

          <PreRegistrationSuccessPopup
            open={open}
            title={t(`registration.${props.pageTitle}`)}
            onClose={onCloseSuccessDialog}
          />
          <ErrorModal
            open={isError}
            title={t(`registration.${props.pageTitle}`)}
            onClose={onCloseErrorDialog}
          />
        </Container>
      </Container>
    </>
  );
};

export default Confirmation;
