import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, Button, useMediaQuery } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    top: "104px !important",

    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },

    "& .MuiDialog-paper": {
      boxShadow: "none",
    },

    [theme.breakpoints.up("md")]: {
      top: "0 !important",

      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },

      "& .MuiDialogContent-root": {
        paddingBottom: 24,
      },
    },
  },

  header: {
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  dialogTitle: {
    padding: 0,
    paddingTop: 8,
    height: 48,
    color: "white",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 14,
    alignItems: "center",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 32,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 8,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  button: {
    marginTop: 20,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 240,
    },
  },
  errorIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#f44336",
    fontSize: 48,
  },
  buttonQuit: {
    fontSize: 12,
    fontWeight: "700",
    marginTop: 24,

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 306,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const ErrorModal = ({ open, title, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();

  const onPressQuit = () => {
    onClose();
    history.push("/");
  };

  const popup = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={!popup}
      className={classes.root}
    >
      <DialogTitle className={classes.header}>
        <Header title={title} onPressButtonBack={onPressQuit} />
      </DialogTitle>

      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>

      <DialogContent>
        <div className={classes.titleContainer}>
          <span className={classes.errorIcon}>
            <InfoIcon fontSize="large"></InfoIcon>
          </span>
          <Typography className={classes.title} variant="h3" gutterBottom>
            {t("registration.preRegistrationError")}
          </Typography>
        </div>

        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.buttonQuit}
            onClick={onPressQuit}
          >
            {t("registration.quit")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorModal;
