import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";

import { UserRoles } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginTop: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  itemTitle: {
    fontSize: 18,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      borderBottomWidth: 2,
      borderBottomColor: "#BCE0FD",
      borderBottomStyle: "solid",
      marginBottom: 22,
      paddingBottom: 8,
    },
  },
  photos: {
    paddingLeft: 0,
    flexDirection: "column",
    alignItems: "flex-start",

    [theme.breakpoints.up("md")]: { paddingTop: 0 },

    "& .MuiListItemText-root": {
      [theme.breakpoints.up("md")]: {
        margin: 0,
      },
    },
    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
        textDecoration: "underline",
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  photosCheckList: {
    paddingLeft: 17,
    marginTop: 8,

    [theme.breakpoints.up("md")]: {
      marginTop: 18,
    },
  },
}));

const Documents = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (user.role === UserRoles.EMPLOYER) {
    return null;
  }

  return (
    <ListItem className={classes.photos}>
      <ListItemText primary={t("registration.submittedDocumentPhotos")} />

      <ul className={classes.photosCheckList}>
        <li className={classes.itemTitle}>
          {t("registration.nationalIDCard")}
        </li>
        <li className={classes.itemTitle}>
          {t("registration.associationMembershipCard")}
        </li>

        {user.role == UserRoles.TUKTUK_DRIVER && (
          <li className={classes.itemTitle}>
            {t("registration.drivingLicenseCard")}
          </li>
        )}
        {user.role == UserRoles.DOMESTIC_WORKER && (
          <li className={classes.itemTitle}>
            {t("registration.workingContract")}
          </li>
        )}
      </ul>
    </ListItem>
  );
};

export default Documents;
