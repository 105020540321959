import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";

import Header from "../../components/Header";
import Loading from "../../components/Loading";
import EmployeeListHeader from "../../components/Payments/EmployeeListHeader";
import EmployeePayment from "../../components/Payments/EmployeePayment";
import Dialog from "../../components/Dialog";

import { fetchEmployees } from "../../store/actions/employeesAction";
import { fetchExchangeRates } from "../../store/actions/exchangeRateActions";

import {
  getTotalEmployeesContribution,
  getTotalEmployeesContributionWithCurrency,
  getContribution,
} from "../../utils/payment";
import { setTransaction } from "../../store/actions/contributionPaymentActions";
import { fetchContributionHistory } from "../../store/actions/contributionHistoryActions";

import { TransactionStatuses } from "../../constants";

import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      paddingTop: 24,
    },
  },
  row: {
    fontSize: 18,
    display: "flex",
    justifyContent: "space-between",
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    paddingBottom: 8,
    alignItems: "flex-end",
    color: theme.palette.primary.main,
    marginBottom: 32,

    [theme.breakpoints.up("md")]: {
      marginTop: 42,
    },
  },
  label: {
    fontSize: 11,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      fontWeight: "700",
    },
  },
  total: {
    [theme.breakpoints.down("md")]: {
      marginTop: 24,

      flexDirection: "column",
      justifyContent: "flex-start",
      paddingTop: 4,

      borderBottomWidth: 0,
    },

    "& .MuiTypography-body2": {
      [theme.breakpoints.down("md")]: {
        borderBottomWidth: 2,
        borderBottomColor: "#BCE0FD",
        borderBottomStyle: "solid",
        width: "100%",
        paddingBottom: 4,
        marginBottom: 10,
      },
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing("0", "auto"),
      },
    },
  },

  footer: {
    marginTop: 32,
    display: "flex",

    [theme.breakpoints.up("md")]: {
      marginTop: 100,
      marginBottom: 24,
    },
  },
  button: {
    fontSize: 12,
    height: 48,

    [theme.breakpoints.up("md")]: {
      width: 240,
    },
  },
  buttonCancel: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  loading: {
    paddingTop: 24,
    paddingBottom: 0,
  },
  blankslate: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
    margin: theme.spacing("48px", 0),
    marginBottom: 0,

    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },

  buttonAddEmployee: {
    marginTop: 16,
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
    },
    "& .MuiButton-label": {
      fontSize: 14,
    },

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      maxWidth: 240,
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      margin: theme.spacing(0, "auto"),
      marginTop: 32,

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
  },
}));

const EmployerPayment = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);

  const now = moment();

  // Employer pay for previous month
  const previousMonth = now.clone().subtract(1, "months");
  const currentYear = now.clone().year();
  const [alreadyPaidEmployee, setAlreadyPaidEmployee] = useState([]);

  const transaction = useSelector(
    (state) => state.contributionPayment.transaction
  );
  const contributionGrouped = useSelector(
    (state) => state.contributionHistory.items
  );

  const exchangeRates = useSelector((state) => state.exchangeRates.items);

  const employees = useSelector((state) => {
    const payments = transaction ? transaction.payments : [];

    return state.employees.items.map((item, index) => {
      let payment = payments.filter(
        (p) => p.contributor_id === item.employee_id
      )[0];

      return {
        ...item,
        number: index + 1,
        contributor_id: item.employee_id,
        salary: payment ? payment.salary : null,
        salary_currency: payment ? payment.salary_currency : "KHR",
        contribution: 0,
        selected: payment ? payment.selected : true,
      };
    });
  });

  const loading = useSelector((state) => state.employees.loading);

  const { register, handleSubmit, reset, control, getValues, watch } = useForm({
    defaultValues: {
      payments: [],
    },
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "payments",
    keyName: "uid",
  });

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/Profile/EmployerPaymentList",
    },
  ];

  const onSubmit = (data) => {
    const valid = isValid(data);

    if (valid) {
      setError(true);

      let total = getTotalContribution();

      let startDate = previousMonth.startOf("month").format();
      let endDate = previousMonth.endOf("month").format();

      let params = {
        total_amount_due: total,
        payment_method: "",
        start_at: startDate,
        end_at: endDate,
        payments: [],
      };

      for (const payment of data.payments) {
        params.payments.push({
          ...payment,
          frequency: 1,
          salary: payment.salary,
          salary_currency: payment.salary_currency,
          contribution: getContribution(payment, exchangeRates),
          contributor_id: payment.contributor_id,
          start_at: startDate,
          end_at: endDate,
        });
      }

      if (isAlreadyPaidPeriod(data.payments)) {
        setIsPaymentError(true);
        return;
      }

      dispatch(setTransaction(params)).then(() => {
        history.push("/contribution-payment/payment-method");
      });
    } else {
      setError(true);
    }
  };

  const isValid = (data) => {
    if (data.payments) {
      let selected = data.payments.filter((payment) => payment.selected);

      if (selected.length === 0) {
        return false;
      }
    }

    const isSelectedEmployeeNoSalary = _.some(data.payments, (d) => {
      return d.selected && (d.salary === 0 || d.salary === "");
    });

    return !isSelectedEmployeeNoSalary;
  };

  const onPressButtonBack = () => {
    history.push("/");
  };

  const getTotalContribution = () => {
    const selected = selectedEmployeePayment();

    return getTotalEmployeesContribution(selected, exchangeRates);
  };

  const totalContributionWithCurrency = () => {
    const selected = selectedEmployeePayment();

    return getTotalEmployeesContributionWithCurrency(selected, exchangeRates);
  };

  const selectedEmployeePayment = () => {
    const data = getValues();

    if (data && data.payments) {
      return data.payments.filter((payment) => payment.selected);
    }

    return [];
  };

  const onPressAddEmployee = () => {
    history.push("/profile/add-employee");
  };

  const renderBlankslate = () => {
    if (!loading && employees.length === 0) {
      return (
        <>
          <Typography className={classes.blankslate}>
            {t("registration.noEmployeeRegistered")}
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                size={"large"}
                disableElevation
                className={classes.buttonAddEmployee}
                onClick={onPressAddEmployee}
                variant="outlined"
              >
                {t("registration.addEmployee")}
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const isAlreadyPaidPeriod = (currentPayments) => {
    let startDate = previousMonth.startOf("month").format("YYYY-MM-DD");
    const items = contributionGrouped.map(function (item) {
      return item["payments"];
    });
    const payments = _.flatten(items);

    const lastMonthPayments = payments.filter(
      (item) =>
        [
          TransactionStatuses.COMPLETED,
          TransactionStatuses.AWAITING_CONFIRMATION,
        ].includes(item.status) && item.start_at === startDate
    );

    let paidEmployees = [];
    for (const payment of currentPayments) {
      let employees = lastMonthPayments.filter(
        (item) => item.id === payment.contributor_id
      );
      if (payment.selected && employees[0]) {
        paidEmployees.push(payment);
      }
    }

    setAlreadyPaidEmployee(paidEmployees);
    if (paidEmployees.length > 0) {
      return true;
    }

    return false;
  };

  watch("payments");

  useEffect(() => {
    dispatch(fetchExchangeRates());
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchContributionHistory(currentYear));
  }, [dispatch, currentYear]);

  useEffect(() => {
    reset({
      payments: employees,
    });
  }, [reset, loading]);

  return (
    <>
      <Header
        title={t("payment.employerContributionPayment")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />

      <Container component="main">
        {loading && <Loading styles={classes.loading} />}

        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          className={classes.root}
        >
          <div className={classes.row}>
            <Typography variant={"h4"} className={classes.label}>
              {t("payment.monthOfContribution")}
            </Typography>
            <Typography variant={"h3"} className={classes.title}>
              {previousMonth.format("MMMM YYYY")}
            </Typography>
          </div>

          {employees.length > 0 && <EmployeeListHeader />}

          {renderBlankslate()}

          {fields.map((payment, index) => (
            <EmployeePayment
              payment={payment}
              index={index}
              key={index}
              register={register}
              control={control}
              getValues={getValues}
              remove={remove}
              error={error}
            />
          ))}

          <Grid container className={clsx(classes.row, classes.total)}>
            <Typography variant={"body2"} className={classes.label}>
              {t("payment.totalPaymentAmountDue")}
            </Typography>
            <Typography variant={"body1"} className={classes.title}>
              {totalContributionWithCurrency()}
            </Typography>
          </Grid>

          <Grid container className={classes.footer} spacing={1}>
            <Grid item xs={6} className={classes.buttonCancel}>
              <Button
                variant="contained"
                color="primary"
                size={"large"}
                disableElevation
                fullWidth
                className={classes.button}
                href="#"
              >
                {t("form.cancel")}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size={"large"}
                disableElevation
                fullWidth
                className={classes.button}
                disabled={employees.length === 0}
              >
                {t("payment.payNow")}
              </Button>
            </Grid>
          </Grid>
        </form>

        <Dialog
          title={t("payment.employeePaymentError")}
          open={isPaymentError}
          setOpen={setIsPaymentError}
        >
          <Typography>
            {t("payment.youAlreadyPaidLastMonthForEmployee")}
          </Typography>

          <ul>
            {alreadyPaidEmployee.map((item, index) => (
              <li key={index}>
                {i18n.language === "kh" && (
                  <strong>{item.employee_name_kh}</strong>
                )}
                {i18n.language === "en" && (
                  <strong>{item.employee_name_latin}</strong>
                )}
                {" - "}
                <strong>{item.employee_nssf_id}</strong>
              </li>
            ))}
          </ul>
        </Dialog>
      </Container>
    </>
  );
};

export default EmployerPayment;
