import { SET_TRANSACTION, RESET_TRANSACTION } from "../types";

export const setTransaction = (transaction) => async (dispatch) => {
  try {
    dispatch({
      type: SET_TRANSACTION,
      payload: transaction,
    });
  } catch (err) {}
};

export const resetTransaction = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_TRANSACTION,
    });
  } catch (err) {}
};
