import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Box, Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import RoomIcon from "@material-ui/icons/Language";

import Header from "../../components/Header";
import { resetTransaction } from "../../store/actions/contributionPaymentActions";
import { UserRoles } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  title: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  message: {
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    marginTop: 8,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 32,
  },
  button: {
    marginTop: 20,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    padding: theme.spacing("11px", "21px"),

    [theme.breakpoints.up("md")]: {
      height: 48,
      width: 240,
    },
  },
}));

const CashPaymentSuccess = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = props;

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/contribution-payment",
    },
    {
      title: t("payment.paymentConfirmation"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/");
  };

  useEffect(() => {
    dispatch(resetTransaction());
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <Header
        title={
          user.role === UserRoles.EMPLOYER
            ? t("payment.employerContributionPayment")
            : t("contribution.TuktukDriverContribution")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Box className={classes.container}>
          <Typography className={classes.title}>
            {t("payment.nssfContributors")}
          </Typography>

          <Typography className={classes.message}>
            {t("payment.paymentByCashMessage")}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default CashPaymentSuccess;
