import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Payment from "./Payment";
import PaymentMethod from "./PaymentMethod";
import PaymentProcessing from "./PaymentProcessing";
import CashPaymentSuccess from "./CashPaymentSuccess";
import PaymentSuccess from "./PaymentSuccess";

import { useTracking } from "../../hooks/useTracking";
import Tracker from "../../services/tracker";

import { resetTransaction } from "../../store/actions/contributionPaymentActions";

const Payments = () => {
  const dispatch = useDispatch();
  let { path } = useRouteMatch();

  useTracking(Tracker.TRACKER_PAGES.CONTRIBUTION_PAYMENT);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(resetTransaction());
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <Payment user={user} {...props} />}
      />

      <Route
        exact
        path={`${path}/payment-method`}
        render={(props) => <PaymentMethod user={user} {...props} />}
      />

      <Route
        exact
        path={`${path}/payment-processing`}
        render={(props) => <PaymentProcessing user={user} {...props} />}
      />

      <Route
        exact
        path={`${path}/success`}
        render={(props) => <PaymentSuccess user={user} {...props} />}
      />

      <Route
        exact
        path={`${path}/cash-payment`}
        render={(props) => <CashPaymentSuccess user={user} {...props} />}
      />
    </Switch>
  );
};

export default Payments;
