import {
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
} from "../types";

import { axios } from "../../utils/request";
import { config } from "../../config";
import history from "../../utils/history";

import { PaymentMethods } from "../../constants";

export const createTransaction = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_TRANSACTION_REQUEST,
  });

  try {
    const response = await axios.post(
      `${config.API_BASE_URL}/transactions`,
      data
    );

    dispatch({
      type: CREATE_TRANSACTION_SUCCESS,
      payload: response.data.data,
    });

    const transaction = response.data.data;

    if (transaction.payment_method == PaymentMethods.CASH_PAYMENT) {
      history.push("/contribution-payment/cash-payment");
    } else {
      if (transaction.payment_url) {
        // redirect to payment gateway payment url
        window.location.replace(transaction.payment_url);
      } else {
        history.push("/");
      }
    }
  } catch (err) {
    dispatch({
      type: CREATE_TRANSACTION_FAILURE,
      payload: err.response,
    });
  }
};
