import {
  SEARCH_EMPLOYEE_REQUEST,
  SEARCH_EMPLOYEE_SUCCESS,
  SEARCH_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
} from "../types";

const initialState = {
  items: [],
  item: null,
  loading: true,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      };

    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        items: [],
        loading: false,
        error: action.payload,
      };

    case SEARCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        item: null,
        loading: true,
        error: null,
      };

    case SEARCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
        error: null,
      };

    case SEARCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        item: null,
        loading: false,
        error: action.payload,
      };

    case CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        items: state.items.concat(action.payload),
      };

    case CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(
          (employee) => employee.id != action.payload.id
        ),
      };
    default:
      return state;
  }
}
