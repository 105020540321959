import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { format } from "../../utils/currency";

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
  item: {
    fontWeight: 600,
  },
  link: {
    marginTop: 16,
    display: "inline-block",
    textTransform: "capitalize",

    "&:hover": {
      cursor: "pointer",
    },
  },
  status: {
    paddingLeft: 8,
  },
}));

const TransactionStatusChanged = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { notification } = props;

  const onPressContributionHistory = () => {
    history.push("/profile?tab=contribution-history");
  };

  return (
    <>
      <Typography variant="h5">
        {t("notifications.transactionsStatusUpdate")}
      </Typography>
      <Typography variant="body2">
        {t("notifications.theStatusOfYouBelowTransactionsHasBeenUpdated")}:
      </Typography>

      <Box className={classes.list}>
        <Grid container item md={6} xs={12}>
          <Grid item xs={1}>
            {t("notifications.id")}
          </Grid>
          <Grid item xs={3}>
            {t("notifications.startDate")}
          </Grid>
          <Grid item xs={3}>
            {t("notifications.endDate")}
          </Grid>
          <Grid item xs={2}>
            {t("notifications.amount")}
          </Grid>
          <Grid item xs={3} className={classes.status}>
            {t("notifications.newStatus")}
          </Grid>
        </Grid>

        {notification && (
          <Grid container item md={6} xs={12} className={classes.item}>
            <Grid item xs={1}>
              {notification.data?.id}
            </Grid>
            <Grid item xs={3}>
              {notification.data?.start_at}
            </Grid>
            <Grid item xs={3}>
              {notification.data?.end_at}
            </Grid>
            <Grid item xs={2}>
              {format(notification.data?.amount)}
            </Grid>
            <Grid item xs={3} className={classes.status}>
              {t(`payment.${notification.data?.status}`)}
            </Grid>
          </Grid>
        )}
      </Box>

      <Typography variant="body2">
        {t("notifications.pleaseCheckYourContributionHistory")}
      </Typography>

      <Link
        color="primary"
        className={classes.link}
        onClick={onPressContributionHistory}
      >
        {t("profiles.contributionHistory")}
      </Link>
    </>
  );
};
export default TransactionStatusChanged;
