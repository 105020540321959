import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Container,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserRoles } from "../../constants";

import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  title: { textAlign: "center" },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PaymentProcessing = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [processing, setProcessing] = useState(true);

  const { user } = props;
  const transaction = useSelector(
    (state) => state.contributionPayment.transaction
  );

  const error = useSelector((state) => state.transaction.error);

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("payment.contributionPayment"),
      route: "/contribution-payment",
    },
    {
      title: t("common.paymentProcessing"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/contribution-payment");
  };

  if (!transaction) {
    history.push("/contribution-payment");
  }

  useEffect(() => {
    if (error) {
      setProcessing(false);
    }
  }, [error]);

  return (
    <>
      <Header
        title={
          user.role === UserRoles.EMPLOYER
            ? t("payment.employerContributionPayment")
            : t("contribution.TuktukDriverContribution")
        }
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Box className={classes.container}>
          {!error && (
            <Typography className={classes.title}>
              {t("payment.paymentIsProcessing")}
            </Typography>
          )}

          {error && (
            <Typography className={classes.title}>
              {error.data?.message}
            </Typography>
          )}
        </Box>

        <Backdrop className={classes.backdrop} open={processing}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
};

export default PaymentProcessing;
