import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useSelector, connect } from "react-redux";

import { ReactComponent as TuktukIcon } from "../assets/icons/tuktuk.svg";
import { ReactComponent as WorkerIcon } from "../assets/icons/worker.svg";
import { ReactComponent as EmployerIcon } from "../assets/icons/employer.svg";
import { ReactComponent as RegisterIcon } from "../assets/icons/account-edit.svg";
import { ReactComponent as AccountIcon } from "../assets/icons/account.svg";
import { ReactComponent as InformationIcon } from "../assets/icons/information.svg";
import { ReactComponent as MoneyIcon } from "../assets/icons/money.svg";
import { ReactComponent as PinIcon } from "../assets/icons/pin.svg";
import { ReactComponent as LeaveIcon } from "../assets/icons/leave.svg";
import { ReactComponent as FileIcon } from "../assets/icons/file.svg";

import SvgIcon from "@material-ui/core/SvgIcon";

import PreRegistrationSuccessPopup from "./PreRegistration/SuccessPopup";
import {
  UserRoles,
  RegistrationStatuses,
  RegistrationPageTitle,
} from "../constants";
import { generateParamsUrl } from "../utils/url";
import Hamburger from "../components/Hamburger";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawer: {
    width: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    top: 56,
    [theme.breakpoints.up("sm")]: {
      top: 64,
    },
    paddingTop: 16,
  },
  listItem: {
    padding: theme.spacing("12px", 2),

    "& .MuiTypography-root": {
      fontSize: 17,
      fontWeight: "700",
      lineHeight: "20px",
      textTransform: "uppercase",
    },
  },
  listIcon: {
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
    minWidth: 48,
  },
  listTitle: {
    color: theme.palette.primary.contrastText,
    color: "#ffffff",
  },
}));

const DrawerMenu = ({ user }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [menus, setMenus] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [open, setOpen] = useState(false);

  const handleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const onClickMenu = (e, menu) => {
    if (
      menu.route == "/pre-registration" &&
      user.pre_registration_status === RegistrationStatuses.SUBMITTED
    ) {
      e.preventDefault();

      setOpenSuccess(true);
    }

    handleCloseDrawer();
  };

  const onCloseSuccessDialog = () => {
    setOpenSuccess(false);
  };

  const isValidated = () => {
    if (
      user &&
      user.pre_registration_status === RegistrationStatuses.VALIDATED
    ) {
      return true;
    }

    return false;
  };

  const renderMenuItem = (menu) => {
    if (menu.route == "/pre-registration" && isValidated()) {
      return null;
    }

    // Contribution payment is not available for Domestic Worker
    if (
      user &&
      menu.route == "/contribution-payment" &&
      user.role === UserRoles.DOMESTIC_WORKER
    ) {
      return null;
    }

    return (
      <ListItem
        button
        key={menu.title}
        className={classes.listItem}
        to={menu.route}
        component={RouterLink}
        onClick={(e) => onClickMenu(e, menu)}
      >
        <ListItemIcon className={classes.listIcon}>
          <SvgIcon>
            <menu.icon />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText primary={menu.title} className={classes.listTitle} />
      </ListItem>
    );
  };

  useEffect(() => {
    let list = [];

    if (user) {
      list = [
        { title: t("common.myProfile"), route: "/profile", icon: AccountIcon },
        {
          title: t("common.preRegistration"),
          route: "/pre-registration",
          icon: RegisterIcon,
        },
        {
          title: t("common.contributionPayment"),
          route: "/contribution-payment",
          icon: MoneyIcon,
        },
        {
          title: t("common.information"),
          route: "/information",
          icon: InformationIcon,
        },
        {
          title: t("common.workingContractSample"),
          route: "/working-contract",
          icon: FileIcon,
        },
        {
          title: t("common.contact"),
          route: "/contact",
          icon: PinIcon,
        },
        {
          title: t("common.logout"),
          route: "/logout",
          icon: LeaveIcon,
        },
      ];
    } else {
      list = [
        {
          title: t("common.tuktukDriver"),
          route:
            "/login" + generateParamsUrl({ role: UserRoles.TUKTUK_DRIVER }),
          icon: TuktukIcon,
        },
        {
          title: t("common.domesticWorker"),
          route:
            "/login" + generateParamsUrl({ role: UserRoles.DOMESTIC_WORKER }),
          icon: WorkerIcon,
        },
        {
          title: t("common.employer"),
          route: "/login" + generateParamsUrl({ role: UserRoles.EMPLOYER }),
          icon: EmployerIcon,
        },
        {
          title: t("common.information"),
          route: "/information",
          icon: InformationIcon,
        },
        {
          title: t("common.workingContractSample"),
          route: "/working-contract",
          icon: FileIcon,
        },
        {
          title: t("common.contact"),
          route: "/contact",
          icon: PinIcon,
        },
      ];
    }
    setMenus(list);
  }, [user, i18n.language]);
  return (
    <div className={classes.root}>
      <Hamburger toggled={open} toggle={handleDrawer} />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>{menus.map((menu, index) => renderMenuItem(menu))}</List>
        {isLoggedIn && (
          <PreRegistrationSuccessPopup
            open={openSuccess}
            title={t(`registration.${RegistrationPageTitle[user.role]}`)}
            onClose={onCloseSuccessDialog}
          />
        )}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DrawerMenu);
