import React from "react";
import {
  AppBar,
  Toolbar,
  Badge,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import clsx from "clsx";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useHistory } from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Logo from "../components/Logo";
import NavigationMenu from "../components/NavigationMenu";
import DrawerMenu from "../components/DrawerMenu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    "&.MuiPaper-elevation4": {
      boxShadow: "none",
    },
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolbarItem: { flex: 1 },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      flex: 0,
    },
  },
  language: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      flex: 0,
    },
  },
  notificationButton: {
    color: "#2699FB",
    padding: 6,
  },
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      backgroundColor: "#FF0021",
      top: 8,
      right: 8,
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const PersistentDrawerLeft = () => {
  const history = useHistory();
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector((state) => state.notifications.items);

  const unreadNotifications = notifications.filter(
    (item) => item.read_at == null
  );

  const onPressNotification = () => {
    history.push("/notifications");
  };

  const renderNotificationMenu = () => {
    if (!user) return null;

    return (
      <Badge
        color="secondary"
        badgeContent={unreadNotifications.length}
        className={classes.badge}
      >
        <IconButton
          color="inherit"
          aria-label="Notification"
          edge="start"
          className={classes.notificationButton}
          onClick={onPressNotification}
        >
          <NotificationsIcon />
        </IconButton>
      </Badge>
    );
  };

  return (
    <>
      <AppBar className={classes.appBar} color="default">
        <Toolbar className={classes.toolBar}>
          <DrawerMenu />
          <div className={clsx(classes.toolbarItem, classes.logo)}>
            <Logo></Logo>
          </div>
          <NavigationMenu />

          <div className={clsx(classes.toolbarItem, classes.language)}>
            {renderNotificationMenu()}
            <LanguageSwitcher />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PersistentDrawerLeft;
