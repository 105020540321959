import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import clsx from "clsx";

import { getContributionWithCurrency } from "../../utils/payment";

import { currencySymbols } from "../../constants";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    padding: theme.spacing(0, "10px"),
    paddingBottom: 16,
    paddingTop: 10,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
    },

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0),
      borderWidth: 0,
      marginTop: 0,
      backgroundColor: "none",
    },
  },

  item: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    alignItems: "center",
    padding: 0,
    paddingTop: 10,
    paddingBottom: 6,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing("16px", 0),
    },
  },
  heading: {
    fontSize: 16,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  itemLabel: {
    fontSize: 11,
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  itemTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },

  content: {
    fontSize: 15,
    paddingBottom: 22,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
  },
  formControl: {
    textAlign: "center",
    marginTop: 0,
    borderRadius: 0,
    paddingBottom: 3,

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },

    "& .MuiOutlinedInput-input": {
      padding: theme.spacing("15px", "14px"),

      [theme.breakpoints.down("md")]: {
        backgroundColor: "#FAFAFA !important",
        textAlign: "center",
      },
    },
  },
  checkboxContainer: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  checkBox: {
    marginRight: 0,
  },
  number: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  salary: {
    "& .MuiOutlinedInput-adornedEnd": {
      backgroundColor: "#ffffff",
    },

    "& .MuiTypography-colorTextSecondary": {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      paddingRight: 0,

      borderBottomWidth: 0,
      marginTop: 8,
    },

    "& .MuiTypography-body1": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: 4,
      },
    },

    paddingBottom: 2,
    paddingTop: 2,
  },
  contribution: {
    paddingTop: 18,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      paddingTop: 4,

      borderBottomWidth: 0,
    },

    "& .MuiTypography-body1": {
      [theme.breakpoints.down("md")]: {
        borderBottomWidth: 2,
        borderBottomColor: "#BCE0FD",
        borderBottomStyle: "solid",
        width: "100%",
        paddingBottom: 4,
        marginBottom: 10,
      },
    },
  },

  action: {
    padding: 0,
    width: "100%",
    justifyContent: "center",
  },

  buttonRemove: {
    position: "absolute",
    top: 4,
    right: 4,

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  iconRemove: {
    fontSize: "1.5rem",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  salaryControl: {
    display: "flex",
    paddingRight: 5,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  currency: {
    width: 58,
    marginLeft: -1,
    "& .MuiSelect-select": {},
    "& .MuiOutlinedInput-notchedOutline": {},
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: 0,
      textAlign: "left",
      paddingLeft: 12,
    },
    "& .MuiSelect-iconOutlined": {
      right: 2,
    },
  },
}));

const EmployeePayment = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { payment, register, getValues, control, remove, index, error } = props;

  const exchangeRates = useSelector((state) => state.exchangeRates.items);

  const currencies = [
    {
      name: "Riel",
      symbol: "៛",
      code: "KHR",
    },
    {
      name: "USD",
      symbol: "$",
      code: "USD",
    },
    {
      name: "Dong",
      symbol: "₫",
      code: "VND",
    },
    {
      name: "Thai Baht",
      symbol: "฿",
      code: "THB",
    },
  ];

  const onPressDelete = () => {
    remove(index);
  };

  const contribution = (index) => {
    const data = getValues();

    if (data.payments) {
      const payment = data.payments[index];

      return getContributionWithCurrency(payment, exchangeRates);
    }

    return 0;
  };

  return (
    <Grid container className={clsx(classes.row)} spacing={1}>
      <TextField
        name={`payments[${index}].contributor_id`}
        type="hidden"
        defaultValue={payment.contributor_id}
        inputRef={register({ required: true })}
      />
      <TextField
        name={`payments[${index}].frequency`}
        type="hidden"
        defaultValue={payment.frequency}
        inputRef={register({ required: false })}
      />
      <TextField
        name={`payments[${index}].start_at`}
        type="hidden"
        defaultValue={payment.startDate}
        inputRef={register({ required: false })}
      />
      <TextField
        name={`payments[${index}].employee_gender`}
        type="hidden"
        defaultValue={payment.employee_gender}
      />
      <TextField
        name={`payments[${index}].employee_name_kh`}
        type="hidden"
        defaultValue={payment.employee_name_kh}
        inputRef={register({ required: false })}
      />
      <TextField
        name={`payments[${index}].employee_name_latin`}
        type="hidden"
        defaultValue={payment.employee_name_latin}
        inputRef={register({ required: false })}
      />

      <TextField
        name={`payments[${index}].employee_nssf_id`}
        type="hidden"
        defaultValue={payment.employee_nssf_id}
        inputRef={register({ required: true })}
      />

      <Typography className={classes.heading}>
        {t("common.employee")} {payment.number}
      </Typography>

      <Grid item xs={12} md={1} className={classes.number}>
        <div className={classes.item}>
          <Typography className={classes.itemTitle}>
            {payment.number}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className={classes.item}>
          <Typography className={classes.itemLabel}>
            {t("registration.fullNameKH")}
          </Typography>
          <Typography className={classes.itemTitle}>
            {payment.employee_name_kh}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className={classes.item}>
          <Typography className={classes.itemLabel}>
            {t("registration.fullNameLATIN")}
          </Typography>
          <Typography className={classes.itemTitle}>
            {payment.employee_name_latin}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={1}>
        <div className={classes.item}>
          <Typography className={classes.itemLabel}>
            {t("registration.gender")}
          </Typography>
          <Typography className={classes.itemTitle}>
            {t(`registration.${payment.employee_gender}`)}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className={classes.item}>
          <Typography className={classes.itemLabel}>
            {t("registration.nssfIdNumber")}
          </Typography>
          <Typography className={classes.itemTitle}>
            {payment.employee_nssf_id}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <div className={clsx(classes.item, classes.salary)}>
          <Typography className={classes.itemLabel}>
            {t("payment.employeePreviousMonthSalary")}
          </Typography>
          <div className={clsx(classes.salaryControl)}>
            <TextField
              name={`payments[${index}].salary`}
              variant="outlined"
              defaultValue={payment.salary}
              inputRef={register()}
              placeholder={"0"}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className={clsx(classes.itemTitle, classes.formControl)}
              error={payment.selected && error && !payment.salary}
            ></TextField>

            <Controller
              as={
                <TextField
                  select
                  variant="outlined"
                  className={clsx(classes.currency, classes.formControl)}
                  SelectProps={{
                    renderValue: (value) => currencySymbols[value],
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.name} {option.symbol}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name={`payments[${index}].salary_currency`}
              control={control}
              defaultValue={payment.salary_currency || "KHR"}
            />
          </div>
        </div>
      </Grid>

      <Grid container item xs={12} md={2} spacing={1}>
        <Grid item xs={12} md={8}>
          <div className={clsx(classes.item, classes.contribution)}>
            <Typography variant="body1" className={classes.itemLabel}>
              {t("payment.contributionAmountDue")}
            </Typography>
            <Typography variant="body2" className={classes.itemTitle}>
              {contribution(index)}
            </Typography>
          </div>
        </Grid>
        <Grid item md={4} className={classes.checkboxContainer}>
          <div className={clsx(classes.item, classes.action)}>
            <FormControlLabel
              control={
                <Checkbox
                  name={`payments[${index}].selected`}
                  color="primary"
                  defaultChecked={payment.selected}
                  inputRef={register()}
                />
              }
              className={classes.checkBox}
            />
          </div>
        </Grid>
      </Grid>

      <IconButton
        color="primary"
        aria-label="remove employee"
        className={classes.buttonRemove}
        size="small"
        onClick={onPressDelete}
      >
        <CloseIcon className={classes.iconRemove} />
      </IconButton>
    </Grid>
  );
};
export default EmployeePayment;
