import {
  UPSERT_REGISTRATION_REQUEST,
  UPSERT_REGISTRATION_SUCCESS,
  UPSERT_REGISTRATION_FAILURE,
  GET_REGISTRATION_REQUEST,
  GET_REGISTRATION_SUCCESS,
  GET_REGISTRATION_FAILURE,
  UPLOAD_PHOTO_SUCCESS,
  DELETE_PHOTO_SUCCESS,
} from "../types";

const initialState = {
  item: {
    photos: [],
  },
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPSERT_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPSERT_REGISTRATION_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
        error: null,
      };
    case UPSERT_REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_REGISTRATION_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
        error: null,
      };

    case GET_REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          photos: state.item.photos.concat(action.payload),
        },
      };
    case DELETE_PHOTO_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          photos: state.item.photos.filter(
            (photo) => photo.id != action.payload.id
          ),
        },
      };

    default:
      return state;
  }
}
