import _ from "lodash";
import {
  FETCH_CONTRIBUTION_HISTORY_REQUEST,
  FETCH_CONTRIBUTION_HISTORY_SUCCESS,
} from "../types";
import { axios } from "../../utils/request";
import { config } from "../../config";

export const fetchContributionHistory = (currentYear) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CONTRIBUTION_HISTORY_REQUEST,
    });

    const response = await axios.get(
      `${config.API_BASE_URL}/payments?year=${currentYear}`
    );
    const payments = response.data.data;

    var grouped = _.chain(payments)
      .groupBy("period")
      .map((value, key) => ({
        startAt: value[0]["start_at"],
        endAt: value[0]["end_at"],
        payments: value,
      }))
      .value();

    dispatch({
      type: FETCH_CONTRIBUTION_HISTORY_SUCCESS,
      payload: grouped,
    });
  } catch (err) {}
};
