import React, { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import PersonalInformation from "../../pages/PreRegistration/PersonalInformations";
import RegistrationUpload from "../../pages/PreRegistration/Upload";
import RegistrationConfirmation from "../../pages/PreRegistration/Confirmation";
import Loading from "../../components/Loading";
import Header from "../../components/Header";

import { fetchRegistration } from "../../store/actions/registrationActions";
import { fetchAssociations } from "../../store/actions/usersActions";
import { fetchProvinces } from "../../store/actions/provinceActions";
import { fetchNationalities } from "../../store/actions/nationalityActions";

import { createLoadingSelector } from "../../store/selectors";

import {
  UserRoles,
  RegistrationStatuses,
  RegistrationPageTitle,
} from "../../constants";

import { useTracking } from "../../hooks/useTracking";
import Tracker from "../../services/tracker";

import history from "../../utils/history";

const useStyles = makeStyles((theme) => ({
  message: {
    fontSize: 17,
    color: theme.palette.primary.main,
    padding: theme.spacing("48px", 0),
    textAlign: "center",
  },
}));

const PreRegistration = (props) => {
  const dispatch = useDispatch();
  let { path } = useRouteMatch();
  const classes = useStyles();
  const { t } = useTranslation();

  useTracking(Tracker.TRACKER_PAGES.PRE_REGISTRATION);

  const { loading } = props;
  const user = useSelector((state) => state.auth.user);
  const registration = useSelector((state) => state.registrations.item);

  const associations = useSelector((state) => state.users.associations);
  const pageTitle = RegistrationPageTitle[user.role];

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.preRegistration"),
      route: "/pre-registration",
    },
  ];

  useEffect(() => {
    dispatch(fetchRegistration());
    dispatch(fetchAssociations());
    dispatch(fetchProvinces());
    dispatch(fetchNationalities());
  }, []);

  if (
    [RegistrationStatuses.SUBMITTED, RegistrationStatuses.VALIDATED].includes(
      user.pre_registration_status
    ) &&
    !history.location.pathname.includes("confirmation")
  ) {
    return (
      <>
        <Header title={t("common.preRegistration")} breadcrumbs={breadcrumbs} />
        <Container component="main">
          <div className={classes.message}>
            Your pre-registration have been submitted or validated.
          </div>
        </Container>
      </>
    );
  }

  if (
    ![
      UserRoles.DOMESTIC_WORKER,
      UserRoles.TUKTUK_DRIVER,
      UserRoles.EMPLOYER,
    ].includes(user.role)
  ) {
    return (
      <div className={classes.message}>
        Pre-registration doesn't available for your role.
      </div>
    );
  }

  if (loading) {
    return (
      <Container component="main">
        <Loading />
      </Container>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <PersonalInformation
            pageTitle={pageTitle}
            user={user}
            registration={registration}
            associations={associations}
            loading={loading}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${path}/upload`}
        render={(props) => (
          <RegistrationUpload
            pageTitle={pageTitle}
            user={user}
            registration={registration}
            loading={loading}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${path}/confirmation`}
        render={(props) => (
          <RegistrationConfirmation
            pageTitle={pageTitle}
            user={user}
            registration={registration}
            loading={loading}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

const loadingSelector = createLoadingSelector([
  "associations",
  "provinces",
  "nationalities",
]);
const mapStateToProps = (state) => ({ loading: loadingSelector(state) });
export default connect(mapStateToProps)(PreRegistration);
