import React from "react";
import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { selectStyles } from "../../theme/sharedStyles";

const ProvinceSelect = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { name, setValue, defaultValue, errors, onChange } = props;

  const provinces = useSelector((state) => state.provinces.items);

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const provinceOptions = provinces
    .filter((province) => {
      if (province.pro_id != -1) {
        return province;
      }
    })
    .map((province) => {
      return {
        value: province.pro_id,
        label:
          i18n.language == "kh"
            ? province.pro_name_khmer
            : province.pro_name_latin,
        name: province.pro_name_latin,
      };
    });

  return (
    <FormControl
      variant="outlined"
      className={props.class}
      error={!!errors[name]?.message}
    >
      <InputLabel>{props.title}</InputLabel>
      <Controller
        name={name}
        control={props.control}
        render={({ onChange }) => (
          <Select
            options={provinceOptions}
            onChange={(selected) => {
              onChange(selected);
              handleChange(selected);
            }}
            styles={selectStyles(errors[name]?.message)}
            defaultValue={provinceOptions.find(
              (option) => option.value == defaultValue
            )}
          />
        )}
        defaultValue={provinceOptions.find(
          (option) => option.value == defaultValue
        )}
        rules={{ required: "Required" }}
      />
      {!!errors[name]?.message && (
        <FormHelperText error={!!errors[name]?.message}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ProvinceSelect;
