import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import OTPVerification from "../../components/OTPVerification";
import PhoneVerifiedSuccess from "../../components/Profile/PhoneVerifiedSuccess";

import { updateUserPhone } from "../../store/actions/usersActions";

const useStyles = makeStyles((theme) => ({}));

const PhoneNumberVerification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const phone = useSelector((state) => state.otps.phone);

  const breadcrumbs = [
    {
      title: t("common.myProfile"),
      route: "/profile",
    },
    {
      title: t("registration.updateMyPhoneNumber"),
    },
  ];

  const onPressButtonBack = () => {
    history.push("/profile");
  };

  const onSuccess = (phoneCode) => {
    let data = { phone: phone };

    dispatch(
      updateUserPhone(user.id, data, (response) => {
        if (response.errors) {
          setError(response.errors);
          setOpenModal(true);
        } else {
          setOpenModal(true);
        }
      })
    );
  };

  const onCloseDialog = () => {
    setOpenModal(false);
  };

  if (!phone) {
    history.push("/profile/update-phone");
  }

  return (
    <>
      <Header
        title={t("registration.updateMyPhoneNumber")}
        onPressButtonBack={onPressButtonBack}
        breadcrumbs={breadcrumbs}
      />

      <Container component="main" maxWidth="xs">
        <OTPVerification
          verifyOnly={true}
          phone={phone}
          onSuccess={onSuccess}
        ></OTPVerification>

        <PhoneVerifiedSuccess
          open={openModal}
          title={t("registration.updateMyPhoneNumber")}
          onClose={onCloseDialog}
          error={error}
        />
      </Container>
    </>
  );
};

export default PhoneNumberVerification;
