import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { Typography, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 18,
    [theme.breakpoints.up("sm")]: {
      minWidth: 480,
      paddingBottom: 8,
    },
  },
  iconContainer: {
    display: "none",
    justifyContent: "center",
    marginTop: 10,
    [theme.breakpoints.up("sm")]: {
      marginTop: 16,
      display: "flex",
    },
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: 2,

    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    marginBottom: 10,
    paddingBottom: 10,

    [theme.breakpoints.up("sm")]: {
      marginTop: 16,
      borderBottomWidth: 0,
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  message: {
    color: theme.palette.primary.main,
    marginTop: 8,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  button: {
    fontSize: 12,
    fontWeight: "700",
    display: "none",

    [theme.breakpoints.up("sm")]: {
      height: 48,
      width: 240,
      margin: theme.spacing("32px", "auto"),
      display: "flex",
    },
  },
  buttonClose: {
    position: "absolute",
    top: 8,
    right: 8,

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const EmployeeNotFoundModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onPress = () => {
    onClose();
  };

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <DialogContent className={classes.content}>
        <div className={classes.iconContainer}>
          <span className={classes.icon}>
            <RemoveIcon></RemoveIcon>
          </span>
        </div>

        <Typography className={classes.title} variant="h2" gutterBottom>
          {t("registration.nssfIdNumberNotRegistered")}
        </Typography>

        <Typography className={classes.message} variant="body2" gutterBottom>
          Lorem dolor sit amet consectetur adipisicing elit, sed do.
        </Typography>

        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.button}
            onClick={onPress}
          >
            {t("common.ok")}
          </Button>
        </div>

        <IconButton
          color="primary"
          aria-label="close dailog"
          className={classes.buttonClose}
          size="small"
          onClick={onPress}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeNotFoundModal;
