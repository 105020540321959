import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  MARK_AS_READ_NOTIFICATION,
} from "../types";
import { getShortDateFormat } from "../../utils/date";

const initialState = {
  items: [],
  loading: false,
};

export default function notifictionReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      };

    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MARK_AS_READ_NOTIFICATION:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.id
            ? { ...item, read_at: getShortDateFormat(new Date()) }
            : item
        ),
      };
    default:
      return state;
  }
}
