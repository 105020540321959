import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Grid, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";

import Header from "../../components/Header";
import Steps from "../../components/PreRegistration/Steps";
import Loading from "../../components/Loading";

import IDCardFront from "../../assets/id-card-front.png";
import IDCardBack from "../../assets/id-card-back.png";

import {
  uploadPhoto,
  deletePhoto,
} from "../../store/actions/registrationActions";
import { UserRoles } from "../../constants";

const MIN_UPLOAD_PHOTOS = 2;

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  button: {
    marginTop: 28,

    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
      fontSize: 12,
      fontWeight: "700",
      margin: theme.spacing(0, "auto"),
      marginTop: 32,
    },
  },
  photoContainer: {
    position: "relative",
  },
  photo: {
    width: "100%",
  },
  message: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginTop: 20,
    marginBottom: 16,

    [theme.breakpoints.up("sm")]: {
      fontWeight: 700,
      fontSize: 20,
    },
  },

  guideList: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  buttonUpload: {
    marginTop: 28,
    backgroundColor: "#fff",
    "&.MuiButton-root": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
      fontSize: 12,
      fontWeight: "700",
      marginTop: 32,
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
    },
  },
  separator: {
    height: 2,
    width: "100%",
    backgroundColor: "#BCE0FD",
    marginTop: 20,
  },
  buttonRemove: {
    position: "absolute",
    top: -10,
    right: -10,
    background: "#ffffff89",
  },
  error: { marginTop: 20 },
  footer: {
    marginBottom: 32,
    display: "flex",
    justifyContent: "center",
  },
  uploadIcon: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      color: "rgba(255, 255, 255, 0.4)",
    },
  },
  sampleIdCardTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginBottom: 16,

    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  sampleIdCards: { maxWidth: 554, marginBottom: 20 },
  sampleIdCard: {
    maxWidth: 256,
    width: "100%",
  },
}));

const RegistrationUpload = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, registration, loading } = props;

  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.preRegistration"),
      route: "/pre-registration",
    },
  ];

  const [error, setError] = useState("");

  const onPressButtonBack = () => {
    history.goBack();
  };

  const onPressRemove = (photo) => {
    dispatch(deletePhoto(photo));
  };

  const onFileUpload = (event) => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      let formData = new FormData();
      formData.append("image", files[i], files[i].name);

      dispatch(uploadPhoto(formData, registration.id));
      setError();
    }
  };

  const onSubmit = (data) => {
    if (registration.photos.length >= MIN_UPLOAD_PHOTOS) {
      history.push("/pre-registration/confirmation");
    } else {
      setError("required");
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <Header
        title={t(`registration.${props.pageTitle}`)}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />

      <Container component="main" className={classes.main} maxWidth={false}>
        <Steps activeStep={1} user={user}></Steps>

        <Container>
          <Typography className={classes.message} variant="body2" gutterBottom>
            {t("registration.pleaseProvideUsThePhotos")}
          </Typography>

          <ul className={classes.guideList}>
            {user.role == UserRoles.TUKTUK_DRIVER && (
              <li>
                {t("registration.drivingLicenseCard")} ({t("common.optional")})
              </li>
            )}
            {user.role == UserRoles.DOMESTIC_WORKER && (
              <li>
                {t("registration.workingContract")} ({t("common.optional")})
              </li>
            )}

            <li>
              {t("registration.associationMembershipCard")} (
              {t("common.optional")})
            </li>
            <li>{t("registration.nationalIDCard")}</li>
          </ul>

          <Typography
            className={classes.sampleIdCardTitle}
            variant="body2"
            gutterBottom
          >
            {t("registration.pleaseProvideIdCard")}
          </Typography>

          <Grid container className={classes.sampleIdCards} spacing={2}>
            <Grid item xs={6}>
              <img src={IDCardBack} className={classes.sampleIdCard}></img>
            </Grid>
            <Grid item xs={6}>
              <img src={IDCardFront} className={classes.sampleIdCard}></img>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {registration.photos.map((photo, index) => (
              <Grid item xs={6} sm={4} md={3} key={index}>
                <div className={classes.photoContainer}>
                  <IconButton
                    color="primary"
                    aria-label="remove photo"
                    className={classes.buttonRemove}
                    size="small"
                    onClick={() => onPressRemove(photo)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <img src={photo.image_url} className={classes.photo} />
                </div>
              </Grid>
            ))}
          </Grid>
          <div className={classes.separator}></div>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size={"large"}
            disableElevation
            className={classes.buttonUpload}
            startIcon={<PublishIcon className={classes.uploadIcon} />}
            component="label"
          >
            {t("registration.uploadPhotos")}
            <input
              type="file"
              hidden
              onChange={onFileUpload}
              accept="image/*"
              multiple={true}
            />
          </Button>
          {error && (
            <Alert severity="error" className={classes.error}>
              {t("registration.atLeastTwoPhotosIsRequired")}
            </Alert>
          )}
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size={"large"}
              disableElevation
              className={classes.button}
              onClick={onSubmit}
            >
              {t("actions.next")}
            </Button>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default RegistrationUpload;
