import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import Header from "../../components/Header";
import Dialog from "../../components/Dialog";

import {
  getTotalTuktukDriverContributionWithCurrency,
  getTotalTuktukDriverContribution,
} from "../../utils/payment";
import { setTransaction } from "../../store/actions/contributionPaymentActions";
import { fetchContributionHistory } from "../../store/actions/contributionHistoryActions";

import {
  TuktukDriverContributionFee,
  TransactionStatuses,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
    paddingBottom: 20,
  },
  box: {
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    paddingBottom: 10,
    marginBottom: 45,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      borderBottomStyle: "none",
      paddingBottom: 0,
      display: "block",
    },
  },
  title: {
    marginBottom: 0,
    fontSize: 16,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 8,
      marginBottom: 22,
      borderBottomColor: "#BCE0FD",
      borderBottomStyle: "solid",
      borderBottomWidth: 2,
    },
  },
  frequencyButtons: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  button: {
    height: "100%",
    width: 240,
    marginLeft: 15,
    fontSize: 11,
    fontWeight: "bold",
    padding: theme.spacing(1.66, 2),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    [theme.breakpoints.down("md")]: {
      width: "30%",
      marginLeft: 0,
      borderRadius: 8,
      height: 40,
    },
  },
  clickOn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  clickOff: {
    backgroundColor: "white",
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  label: {
    fontWeight: "bold",
    fontSize: 24,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      textAlign: "center",
    },
  },
  buttonPay: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 240,
      fontSize: 12,
      fontWeight: "700",
      margin: theme.spacing(0, "auto"),
      marginTop: 32,
    },
  },
}));

const TuktukDriverPayment = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = props;

  const now = moment();

  const transaction = useSelector(
    (state) => state.contributionPayment.transaction
  );
  const contributionGrouped = useSelector(
    (state) => state.contributionHistory.items
  );
  const defaultFrequency = transaction?.frequency || 6;

  const startAt = now.clone();
  const [frequency, setFrequency] = useState(defaultFrequency);
  const [nextPaymentDate, setNextPaymentDate] = useState();

  const [isAlreadyPaid, setIsAlreadyPaid] = useState(false);
  const [currentYear, setCurrentYear] = useState(now);

  const { t } = useTranslation();
  const breadcrumbs = [
    {
      title: t(`common.${user.role}`),
      route: "/",
    },
    {
      title: t("common.contributionPayment"),
      route: "/contribution-payment",
    },
  ];

  const onPressPayNow = () => {
    let total = getTotalTuktukDriverContribution(frequency);
    let startDate = startAt.startOf("month").format();
    let endDate = getEndAt(startAt).endOf("month").format();

    let params = {
      total_amount_due: total,
      payment_method: "",
      frequency: frequency,
      start_at: startDate,
      end_at: endDate,
      payments: [
        {
          frequency: frequency,
          salary: 0,
          contribution: TuktukDriverContributionFee,
          contributor_id: user.id,
          start_at: startDate,
          end_at: endDate,
          selected: true,
        },
      ],
    };

    dispatch(setTransaction(params)).then(() => {
      let start = moment(startDate).format("YYYY-MM-DD");

      if (isAlreadyPaidPeriod(start)) {
        setIsAlreadyPaid(true);
      } else {
        history.push("/contribution-payment/payment-method");
      }
    });
  };

  const onPressButtonBack = () => {
    history.push("/");
  };

  const paymentAmountDue = () => {
    return getTotalTuktukDriverContributionWithCurrency(frequency);
  };

  const paymentFrequencyMonthRange = () => {
    let start = startAt.format("MMMM YYYY");
    let end = getEndAt(startAt).format("MMMM YYYY");

    return start + " > " + end;
  };

  const getEndAt = (startDate) => {
    return startDate.clone().add(frequency - 1, "M");
  };

  const isAlreadyPaidPeriod = (startDate) => {
    const items = contributionGrouped.map(function (item) {
      return item["payments"];
    });
    const payments = _.flatten(items);

    const activePayments = payments
      .filter((item) =>
        [
          TransactionStatuses.COMPLETED,
          TransactionStatuses.AWAITING_CONFIRMATION,
        ].includes(item.status)
      )
      .sort((a, b) => {
        var dateA = new Date(a.end_at),
          dateB = new Date(b.end_at);
        return dateB - dateA;
      });
    const lastActivePayment = activePayments[0];

    if (
      lastActivePayment &&
      new Date(lastActivePayment.end_at) > new Date(startDate)
    ) {
      const nextPayment = moment(new Date(lastActivePayment.end_at)).add(
        1,
        "d"
      );
      setNextPaymentDate(nextPayment.format("DD/MM/YYYY"));
      return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch(fetchContributionHistory(currentYear.year()));
  }, [currentYear]);

  return (
    <>
      <Header
        title={t("payment.tuktukDriverContributionPayment")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main">
        <Box className={classes.root}>
          <div className={classes.box}>
            <div className={classes.title}>
              {t("contribution_payment.paymentFrequency")}
            </div>
            <div className={classes.frequencyButtons}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={clsx(
                  classes.button,
                  frequency == 6 ? classes.clickOn : classes.clickOff
                )}
                onClick={() => setFrequency(6)}
              >
                {t("contribution_payment.6months")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={clsx(
                  classes.button,
                  frequency == 9 ? classes.clickOn : classes.clickOff
                )}
                onClick={() => setFrequency(9)}
              >
                {t("contribution_payment.9months")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={clsx(
                  classes.button,
                  frequency == 12 ? classes.clickOn : classes.clickOff
                )}
                onClick={() => setFrequency(12)}
              >
                {t("contribution_payment.1year")}
              </Button>
            </div>
          </div>

          <div className={classes.box}>
            <div className={classes.title}>
              {t("contribution_payment.paymentTranche")}
            </div>
            <div className={classes.label}>{paymentFrequencyMonthRange()}</div>
          </div>
          <div className={classes.box}>
            <div className={classes.title}>
              {t("contribution_payment.paymentAmountDue")}
            </div>
            <div className={classes.label}>{paymentAmountDue()}</div>
          </div>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            onClick={onPressPayNow}
            disableElevation
            className={classes.buttonPay}
          >
            {t("payment.payNow")}
          </Button>
        </Box>

        <Dialog
          title={t("payment.paymentError")}
          open={isAlreadyPaid}
          setOpen={setIsAlreadyPaid}
        >
          <Typography>{t("payment.youCantPayMultipleTimes")}</Typography>
          <Typography>
            {t("payment.theNextPayment")} <strong>{nextPaymentDate}</strong>
          </Typography>
        </Dialog>
      </Container>
    </>
  );
};

export default TuktukDriverPayment;
