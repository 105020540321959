import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiListItemText-root": {
      opacity: 0,
      [theme.breakpoints.up("md")]: {
        opacity: 1,
      },
    },
    "& .MuiListItem-secondaryAction": {
      borderBottomWidth: 0,
      margin: 0,
      padding: 2,

      [theme.breakpoints.up("md")]: {
        paddingRight: 48,
        borderBottomWidth: 2,
        marginTop: 16,
        paddingTop: 8,
        paddingBoottom: 8,
      },
    },
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: "#BCE0FD",

    [theme.breakpoints.up("md")]: {
      borderBottomWidth: 0,
    },
  },
  itemTitle: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  item: {
    paddingLeft: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    marginTop: 16,

    "& .MuiListItemText-primary": {
      fontSize: 11,
      color: theme.palette.primary.main,
    },
  },
  permanentAddressTitle: {
    opacity: "1 !important",
    [theme.breakpoints.up("md")]: {
      opacity: "0 !important",
    },
  },
  provinceTitle: {
    display: "none !important",
    [theme.breakpoints.up("md")]: {
      display: "block !important",
    },
  },
}));

const Addresses = ({ registration }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List className={classes.root}>
      <ListItem className={classes.item}>
        <ListItemText
          primary={t("locations.province")}
          className={classes.provinceTitle}
        />
        <ListItemText
          primary={t("registration.permanentAddress")}
          className={classes.permanentAddressTitle}
        />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.province}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={classes.item}>
        <ListItemText primary={t("locations.district")} />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.district}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={classes.item}>
        <ListItemText primary={t("locations.commune")} />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.commune}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={classes.item}>
        <ListItemText primary={t("locations.village")} />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.village}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={classes.item}>
        <ListItemText primary={t("locations.houseNumber")} />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.house_no}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={classes.item}>
        <ListItemText primary={t("locations.street")} />
        <ListItemSecondaryAction className={classes.itemTitle}>
          {registration.street}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

export default Addresses;
