import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Avatar, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import LogoImage from "../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(0, "auto"),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 0),
    },
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <Avatar
      alt="Spin"
      to="/"
      component={RouterLink}
      src={LogoImage}
      className={clsx(classes.logo, props.className)}
    />
  );
};

export default Logo;
