import axios from "axios";
import { config } from "../config/";

const track = (page) => {
  return axios
    .post(`${config.API_BASE_URL}/statistics`, { type: page })
    .then((response) => {
      return response.data;
    });
};

const TRACKER_PAGES = Object.freeze({
  INFORMATION: "information",
  CONTRIBUTION_PAYMENT: "fees_payment",
  PRE_REGISTRATION: "pre_register",
  WORKING_CONTRACT_SAMPLE: "working_contract_sample",
  FREE_CALL_CENTER: "free_call_center",
});

export default { track, TRACKER_PAGES };
