import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "none",
    color: theme.palette.primary.main,

    [theme.breakpoints.up("md")]: {
      display: "flex",
      padding: theme.spacing(0, "16px"),
    },
  },
  title: {
    fontSize: 13,
    borderBottomWidth: 2,
    borderBottomColor: "#BCE0FD",
    borderBottomStyle: "solid",
    paddingBottom: 6,
    width: "100%",
  },
}));

const EmployeeListHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.row} spacing={1}>
      <Grid item xs={1} md={2} lg={1}>
        <Typography variant={"h4"} className={classes.title}>
          {t("common.employee")} #
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"h4"} className={classes.title}>
          {t("registration.fullNameKH")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"h4"} className={classes.title}>
          {t("registration.fullNameLATIN")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"h4"} className={classes.title}>
          {t("registration.gender")}
        </Typography>
      </Grid>
      <Grid item xs={3} md={2} lg={3}>
        <Typography variant={"h4"} className={classes.title}>
          {t("registration.nssfIdNumber")}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          variant={"h4"}
          className={clsx(classes.title, classes.delete)}
        >
          {t("actions.delete")}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default EmployeeListHeader;
