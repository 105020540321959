import { SET_VERIFICATION_PHONE } from "../types";
import history from "../../utils/history";
export const setVerificationPhone = (phone) => async (dispatch) => {
  dispatch({
    type: SET_VERIFICATION_PHONE,
    payload: phone,
  });

  history.push(`/profile/phone-verification`);
};
