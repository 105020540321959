import {
  SET_USER,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_UPDATE_PHONE_SUCCESS,
} from "../types";

const user = JSON.parse(localStorage.getItem("auth"));
const accessToken = localStorage.getItem("access_token");

const initialState =
  user && accessToken
    ? { isLoggedIn: true, user, accessToken }
    : { isLoggedIn: false, user: null, accessToken: null };

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: { ...action.payload },
        isLoggedIn: true,
      };
    case USER_REGISTER_REQUEST:
      return {
        ...state,
        accessToken: null,
        error: null,
        loading: true,
      };
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        error: null,
        loading: false,
      };

    case USER_REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        accessToken: null,
      };

    case USER_LOGIN_REQUEST:
      return {
        ...state,
        accessToken: null,
        error: null,
        user: null,
        loading: true,
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        error: null,
        loading: false,
      };

    case USER_LOGIN_FAILURE:
      return {
        isLoggedIn: false,
        user: null,
        accessToken: null,
        error: action.payload,
        loading: false,
      };

    case USER_LOGOUT_REQUEST:
      return {
        accessToken: null,
        isLoggedIn: false,
        user: null,
      };

    case USER_UPDATE_PHONE_SUCCESS:
      return {
        loading: false,
        user: { ...action.payload },
        error: null,
      };

    default:
      return state;
  }
}
