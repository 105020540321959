import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OTPVerification from "../../components/OTPVerification";

import { loginPhone } from "../../store/actions/authActions";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import "react-phone-number-input/style.css";

import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginTop: 15,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    textAlign: "center",
    marginBottom: 0,
  },
  bottomDiv: {
    position: "absolute",
    bottom: 30,
    width: "60%",
    textAlign: "center",
  },
  resendText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  timetitle: {
    fontSize: 12,
    paddingTop: 10,
    color: theme.palette.primary.main,
  },
  errors: {
    margin: 0,
  },
}));

const CheckSmsCode = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  let { role } = useParams();

  const phone = window.localStorage.getItem("phone");
  if (user) {
    return <Redirect to="/" />;
  }

  const onPressButtonBack = () => {
    history.goBack();
  };

  const onSuccess = (data) => {
    dispatch(loginPhone(data, role.trim()));
  };

  const breadcrumbs = [
    { title: t("register.register"), route: "/check-sms-code/:role" },
  ];

  return (
    <>
      <Header
        title={t("login_phone.title")}
        breadcrumbs={breadcrumbs}
        onPressButtonBack={onPressButtonBack}
      />
      <Container component="main" maxWidth="xs">
        {phone && phone != "" ? (
          <OTPVerification
            counter={0}
            verifyOnly={false}
            phone={phone}
            onSuccess={onSuccess}
          ></OTPVerification>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default CheckSmsCode;
